import React from 'react';
import { Typography } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import IrrigationStatusIcon from './IrrigationStatusIcon';
import { irrigationStatus } from '../../../constants';
import './irrigationStatus.css';
import { useIrrigationStatus } from '../../../helper/useIrrigationStatus';

const backgroundColor = {
  [irrigationStatus.IRRIGATING]: {
    primary: '#DEF4FC',
    secondary: '#1F71C6',
  },
  [irrigationStatus.SCHEDULED]: {
    primary: '#D7EEDE',
    secondary: '#306E2D',
  },
  [irrigationStatus.NOT_SCHEDULED]: {
    primary: '#E1E1E1',
    secondary: '#808080',
  },
};

export default function IrrigationStatus(props) {
  const { Text } = Typography;
  const { block, type } = props;
  const [statusDetails] = useIrrigationStatus(block.blockId);
  const bgColor = backgroundColor[statusDetails.status];

  return (
    <div className="irrigation-status-container" style={{ background: bgColor.primary }}>
      <div className="irrigation-status-icon">
        {type === 'calendar' ? (
          <div className="irrigation-status-calendar">
            <CalendarOutlined style={{ color: bgColor.secondary }} />
          </div>
        ) : (
          <IrrigationStatusIcon status={statusDetails.status} />
        )}
      </div>
      <div className="irrigation-status-content">
        <Text className="irrigation-status-title" strong>{statusDetails.title}</Text>
        <Text>{statusDetails.description}</Text>
      </div>
    </div>
  );
}

IrrigationStatus.defaultProps = {
  type: '',
};

IrrigationStatus.propTypes = {
  block: PropTypes.objectOf(Object).isRequired,
  type: PropTypes.string,
};
