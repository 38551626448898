import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FeatureGroup, TileLayer } from 'react-leaflet';
import isEqual from 'react-fast-compare';
import { selectors } from 'farmx-redux-core';
import { Button } from 'antd';
import { FaLayerGroup } from 'react-icons/fa';
import MapControlButtons from './MapControlButtons';
import AnomalyControls from './Anomaly/AnomalyControls';
import AnomalyButtons from './Anomaly/AnomalyButtons';
import { isMobile } from '../../../utils/detectDevice';

const {
  selectMapShowSoilType,
} = selectors;

export default function MapFeatureGroup(props) {
  const {
    dispatchMapArg, setIsAnomalyControlEnabled, presMode, selectedObjFromState,
    isAnomalyControlEnabled, stateMapArg, ranchId, blockId, isBottomSheetVisible,
    isImageryPanelVisible, anomalyGeoJSON, createAnomaly, setCreateAnomaly,
    selectedFeature, setModal, sideBySideMapVisible, mapFeatureGroupRef, isLeft,
    setLayerOption, setMapSettingModal,
  } = props;

  const selMapShowSoilType = useSelector(
    (state) => selectMapShowSoilType(state), isEqual,
  );

  const layerOptionButton = () => (
    <Button
      size="large"
      type="primary"
      icon={<FaLayerGroup />}
      style={{ backgroundColor: 'rgba(0,0,0,0.7)', color: 'white', border: 'none' }}
      onClick={() => {
        setLayerOption(true);
        setMapSettingModal(true);
      }}
    />
  );

  return (
    <FeatureGroup
      ref={(r) => {
        if (r && !mapFeatureGroupRef.current && isLeft) {
          mapFeatureGroupRef.current = r;
        }
      }}
    >
      {
        selMapShowSoilType && !sideBySideMapVisible && (
        <TileLayer
          url="https://map.farmx.co/api/soil/wms/?x={x}&y={y}&z={z}"
          name="soil"
          maxZoom={19}
          minZoom={0}
          showOnSelector={false}
          zIndex={5}
        />
        )
        }
      {!isMobile && (
        <div className="controls-box">
          <div className="controls-right desktop-controls-right-buttons">
            {!isAnomalyControlEnabled && layerOptionButton()}
            {!isAnomalyControlEnabled && (
            <MapControlButtons
              isMobile={isMobile}
              isImageryPanelVisible={stateMapArg.isImageryPanelVisible}
              selectedObjFromState={selectedObjFromState}
              ranchId={ranchId}
              blockId={blockId}
              dispatchMap={dispatchMapArg}
              isBottomSheetVisible={isBottomSheetVisible}
              selectedImageryData={stateMapArg.selectedImageryData}
              anomalyGeoJSON={anomalyGeoJSON}
              presMode={presMode}
            />
            )}
            <AnomalyControls
              presMode={presMode}
              createAnomaly={createAnomaly}
              setCreateAnomaly={setCreateAnomaly}
              selectedFeature={selectedFeature}
              setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
            />
          </div>
          {!isMobile && (
          <AnomalyButtons
            presMode={presMode}
            createAnomaly={createAnomaly}
            setCreateAnomaly={setCreateAnomaly}
            stateMapArg={stateMapArg}
            setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
            isImageryPanelVisible={isImageryPanelVisible}
            isBottomSheetVisible={isBottomSheetVisible}
            setModal={setModal}
            sideBySideMapVisible={sideBySideMapVisible}
          />
          )}
        </div>
      )}
    </FeatureGroup>
  );
}

MapFeatureGroup.propTypes = {
  presMode: PropTypes.string,
  createAnomaly: PropTypes.func,
  setCreateAnomaly: PropTypes.func,
  setIsAnomalyControlEnabled: PropTypes.func,
  setModal: PropTypes.func,
  isBottomSheetVisible: PropTypes.bool,
  isImageryPanelVisible: PropTypes.bool,
  stateMapArg: PropTypes.shape(),
  selectedFeature: PropTypes.shape(),
  selectedObjFromState: PropTypes.shape(),
  dispatchMapArg: PropTypes.func,
  isAnomalyControlEnabled: PropTypes.bool,
  blockId: PropTypes.number,
  ranchId: PropTypes.number,
  anomalyGeoJSON: PropTypes.shape(),
  sideBySideMapVisible: PropTypes.bool,
  mapFeatureGroupRef: PropTypes.shape(),
  isLeft: PropTypes.bool,
  setLayerOption: PropTypes.func,
  setMapSettingModal: PropTypes.func,
};

MapFeatureGroup.defaultProps = {
  setModal: null,
  presMode: null,
  createAnomaly: null,
  setCreateAnomaly: null,
  stateMapArg: null,
  setIsAnomalyControlEnabled: null,
  isBottomSheetVisible: false,
  isImageryPanelVisible: false,
  selectedFeature: null,
  dispatchMapArg: null,
  selectedObjFromState: {},
  isAnomalyControlEnabled: false,
  blockId: null,
  ranchId: null,
  anomalyGeoJSON: null,
  mapFeatureGroupRef: {},
  sideBySideMapVisible: false,
  isLeft: true,
  setLayerOption: null,
  setMapSettingModal: null,
};
