import React from 'react';
import { Button } from 'antd';
import { FaLocationArrow } from 'react-icons/fa';
import { blockApi } from 'farmx-api';
import moment from 'moment';

/**
 * @param {*} blockIds - array of blockIds
 * @param {*} callBack - callback function to return anomaly data
 */
export const loadBlockAnomalies = async (blockIds, callBack) => {
  const allRequests = [];
  const finalData = [];
  blockIds.forEach((blockId) => {
    allRequests.push(blockApi.getBlockAnomaly(blockId));
  });
  Promise.allSettled(allRequests).then((results) => {
    results.forEach((r) => {
      if (r.status === 'fulfilled') {
        if (r?.value?.data?.length) {
          const anomalies = r.value.data;
          if (anomalies) {
            anomalies.forEach((anomaly) => {
              const anomalyObj = {
                id: `${anomaly.name}/${anomaly.anomaly_type}`,
                type: 'Feature',
                geometry: anomaly.center,
                name: anomaly.name,
                properties: anomaly,
              };
              finalData.push(anomalyObj);
            });
          }
        }
      }
    });
    callBack(finalData);
  });
};

export function navigateMap(selectedAnomaly) {
  if (selectedAnomaly === null) return null;
  const baseURL = 'https://www.google.com/maps/dir/?api=1&destination=';
  const lat = selectedAnomaly.properties.center.coordinates[1];
  const lng = selectedAnomaly.properties.center.coordinates[0];
  window.open(`${baseURL + lat},${lng}`, '_blank');
  return true;
}

/**
   * @param {*} selectedFeature - selected anomaly feature
   * @returns - the modified feature object to draw polygon
   */
export function getLayerBounds(selectedFeature) {
  if (selectedFeature) {
    const obj = selectedFeature;
    obj.geometry = selectedFeature.properties.bounds;
    return obj;
  }
  return selectedFeature;
}

export const getDataForReDraw = (ranchSensors, presMode,
  anomalyGeoJSON, selectedFeature) => {
  let data = ranchSensors;
  if (presMode === 'anomaly' && !selectedFeature) data = anomalyGeoJSON;
  else if (presMode === 'anomaly' && selectedFeature) data = selectedFeature;

  return data;
};

export function renderAnomalyInfo(obj, t) {
  return (
    <div className="margin-top-10 map-mobile-anomaly-button">
      <Button
        type="ghost"
        onClick={() => navigateMap(obj)}
        icon={<FaLocationArrow className="anomaly-icon" />}
      >
        {t('Navigate')}
      </Button>
    </div>
  );
}

export function softDeleteBlockAnomaly(anomalyDeleteData, callBack) {
  blockApi.softDeleteAnomaly(anomalyDeleteData).then((success) => {
    callBack(true, success);
  }, (error) => {
    callBack(false, error);
  });
}

export function deleteBlockAnomaly(anomalyId, callBack) {
  blockApi.deleteAnomaly(anomalyId).then((success) => {
    callBack(true, success);
  }, (error) => {
    callBack(false, error);
  });
}

export function createAnomalyData(formObj, layer) {
  const anomaly = {
    name: formObj.name,
    anomaly_type: formObj.type,
    description: formObj.description,
    bounds: {
      coordinates: layer.toGeoJSON().geometry.coordinates,
      type: 'Polygon',
    },
    center: {
      coordinates: [
        layer.getBounds().getCenter().lng,
        layer.getBounds().getCenter().lat,
      ],
      type: 'Point',
    },
    block: formObj.blockId,
  };
  return anomaly;
}

export function createBlockAnomaly(anomalyData, callBack) {
  blockApi.createAnomaly(anomalyData).then((success) => {
    callBack(true, success);
  }, (error) => {
    callBack(false, error);
  });
}

export function editBlockAnomaly(anomalyData, callBack) {
  blockApi.updateAnomaly(anomalyData).then((success) => {
    callBack(true, success);
  }, (error) => {
    callBack(false, error);
  });
}

/**
 * Change nested structure to simple key value pair for csv generation
 */
export function flattenAnomalyArrayForCsv(anomalyArr) {
  return anomalyArr.map((anomalySingle = {}) => {
    const obj = {};
    obj.Id = anomalySingle.id;
    obj.Name = anomalySingle.name;
    obj.Type = anomalySingle.type;

    if (anomalySingle.geometry) {
      obj['Geometry Type'] = anomalySingle.geometry.type ?? '';
      obj['Geometry Coordinates'] = anomalySingle?.geometry.coordinates?.join(', ') ?? '';
    }

    if (anomalySingle.properties) {
      obj['Properties Id'] = anomalySingle.properties.id || '';
      obj['Properties Name'] = anomalySingle.properties.name || '';
      obj['Properties Block'] = anomalySingle.properties.block || '';
      obj['Properties Anomaly Type'] = anomalySingle.properties.anomaly_type || '';
      obj['Properties Center Type'] = anomalySingle.properties.center?.type || '';

      const { bounds } = anomalySingle.properties;
      if (bounds) {
        obj['Properties Bounds Type'] = anomalySingle.properties.bounds.type || '';
        /**
         * anomalySingle.properties.bounds.coordinates is in the format:
         * [
         *  [
         *    [x, y],
         *    [x1, y1]
         *  ]
         * ]
         *
         * Flattening it to look like:
         * (x, y)(x1, y1)
         */
        const propertiesBoundsCoordinates = anomalySingle.properties.bounds.coordinates || [];

        let output = '';
        propertiesBoundsCoordinates.forEach((multipleCoordinates = []) => {
          multipleCoordinates.forEach((singleCoordinate) => {
            output += '(';
            output += singleCoordinate?.join(', ') || '';
            output += ')';
          });
        });
        obj['Properties Bounds Coordinates'] = output;
      }
    }

    return obj;
  });
}

export function isImageryUpdateNeeded(imageryData, selectedSatelliteDate,
  selectedSatelliteDatesDataRange, selectedSatelliteDataType, blockId, ranchId,
  ranchIdFromState, blockIds) {
  let CONDITION_CHECK_1 = false;
  if (imageryData) {
    const dateStr = moment(selectedSatelliteDate).format('YYYY-MM-DD');
    const dateStrFromData = moment(Number(imageryData[0].date) * 1000).format('YYYY-MM-DD');
    const dateStrings = selectedSatelliteDatesDataRange.map((d) => d?.dateString);
    CONDITION_CHECK_1 = ((imageryData[0].data_key_url
      ?.indexOf(`/${selectedSatelliteDataType}`) === -1)
      || !moment(dateStr).isSame(dateStrFromData) || !dateStrings.includes(dateStr));
  }

  const CONDITION_CHECK_2 = !(imageryData?.length
    === blockId?.length);
  const CONDITION_CHECK_3 = !(imageryData?.[0]?.block
      === blockId[0]);
  const CONDITION_CHECK_4 = ranchId === ranchIdFromState && blockIds
   && blockIds.includes(imageryData?.[0]?.block);

  let callApi = !imageryData;
  if (CONDITION_CHECK_4) {
    if (imageryData && blockId?.length) {
      callApi = CONDITION_CHECK_1;
      if (blockId?.length && !callApi) {
        callApi = CONDITION_CHECK_2 || CONDITION_CHECK_3;
      }
    } else if (!callApi && imageryData?.length > 1) {
      callApi = CONDITION_CHECK_1;
    } else callApi = true;
  } else callApi = true;

  return callApi;
}

/**
 * @param {*} selMapPresentationModes - available presentationModes from the redux state
 * @returns - The presentation mode object if available, otherwise returns default one.
 */
export function getPresentationModes(selMapPresentationModes) {
  const defaultPresMode = {
    irrigationRecommendation: false,
    soilstatus: true,
    connectivity: false,
    waterpressure: false,
    control: false,
    provision: false,
    anomaly: false,
    cropHealth: false,
  };

  return Object.keys(selMapPresentationModes || {})?.length ? selMapPresentationModes : defaultPresMode;
}

/**
 * @param {*} selMapPresentationModes - available presentationModes from the redux state
 * @returns - The selected presentation mode
 */
export function getSelectedPresentationMode(selMapPresentationModes) {
  const presMode = Object.keys(getPresentationModes(selMapPresentationModes))
    ?.find((k) => getPresentationModes(selMapPresentationModes)[k]);
  return presMode;
}
