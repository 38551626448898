import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectors, actions } from 'farmx-redux-core';
import { Map as MapComponent } from 'farmx-web-ui';
import { notification } from 'antd';
import { isMobile } from '../../../utils/detectDevice';
import {
  getLayerBounds, createAnomalyData, createBlockAnomaly,
  editBlockAnomaly, loadBlockAnomalies,
} from '../../../helper/mapHelper';
import MapFeatureGroup from './MapFeatureGroup';

const {
  setRanchMobile, setRanch,
  setBlockMobile, setBlocks,
} = actions;

const {
  selectUserAreaFormat,
} = selectors;

export default function MapWrapper(props) {
  const {
    dispatchMap, dispatchMapArg, setIsAnomalyControlEnabled, presMode, selectedObjFromState,
    isAnomalyControlEnabled, stateMapArg, ranchId, blockId,
    ranchGeoJSON, blocksGeoJSON, createAnomaly, setCreateAnomaly,
    setModal, mapFeatureGroupRef, isLeft, stateMap, stateMap1,
    setLayerOption, setMapSettingModal, modal, formValues, setIsLoading, showHighResBaseMap,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const areaFormat = useSelector(selectUserAreaFormat);

  const paddingBottomMobile = stateMap.isBottomSheetVisible ? 270 : 10;
  const mapBoxPadding = {
    top: isMobile ? 140 : 0,
    left: 0,
    bottom: isMobile ? paddingBottomMobile : 0,
    right: 0,
  };

  function onRanchBlockClick(id, type) {
    if (type === 'block' && !isMobile) dispatch(setBlocks([Number(id)]));
    if (type === 'ranch' && !isMobile) dispatch(setRanch(Number(id)));
    dispatchMap({
      type: 'setSelectedFeatureWithType',
      payload: {
        selectedFeature: undefined,
        bottomSheetType: undefined,
        showBottomSheet: true,
      },
    });
  }

  function onChangeFinish() {
    dispatchMap({ type: 'setAnomalyLayerData', payload: null });
    setIsLoading(false);
    setModal({ ...modal, visible: false });
    setCreateAnomaly({ ...createAnomaly, visible: false });
    loadBlockAnomalies([blockId], (response) => {
      if (response) {
        dispatchMap({
          type: 'setAnomalyGeoJSON',
          payload: response,
        });
      }
    });
  }

  function onDrawOrEditAnomaly(drawObj) {
    if (drawObj?.type === 'create') {
      const layerObj = drawObj?.layer ? drawObj.layer : stateMap.anomalyLayerData;
      if (layerObj) {
        const formData = createAnomalyData(formValues.current, layerObj);
        if (formData?.block) {
          createBlockAnomaly(formData, (success) => {
            if (success) {
              notification.success({
                message: t('Anomaly Created Successfully!'),
              });
              formValues.current.name = null;
            } else {
              notification.error({
                message: t('Failed to Create anomaly'),
              });
            }
            onChangeFinish();
          });
        }
      }
    } else if (drawObj?.type === 'edit') {
      editBlockAnomaly(drawObj.layer, (success) => {
        if (success) {
          notification.success({
            message: t('Anomaly Edited Successfully!'),
          });
        } else {
          notification.error({
            message: t('Failed to Edit anomaly'),
          });
        }
        onChangeFinish();
      });
    } else if (drawObj?.type === 'show-undo') {
      setCreateAnomaly({ ...createAnomaly, ...drawObj });
    } else if (drawObj?.type === 'stop-draw') {
      setCreateAnomaly({ ...createAnomaly, ...drawObj });
      dispatchMap({ type: 'setAnomalyLayerData', payload: drawObj?.layer });
    } else if (drawObj?.type === 'show-cancel') {
      setCreateAnomaly({ ...createAnomaly, ...drawObj });
    } else {
      onChangeFinish();
    }
  }

  function renderFeatureGroup() {
    return (
      <MapFeatureGroup
        dispatchMapArg={dispatchMap}
        setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
        presMode={presMode}
        selectedFeature={isLeft ? stateMap.selectedFeature : stateMap1.selectedFeature}
        createAnomaly={createAnomaly}
        setCreateAnomaly={setCreateAnomaly}
        stateMapArg={stateMapArg}
        setModal={setModal}
        isImageryPanelVisible={stateMap.isImageryPanelVisible}
        isBottomSheetVisible={stateMap.isBottomSheetVisible}
        selectedObjFromState={selectedObjFromState}
        isAnomalyControlEnabled={isAnomalyControlEnabled}
        ranchId={ranchId}
        blockId={blockId}
        anomalyGeoJSON={stateMap.anomalyGeoJSON}
        sideBySideMapVisible={isLeft ? stateMap.sideBySideMapVisible
          : stateMap1.sideBySideMapVisible}
        mapFeatureGroupRef={mapFeatureGroupRef}
        isLeft={isLeft}
        setLayerOption={setLayerOption}
        setMapSettingModal={setMapSettingModal}
      />
    );
  }

  return (
    <MapComponent
      exposeZoomChange={(zoom) => {
        dispatchMapArg({
          type: 'setZoomSensorsVisible',
          payload: zoom,
        });
      }}
      ranchesGeoJSON={ranchGeoJSON}
      blocksGeoJSON={blocksGeoJSON}
      recenterGeoJSON={stateMap.recenterGeoJSON}
      presentationMode={presMode}
      mapBoxPadding={mapBoxPadding}
      onClickRanch={(id) => {
        dispatch(setRanchMobile(Number(id)));
        onRanchBlockClick(id, 'ranch');
      }}
      onClickBlock={(id) => {
        //  To avoid block selection on map when anomaly create/edit modes are active state
        if (!createAnomaly?.visible || ((!createAnomaly?.type === 'create')
      && (!createAnomaly?.type === 'edit'))) {
          dispatch(setBlockMobile([Number(id)]));
          onRanchBlockClick(id, 'block');
        }
      }}
      isDataLoading={stateMap.isLoading}
      isShowingImagery={{
        side: 'left',
        ...stateMap.selectedImageryData,
        otherSide: { side: 'right', ...stateMap1.selectedImageryData },
      }}
      anomalyGeoJSON={presMode === 'anomaly' ? getLayerBounds(isLeft
        ? stateMap.selectedFeature : stateMap1.selectedFeature)
        : undefined}
      showSideBySideMap={stateMap.sideBySideMapVisible}
      layersControl={{
        ...stateMapArg.showLayer,
        soil: { left: stateMap.showLayer.soil.left, right: stateMap1.showLayer.soil.right },
      }}
      createAnomaly={createAnomaly}
      onDrawAnomaly={onDrawOrEditAnomaly}
      userAreaUnit={areaFormat}
      showHighResBaseMap={showHighResBaseMap}
    >
      {renderFeatureGroup(dispatchMapArg, stateMapArg)}
    </MapComponent>
  );
}

MapWrapper.propTypes = {
  dispatchMapArg: PropTypes.func,
  dispatchMap: PropTypes.func,
  setIsAnomalyControlEnabled: PropTypes.func,
  presMode: PropTypes.string,
  selectedObjFromState: PropTypes.shape(),
  isAnomalyControlEnabled: PropTypes.bool,
  stateMapArg: PropTypes.shape(),
  blockId: PropTypes.number,
  ranchId: PropTypes.number,
  ranchGeoJSON: PropTypes.shape(),
  blocksGeoJSON: PropTypes.shape(),
  createAnomaly: PropTypes.func,
  setCreateAnomaly: PropTypes.func,
  setModal: PropTypes.func,
  mapFeatureGroupRef: PropTypes.shape(),
  isLeft: PropTypes.bool,
  stateMap: PropTypes.shape(),
  stateMap1: PropTypes.shape(),
  setLayerOption: PropTypes.func,
  setMapSettingModal: PropTypes.func,
  modal: PropTypes.shape(),
  formValues: PropTypes.shape(),
  setIsLoading: PropTypes.func,
  showHighResBaseMap: PropTypes.bool,
};

MapWrapper.defaultProps = {
  setModal: null,
  presMode: null,
  createAnomaly: null,
  setCreateAnomaly: null,
  stateMapArg: null,
  setIsAnomalyControlEnabled: null,
  dispatchMapArg: null,
  dispatchMap: null,
  selectedObjFromState: {},
  isAnomalyControlEnabled: false,
  blockId: null,
  ranchId: null,
  ranchGeoJSON: null,
  blocksGeoJSON: null,
  mapFeatureGroupRef: {},
  isLeft: true,
  setLayerOption: null,
  setMapSettingModal: null,
  stateMap: {},
  stateMap1: {},
  modal: null,
  formValues: {},
  setIsLoading: null,
  showHighResBaseMap: false,
};
