import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import PropTypes from 'prop-types';
import ControlPumpListItem from './ControlPumpListItem';

export default function ControlPumpList(props) {
  const { blocks } = props;
  const { t } = useTranslation();
  const blockIds = blocks?.map((block) => block.id);
  const allPumps = useSelector((state) => (
    selectors.selectPumpControllersForBlockIds(state, blockIds, true)
  )) || [];
  const loadingSensors = useSelector((state) => selectors.selectLoadingSensors(state));

  return (
    <div className="control-pump-list-container item-list" data-testid="control-pump-list">
      {allPumps.length ? allPumps.map((pump) => (
        <div className="pump-list-item" key={pump.blockId}>
          <Link
            key={pump.blockId}
            to={{
              pathname: `/control/pump/${pump.blockId}`,
              state: { goBack: true, identifier: pump.identifier },
            }}
          >
            <ControlPumpListItem
              key={pump.blockId}
              pump={pump}
            />
          </Link>
        </div>
      )) : null}
      {!allPumps.length && !loadingSensors.loading && (
        <div className="mobile-list-item no-config">
          {t('No Pumps Connected')}
        </div>
      )}
      {!allPumps.length && loadingSensors.loading && (
        <div className="mobile-list-item no-config">
          {t('Loading Pumps')}
        </div>
      )}
    </div>
  );
}

ControlPumpList.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
};

ControlPumpList.defaultProps = {
  blocks: [],
};
