import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../home/soil-state-legend-modal.less';
import ZendeskHelpModal from '../components/ZendeskHelpModal';

/**
 * Component loads the content from Zendesk if articleId is provided.
 */
export default function LegendModal(props) {
  const {
    visible, onCancel, legendDetails, articleId,
  } = props;
  const { t } = useTranslation();

  function renderLegendDetails() {
    return (
      <div className="color-label-container">
        {legendDetails && legendDetails.legendMap
          && Object.keys(legendDetails.legendMap).map((key) => (
            <div className="color-label" key={key}>
              <div className={`color-size ${legendDetails.legendMap[key]}`} />
              <Typography.Text className="color-description">
                {t(key)}
              </Typography.Text>
            </div>
          ))}
      </div>
    );
  }

  return articleId ? (
    <ZendeskHelpModal
      visible={visible}
      onCancel={onCancel}
      articleId={articleId}
    />
  ) : (
    <Modal
      className="soil-state-legend-modal"
      title={legendDetails && t(legendDetails.title)}
      centered
      visible={visible}
      closable
      onCancel={onCancel}
      footer={null}
    >
      <Typography.Text className="state-legend-description">
        {legendDetails && t(`${legendDetails.statement}`)}
      </Typography.Text>
      {renderLegendDetails()}
      <div className="view-all-help-link">
        <Link to="/help">{t('View All Help')}</Link>
      </div>
    </Modal>
  );
}

LegendModal.defaultProps = {
  visible: false,
  onCancel: () => { },
  legendDetails: {},
  articleId: '',
};

LegendModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  legendDetails: PropTypes.shape({
    title: PropTypes.string,
    statement: PropTypes.string,
    legendMap: PropTypes.shape(),
  }),
  articleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
