import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hooks, selectors } from 'farmx-redux-core';
import { Link } from 'react-router-dom';
import { PageHeader } from '../components/PageHeader';
import { PumpSummary } from './ListCards/PumpSummary';
import { RanchBlockSelect } from './RanchBlockSelect';
import { ControlPumpDetailItem } from '../control/ControlPump/ControlPumpDetailItem';
import { isMobile } from '../../../utils/detectDevice';

export function PumpList(props) {
  const { history } = props;
  const { t } = useTranslation();
  const [selectedObj, setSelectedObj] = useState({});
  const { blockIds } = hooks.useRanchBlockSelection();

  const list = useSelector((state) => (
    selectors.selectPumpControllersForBlockIds(state, blockIds, true)
  ));

  useEffect(() => {
    if (history && history.location && history.location.selectedObj) {
      setSelectedObj(history.location.selectedObj);
    }
  }, [history]);

  return (
    <div className="div-default padding-10 pump-list-container page-content">
      <div className="desktop-container">
        <PageHeader
          showBack
          title={t('Pumps')}
          toPath="/list"
        />
        {isMobile && (
        <RanchBlockSelect
          selectedObj={selectedObj}
          setSelectedObj={setSelectedObj}
        />
        )}
        <div className="list-container">
          <PumpSummary />
          {list.map((pumpDetail) => (
            <Link key={pumpDetail.identifier} to={`/list/pump/${pumpDetail.type}/${pumpDetail.identifier}`}>
              <ControlPumpDetailItem
                key={pumpDetail.id}
                loadStatus={false}
                pumpDetail={pumpDetail}
                showPumpSpecs={false}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

PumpList.defaultProps = {
  history: {},
};

PumpList.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    location: PropTypes.shape({
      selectedObj: PropTypes.object,
    }),
  }),
};
