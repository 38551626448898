import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import { Link } from 'react-router-dom';
import { PageHeader } from '../components/PageHeader';
import { RanchBlockSelect } from './RanchBlockSelect';
import { IRSensorListItem } from './IRSensorListItem';
import { installState } from '../../../constants';
import { getLoadingStatusForListOfSensors } from '../../../helper/listHelper';
import { IRSensorSummary } from './ListCards/IRSensorSummary';
import { isMobile } from '../../../utils/detectDevice';

const {
  selectBlocksForRanchBlockSelection,
  selectAllSensorsForBlockIdsByType,
} = selectors;

export function IRSensorList(props) {
  const { history } = props;
  const { t } = useTranslation();
  const [selectedObj, setSelectedObj] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const state = useSelector((store) => store);
  const sensorStatus = state.sensorsData.status.entities;

  useEffect(() => {
    if (history && history.location && history.location.selectedObj) {
      setSelectedObj(history.location.selectedObj);
    }
  }, [history]);

  useEffect(() => {
    const blocks = selectBlocksForRanchBlockSelection(state, selectedObj);
    const blockIds = blocks.map((item) => item.id);

    const sensors = selectAllSensorsForBlockIdsByType(state, {
      blockIds,
      installState: installState.INSTALLED,
    });

    setList(sensors.farmx_infrared || []);
  }, [selectedObj, state]);

  useEffect(() => {
    setLoading(getLoadingStatusForListOfSensors(list, sensorStatus));
  }, [list, sensorStatus]);

  const showList = Boolean(!loading && list.length);

  return (
    <div className="div-default padding-10 page-content device-summary-list">
      <div className="desktop-container">
        <PageHeader
          showBack
          title={t('IR Sensors')}
          toPath="/list"
        />
        {isMobile && (
        <RanchBlockSelect
          selectedObj={selectedObj}
          setSelectedObj={setSelectedObj}
        />
        )}
        <div className="list-container">
          <IRSensorSummary
            list={list}
          />

          {showList && (
            <>
              {list.map((ps) => {
                const status = sensorStatus[`${ps.type}/${ps.identifier}`];
                if (status && status.visible !== false) {
                  return (
                    <Link key={ps.identifier} to={`/list/infrared-sensor/${ps.type}/${ps.identifier}`}>
                      <IRSensorListItem
                        key={ps.id}
                        sensorDetail={{
                          ...ps,
                          ...status,
                        }}
                      />
                    </Link>
                  );
                }
                return null;
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

IRSensorList.defaultProps = {
  history: {},
};

IRSensorList.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    location: PropTypes.shape({
      selectedObj: PropTypes.object,
    }),
  }),
};
