import React from 'react';
import PropTypes from 'prop-types';
import { sensorTitles, SensorAlarmButton } from 'farmx-web-ui';
import { useTranslation } from 'react-i18next';
import './sensor-detail-header.less';

export function SensorDetailHeader({
  sensorProperties: {
    type,
    location,
    alarmCount,
    identifier,
    // sensorStatus, // needs more information
  },
  showAlarmIcon,
  showSensorType,
}) {
  const { t } = useTranslation();
  return (
    <div className="flex-row margin-top-10 sensor-detail-header">
      <div className="flex-column">
        {showSensorType ? (
          <div className="flex-row">
            <div className="flex-column">
              <div className="sensor-header-type">{t(sensorTitles[type]?.singular || '')}</div>
            </div>
          </div>
        ) : null}
        <div className="sensor-header-location">
          {`${location.lat} ${location.lng}`}
        </div>
      </div>
      {showAlarmIcon
        ? (
          <div className="sensor-alarm-status-block">
            <SensorAlarmButton
              isMobileView
              alarmCount={alarmCount}
              identifier={identifier}
              type={type}
            />
            {/* <div className="sensor-header-status">
          Status
        </div> */}
          </div>
        ) : null}
    </div>
  );
}

SensorDetailHeader.propTypes = {
  sensorProperties: PropTypes.shape({
    identifier: PropTypes.string,
    type: PropTypes.string,
    alarmCount: PropTypes.number,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }).isRequired,
  showAlarmIcon: PropTypes.bool,
  showSensorType: PropTypes.bool,
};

SensorDetailHeader.defaultProps = {
  showAlarmIcon: true,
  showSensorType: true,
};
