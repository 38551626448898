import { blockApi } from 'farmx-api';

export function requestImagingServicesForBlock(callback) {
  blockApi.requestImagingServices().then(() => callback(true)).catch(() => callback(false));
}

export function getStateForAnomalySeverity(severity) {
  if (severity === 'low') {
    return 'good';
  }
  if (severity === 'moderate') {
    return 'poor';
  }
  if (severity === 'major') {
    return 'bad';
  }
  if (severity === 'critical') {
    return 'critical';
  }
  return undefined;
}

export function blockAnomalyErrorReport(errorObj, callback) {
  blockApi.anomalyErrorReport(errorObj).then((resp) => {
    callback(true, resp);
  }).catch((err) => {
    callback(false, err);
  });
}

/**
 * Below Function will do data preparation for the anomaly detail page
 */
export function useAnomalyDataForRecommendation(anomaly, recommendations,
  anomalyForBlock, propAnomaly) {
  const anomalyIds = recommendations.map((d) => d?.imageryAnomaly);
  const filteredAnomaly = anomalyForBlock.filter((d) => anomalyIds.includes(d?.properties?.id));

  let anomalyData = {};
  if (anomaly?.length) {
    const anomalyArr = anomaly.map((d) => d?.anomaly);
    if (anomalyArr?.length) {
      [anomalyData] = anomalyArr;
    }
  } else if (propAnomaly) anomalyData = propAnomaly;
  else if (!anomaly && filteredAnomaly?.length) [anomalyData] = filteredAnomaly;

  let anomalyDataForRender = [];
  if (anomaly?.length) anomalyDataForRender = anomaly.map((d) => d?.anomaly);
  else if (propAnomaly) anomalyDataForRender = [propAnomaly];
  else if (!anomaly && filteredAnomaly?.length) {
    anomalyDataForRender = filteredAnomaly;
  }
  const recommendation = recommendations?.filter((d) => (
    d?.block === anomaly?.[0]?.block)
    && (d?.imageryAnomaly === anomaly?.[0]?.imageryAnomaly));

  anomalyDataForRender = anomalyDataForRender.map((anomalyDataIndividual) => {
    const equivalentRecommendation = recommendation.find((rec) => (
      rec.block === anomalyDataIndividual?.properties.block
      && rec.imageryAnomaly === anomalyDataIndividual?.properties.id
    ));
    return {
      ...anomalyDataIndividual,
      properties: {
        ...(anomalyDataIndividual?.property || {}),
        ...(equivalentRecommendation || {}),
      },
    };
  });

  return { anomalyData, anomalyDataForRender, recommendation };
}
