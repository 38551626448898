import { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { scheduleApi } from 'farmx-api';
import { irrigationStatus } from '../constants';

const dateStart = moment().toDate();
const dateEnd = moment().add(7, 'days').toDate();

/**
 * It returns status based on following conditions:
 *
 * Irrigation Scheduled: If the events has data.
 *
 * Irrigating now: If the events has such data where current date lies in
 * between start and stop date.
 *
 * Not irrigating: If the events is empty
 */
const getIrrigationDetails = (events, t) => {
  if (events && events.length) {
    const irrigatingEvent = events.find((event) => (
      moment(event.start_date).isSameOrBefore(moment(dateStart))
      && moment(event.stop_date).isAfter(moment(dateStart))
    ));
    if (irrigatingEvent) {
      return {
        description: `${moment(irrigatingEvent.stop_date).fromNow(true)} ${t('remaining')}`,
        status: irrigationStatus.IRRIGATING,
        title: 'Irrigating Now',
      };
    }
    const [firstEvent] = events;
    return {
      description: `${moment(firstEvent.start_date).format('ddd, h:mmA')} - ${moment(firstEvent.stop_date).format('ddd, h:mmA')}`,
      status: irrigationStatus.SCHEDULED,
      title: 'Irrigation Scheduled',
    };
  }
  return {
    description: '',
    status: irrigationStatus.NOT_SCHEDULED,
    title: 'Irrigation Not Scheduled',
  };
};

export const useIrrigationStatus = (blockId) => {
  const { t } = useTranslation();
  const [statusDetails, setStatusDetails] = useState({
    description: '',
    status: irrigationStatus.NOT_SCHEDULED,
    title: 'Irrigation Not Scheduled',
  });
  const loadSchedule = useCallback(async () => {
    const response = await scheduleApi.retrieveBlockRenderedIrrigationEvents({
      blockId, dateStart, dateEnd,
    });
    const irrigationDetails = getIrrigationDetails(response?.data, t);
    setStatusDetails(irrigationDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockId]);

  useEffect(() => {
    loadSchedule();
  }, [loadSchedule]);

  return [statusDetails];
};
