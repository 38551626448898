import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hooks, selectors } from 'farmx-redux-core';
import { farmApi } from 'farmx-api';
import { Button, InputNumber } from 'antd';
import './chillHoursAndPortions.less';
import { LoadingOutlined } from '@ant-design/icons';
import { CollapseWrapper } from './CollapseWrapper';
import { isMobile } from '../../../utils/detectDevice';
import RanchBlockTitle from '../components/RanchBlockTitle';

export const GrowingDegree = () => {
  const { t } = useTranslation();
  const { blockIds } = hooks.useRanchBlockSelection();
  const blockDetails = useSelector((state) => selectors.selectBlockById(state, blockIds[0]));
  const ranchId = blockDetails && blockDetails.ranch;

  const [gddLowerBound, setGddLowerBound] = useState(50);
  const [gddUpperBound, setGddUpperBound] = useState(86);
  const [growingDegreeList, setGrowingDegreeList] = useState(null);
  const [loading, setLoading] = useState(false);

  const getGDDData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await farmApi.getGDD({ ranchId, lower: gddLowerBound, upper: gddUpperBound });
      if (res?.data) {
        setGrowingDegreeList(res?.data?.gdd);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  }, [gddLowerBound, gddUpperBound, ranchId]);

  useEffect(() => {
    getGDDData();
  }, [getGDDData]);

  function renderGrowingDegree() {
    return (
      <div className="summary-information-info summary-gdd layout-column">
        <div className="layout-row align-center">
          <div className={!isMobile ? 'gdd-form layout-row flex-100'
            : 'gdd-form layout-column flex-100'}
          >
            <div className={!isMobile ? 'form-group flex-100 layout-column'
              : 'form-group flex-100 layout-row justify-content-space-around flex-row'}
            >
              <label htmlFor="lowerCutoff">
                {`${t('Lower Cutoff')} `}
                (&deg;F)
              </label>
              <InputNumber
                id="lowerCutoff"
                className="form-control"
                step={0.01}
                defaultValue={gddLowerBound}
                onChange={setGddLowerBound}
                required
              />
            </div>

            <div className={!isMobile ? 'form-group flex-100 layout-column'
              : 'form-group flex-100 layout-row justify-content-space-around flex-row'}
            >
              <label htmlFor="upperCutoff">
                {`${t('Upper Cutoff')} `}
                (&deg;F)
              </label>
              <InputNumber
                id="upperCutoff"
                className="form-control"
                step={0.01}
                defaultValue={gddUpperBound}
                onChange={setGddUpperBound}
                required
              />
            </div>
            <div className={!isMobile ? 'form-group flex-50 layout-column'
              : 'form-group justify-content-space-around flex-row'}
            >
              <Button className="btn" type="ghost" onClick={() => getGDDData()} block>
                {t('Update')}
              </Button>
            </div>
          </div>
        </div>
        {!loading && (
        <>
          {growingDegreeList?.length ? growingDegreeList?.map((d) => (
            <>
              <div className="summary-detail layout-row">{d?.block?.name}</div>
              <span className="status-data layout-row align-center">{d?.gdd}</span>
            </>
          )) : <span className="status-data layout-row align-center">{t('No data')}</span>}
        </>
        )}
        {loading && <LoadingOutlined className="status-data layout-row align-center" />}
      </div>
    );
  }

  const headerTitle = `${t('Growing Degree Days')} (${t('Since Jan 1')})`;

  return (
    <CollapseWrapper title={isMobile ? <RanchBlockTitle title={headerTitle} /> : headerTitle}>
      {renderGrowingDegree()}
    </CollapseWrapper>
  );
};
