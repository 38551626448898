import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { stateTagColorCodes, stateTagTextColorCodes } from 'farmx-web-ui';

export const StatusTag = ({
  width,
  text,
  state,
  tagStyle,
  icon,
}) => {
  const { t } = useTranslation();
  return (
    <Tag
      color={stateTagColorCodes[state]}
      style={{
        ...{ width, textAlign: 'center' },
        ...tagStyle,
      }}
    >
      <div
        style={{ display: 'flex', justifyContent: 'start', color: stateTagTextColorCodes[state] }}
      >
        {!icon ? <div><i className="icon icon-eyedropper icon-white" /></div>
          : (
            <div className="status-tag-icon-alignment">
              <i className={`icon ${icon} icon-white`} />
            </div>
          )}
        <div style={{ textTransform: 'capitalize', marginLeft: '4px' }}>{t(text)}</div>
      </div>
    </Tag>
  );
};

StatusTag.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  state: PropTypes.string.isRequired,
  tagStyle: PropTypes.shape({}),
  icon: PropTypes.func,
};

StatusTag.defaultProps = {
  tagStyle: {},
  width: null,
  icon: null,
};
