import React, { useEffect } from 'react';
import { matchPath } from 'react-router';
import { Link, withRouter, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { CollapsableSidebar, RanchBlockSelect } from 'farmx-web-ui';
import { useSelector, useDispatch } from 'react-redux';
import { actions, hooks, selectors } from 'farmx-redux-core';
import {
  FaHome,
  FaChartLine,
  FaList,
  FaToggleOn,
  FaMapMarkedAlt,
  FaRegCalendarAlt,
} from 'react-icons/fa';
import { ROUTES } from './routes';
import './Sidebar.css';

const {
  selectLoginUserInfo,
  selectSelectedBlocks,
} = selectors;

const {
  setRanchBlockSelection,
  setBlocks, setRanch, setUrlLoad,
} = actions;

const { useRanchBlockSelection } = hooks;

const getMatchedKey = (location) => {
  const matchedKey = Object.values(ROUTES).find((route) => matchPath(location.pathname, route.key));
  if (matchedKey === undefined) return undefined;
  return matchedKey.key;
};

function Sidebar(props) {
  const location = useLocation();
  const {
    isMobile,
    onCollapse,
    collapsed,
  } = props;
  const dispatch = useDispatch();
  const userInfo = useSelector(selectLoginUserInfo).payload;

  const { selectedObjFromState, blockIds } = useRanchBlockSelection();
  const selectedBlocks = useSelector((state) => selectSelectedBlocks(state));

  const blocksFromState = selectedBlocks?.payload || [];
  useEffect(() => {
    if (blockIds?.length && !blocksFromState?.length && selectedObjFromState?.value) {
      dispatch(setBlocks(blockIds));
    }
  }, [dispatch, blockIds, blocksFromState, selectedObjFromState]);

  function collapseIfMobile() {
    if (isMobile && onCollapse) onCollapse();
    return null;
  }

  function handleSelect(d) {
    if (d && d.value !== undefined) {
      dispatch(setUrlLoad(false));
      dispatch(setRanchBlockSelection(d));
      if (d?.type === 'block') dispatch(setBlocks([Number(d?.value)]));
      if (d?.type === 'ranch') dispatch(setRanch(Number(d?.value)));
    }
  }

  return (
    <CollapsableSidebar
      theme="light"
      onCollapse={onCollapse}
      collapsed={collapsed}
    >
      {!collapsed
        && (
          <div className="div-select-container ranch-block-select-container
           sidebar-select-margin"
          >
            {!isMobile && (
            <RanchBlockSelect
              selected={selectedObjFromState}
              onSelect={handleSelect}
              admin={userInfo && userInfo.admin}
              showFullHeight
            />
            )}
          </div>
        )}
      <Menu
        className="nav-menu"
        theme="light"
        selectedKeys={[getMatchedKey(location)]}
        mode="inline"
        onClick={collapseIfMobile}
      >
        <Menu.Item key={ROUTES.RECOMMENDATION.key}>
          <Link to={ROUTES.RECOMMENDATION.path}>
            <span className="menu-icon"><FaHome /></span>
            <span className="menu-title">HOME</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.MAP.key}>
          <Link to={ROUTES.MAP.path}>
            <span className="menu-icon"><FaMapMarkedAlt /></span>
            <span className="menu-title">MAP</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.LIST.key}>
          <Link to={ROUTES.LIST.path}>
            <span className="menu-icon"><FaList /></span>
            <span className="menu-title">DEVICES</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.GRAPH.key}>
          <Link to={ROUTES.GRAPH.path}>
            <span className="menu-icon"><FaChartLine /></span>
            <span className="menu-title">GRAPH</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.SCHEDULE.key}>
          <Link to={ROUTES.SCHEDULE.path}>
            <span className="menu-icon"><FaRegCalendarAlt /></span>
            <span className="menu-title">SCHEDULE</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ROUTES.CONTROL.key}>
          <Link to={ROUTES.CONTROL.path}>
            <span className="menu-icon"><FaToggleOn /></span>
            <span className="menu-title">CONTROL</span>
          </Link>
        </Menu.Item>
      </Menu>
    </CollapsableSidebar>
  );
}

Sidebar.propTypes = {
  isMobile: PropTypes.bool,
  onCollapse: PropTypes.func,
  collapsed: PropTypes.bool,
};

Sidebar.defaultProps = {
  isMobile: false,
  onCollapse: undefined,
  collapsed: false,
};

export default withRouter(Sidebar);
