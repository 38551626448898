import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hooks, selectors } from 'farmx-redux-core';
import moment from 'moment';
import { farmApi } from 'farmx-api';
import { Table, DatePicker, Tooltip } from 'antd';
import './chillHoursAndPortions.less';
import { LoadingOutlined } from '@ant-design/icons';
import { displayPrecipitation } from '../../../helper/listHelper';
import { CollapseWrapper } from './CollapseWrapper';
import { isMobile } from '../../../utils/detectDevice';

const { useUnits } = hooks;

function dateRange() {
  const today = moment().subtract(8, 'd');
  const res = Array(7).fill().map(
    () => today.add(1, 'd').format('DD MMM'),
  );
  return res;
}
const precipitationTableHeaders = ['Name', 'Type', ...dateRange(), 'Today'];

export const CumulativePrecipitations = (props) => {
  const { fromUnits } = props;

  const { t } = useTranslation();
  const { blockIds } = hooks.useRanchBlockSelection();
  const blockDetails = useSelector((state) => selectors.selectBlockById(state, blockIds[0]));
  const ranchId = blockDetails && blockDetails.ranch;
  const getUserUnits = useUnits();

  const [precipitation, setPrecipitation] = useState(null);
  const [cumulativePrecipitation, setCumulativePrecipitation] = useState(null);
  const [cumulativePrecipitation1, setCumulativePrecipitation1] = useState(null);
  const [precipitationStartDate, setPrecipitationStartDate] = useState(moment().startOf('year'));
  const [precipitationEndDate, setPrecipitationEndDate] = useState(moment());
  const [precipitationStartDate1, setPrecipitationStartDate1] = useState(moment()
    .startOf('year').subtract(1, 'year'));
  const [precipitationEndDate1, setPrecipitationEndDate1] = useState(moment(precipitationStartDate1)
    .endOf('year'));
  const [loading1, setLoading1] = useState(false);
  const [loading, setLoading] = useState(false);

  const getRanchPrecipitation = useCallback(async () => {
    try {
      const res = await farmApi.getRanchRainfallDaily(ranchId);
      if (res && res.status === 200 && res.data) {
        setPrecipitation(res.data.sensors);
      }
    } catch {
      // continue regardless of error
    }
  }, [ranchId]);

  const getPrecipitation = useCallback(async (startDate, endDate, stateFunc, loadingFunc) => {
    try {
      loadingFunc(true);
      const res = await farmApi.getCumulativePrecipitation({
        ranchId,
        start: startDate.valueOf(),
        end: endDate.valueOf(),
      });
      if (res && res.status === 200 && res.data) {
        stateFunc(res.data.precip);
        loadingFunc(false);
      }
    } catch (err) {
      loadingFunc(false);
    }
  }, [ranchId]);

  useEffect(() => {
    getPrecipitation(precipitationStartDate, precipitationEndDate,
      setCumulativePrecipitation, setLoading);
    getPrecipitation(precipitationStartDate1, precipitationEndDate1,
      setCumulativePrecipitation1, setLoading1);
    getRanchPrecipitation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPrecipitation, getRanchPrecipitation]);

  function renderWeatherStationType(weatherStationType) {
    if (weatherStationType === 'weather_station') {
      return 'Local';
    }
    if (weatherStationType === 'cimis_station') {
      return 'Cimis';
    }
    if (weatherStationType === 'twc_station') {
      return 'Virtual';
    }

    return weatherStationType;
  }

  function renderPrecipitationTable() {
    return (
      <div className="summary-information-info summary-precipitation-status">
        <div className="precipitation-status">
          <div className="status-header layout-row">
            {precipitationTableHeaders?.map((d, index) => (
              <>
                {index < 1 && (
                  <div className="layout-row flex-100 align-left">
                    <span>{d}</span>
                  </div>
                )}
                {index >= 1 && (
                  <div className="layout-row flex-50 align-center">
                    <span>{d}</span>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
        {precipitation && precipitation.map((weatherStationData) => (
          <div
            className="status-data layout-row"
            key={weatherStationData.identifier + weatherStationData.type}
          >
            {precipitationTableHeaders?.map((d, index) => (
              <>
                {index === 0 && (
                  <div className="layout-row flex-100 align-left">
                    <span>{weatherStationData.name}</span>
                  </div>
                )}
                {index === 1 && (
                  <div className="layout-row flex-50 align-center">
                    <span>{renderWeatherStationType(weatherStationData.type)}</span>
                  </div>
                )}
                {index >= 2 && weatherStationData.dailyRainfall && (
                  <div className="layout-row flex-50 align-center">
                    <span>
                      {parseFloat(
                        Number(weatherStationData.dailyRainfall[index - 2]).toFixed(2),
                      )}
                    </span>
                  </div>
                )}
              </>
            ))}
          </div>
        ))}
        {!precipitation && (
        <div className="status-data layout-row flex-100 align-center">
          <span>{t('No data')}</span>
        </div>
        )}
      </div>
    );
  }

  function onChange(start, end, isPrevious) {
    const stateFunc = isPrevious ? setCumulativePrecipitation1 : setCumulativePrecipitation;
    const loadingFunc = isPrevious ? setLoading1 : setLoading;
    getPrecipitation(start, end, stateFunc, loadingFunc);
  }

  function renderDatePicker(start, end, dateType) {
    const isPrevious = dateType.includes('previous');
    const isStart = dateType.includes('start');
    return (
      <DatePicker
        value={isStart ? start : end}
        showTime={false}
        onSelect={(d) => {
          if (isStart && isPrevious) setPrecipitationStartDate1(d);
          if (!isStart && isPrevious) setPrecipitationEndDate1(d);
          if (isStart && !isPrevious) setPrecipitationStartDate(d);
          if (!isStart && !isPrevious) setPrecipitationEndDate(d);
          onChange(isStart ? d : start, !isStart ? d : end, isPrevious);
        }}
        allowClear={false}
      />
    );
  }

  const dataSource = [
    {
      key: '1',
      startDate: renderDatePicker(precipitationStartDate1, precipitationEndDate1, 'previous-start'),
      endDate: renderDatePicker(precipitationStartDate1, precipitationEndDate1, 'previous-end'),
      precipitation: { record: cumulativePrecipitation1, loadingState: loading1 },
    },
    {
      key: '2',
      startDate: renderDatePicker(precipitationStartDate, precipitationEndDate, 'current-start'),
      endDate: renderDatePicker(precipitationStartDate, precipitationEndDate, 'current-end'),
      precipitation: { record: cumulativePrecipitation, loadingState: loading },
    },
  ];

  const getBlockName = (e) => e?.block?.name;

  const columns = [
    {
      title: t('Start Date'),
      dataIndex: 'startDate',
      key: 'startDate',
    },
    {
      title: t('End Date'),
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: `${t('Cumulative Precipitation')} (inches)`,
      dataIndex: 'precipitation',
      key: 'precipitation',
      render: (tabRow) => {
        const { record, loadingState } = tabRow;
        return (
          <div className="precipitation-column-style">
            {!loadingState && (
            <>
              {record?.length ? record?.map((d) => (
                <>
                  <div className={isMobile ? 'summary-detail layout-row'
                    : 'summary-detail layout-row'}
                  >
                    {isMobile ? (
                      <Tooltip
                        title={getBlockName(d).length > 10
                          ? getBlockName(d) : ''}
                        trigger="click"
                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                      >
                        <div className="header-ellipsis">
                          {getBlockName(d)}
                        </div>
                      </Tooltip>
                    )
                      : <div>{getBlockName(d)}</div>}
                  </div>
                  <span className="status-data layout-row align-center">
                    {displayPrecipitation(d?.precip, fromUnits, getUserUnits)}
                  </span>
                </>
              )) : <span className="status-data layout-row align-center">{t('No data')}</span>}
            </>
            )}
            {loadingState && <LoadingOutlined className="status-data layout-row align-center" />}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <CollapseWrapper title={`${t('Precipitation')} (in.)`}>
        {renderPrecipitationTable()}
      </CollapseWrapper>

      <CollapseWrapper title={`${t('Cumulative Precipitation')}`}>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </CollapseWrapper>
    </div>
  );
};

CumulativePrecipitations.defaultProps = {
  fromUnits: {
    airTemperature: 'degrees_fahrenheit',
    precipitation: 'inches',
  },
};

CumulativePrecipitations.propTypes = {
  fromUnits: PropTypes.shape({
    airTemperature: PropTypes.string,
    precipitation: PropTypes.string,
  }),
};
