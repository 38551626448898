import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { truncate } from 'lodash';

const colorClass = {
  primary: 'primary-color',
  secondary: 'secondary-color',
  success: 'success-color',
  danger: 'danger-color',
  disabled: 'text-color-unknown',
};
export function ValueUnitInfo(props) {
  const {
    ellipsis,
    info,
    maxInfoLength,
    showDegree,
    type,
    unit,
    value,
  } = props;

  const formattedInfo = ellipsis ? truncate(info, { length: maxInfoLength }) : info;
  const isCWSI = unit === 'CWSI';
  const isMedStress = value === 'MED STRESS';

  return (
    <span className="value-unit-info flex-column map-valve-info">
      <span className="value-container flex-row">
        <Typography.Title
          level={2}
          className={isCWSI || isMedStress ? `value-text-med-crop-stress ${colorClass[type]}`
            : `value-text ${colorClass[type]}`}
        >
          {typeof value === 'number' && isCWSI ? value.toFixed(2) : value }
        </Typography.Title>
        <span className="unit-info flex-column">
          <Typography.Text
            strong
            className={`unit-text ${colorClass[type]} ${showDegree ? 'degree' : ''}`}
          >
            {unit}
          </Typography.Text>
        </span>
      </span>
      <Typography.Text type="secondary" className="info-text">{formattedInfo}</Typography.Text>
    </span>
  );
}

ValueUnitInfo.defaultProps = {
  ellipsis: false,
  info: '',
  showDegree: false,
  maxInfoLength: 15,
  type: 'primary',
  unit: '',
  value: '',
};

ValueUnitInfo.propTypes = {
  ellipsis: PropTypes.bool,
  info: PropTypes.string,
  maxInfoLength: PropTypes.number,
  showDegree: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger']),
  unit: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
