// using cloneDeep to make data more less immutable
import cloneDeep from 'lodash/cloneDeep';

export const initReducerMapMobile = () => ({
  isBottomSheetVisible: true,
  // mobilePaddingBottom: 20,
  recenterGeoJSON: undefined,
  zoomSensorsVisible: false,
  selectedFeature: undefined,
  anomalyGeoJSON: [],
  // bottomSheetType enum:
  // 'settings' 'sensor' 'block' 'ranch' 'all_ranches' undefined
  // currently using 'settings' 'sensor' undefined
  bottomSheetType: undefined,
  isLoading: false,
  isExpandBtnVisible: true,
  ranchesAllGeoJSON: {
    type: 'FeatureCollection',
    features: [],
  },
  ranchesPointsGeoJSON: {
    type: 'FeatureCollection',
    features: [],
  },
  isDataLoading: true,
  showMap3D: false,
  selectedImageryData: {
    visible: false,
    data: undefined,
  },
  selectedImageryDataOpacity: 90,
  isImageryPanelVisible: false,
  sensorsByRanchIdGeoJSON: [],
  latestReload: undefined,
  ranchIds: [],
  anomalyLayerData: null,
  sideBySideMapVisible: false,
  showLayer: {
    soil: { left: { visible: false }, right: { visible: false } },
    side: 'left',
    type: undefined,
    visible: false,
  },
  layersControl: { side: 'left', type: 'tab' },
});

export function reducerMapMobile(state, action) {
  switch (action.type) {
    case 'setIsExpandBtnVisible': {
      return {
        ...state,
        isExpandBtnVisible: action.payload,
      };
    }
    case 'setShowLayer': {
      return {
        ...state,
        showLayer: action.payload,
      };
    }
    case 'setLayersControl': {
      return {
        ...state,
        layersControl: action.payload,
      };
    }
    case 'setIsLoading': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'setAnomalyLayerData': {
      return {
        ...state,
        anomalyLayerData: action.payload,
      };
    }
    case 'setZoomSensorsVisible': {
      const zoom = action.payload;
      if (zoom < 13 && state.zoomSensorsVisible) {
        return {
          ...state,
          zoomSensorsVisible: false,
        };
      }
      if (zoom >= 13 && !state.zoomSensorsVisible) {
        return {
          ...state,
          zoomSensorsVisible: true,
        };
      }
      return state;
    }

    case 'setUpdateRecenterGeoJSON': {
      try {
        const r = cloneDeep(state.recenterGeoJSON);
        if (!r?.features?.length) {
          return state;
        }
        // update data to force rerender
        if (r?.features[0]) r.features[0].t = (new Date()).getTime();
        return {
          ...state,
          recenterGeoJSON: r,
        };
      } catch (error) {
        console.log('ERROR in setUpdateRecenterGeoJSON', error);
        return state;
      }
    }

    case 'setRecenterGeoJSON': {
      try {
        const r = cloneDeep(action.payload);
        if (!r?.features?.length) {
          return state;
        }
        // update data to force render
        if (r?.features[0]) r.features[0].t = (new Date()).getTime();
        return {
          ...state,
          recenterGeoJSON: r,
        };
      } catch (error) {
        console.log('Error in setRecenterGeoJSON', error);
        return state;
      }
    }

    case 'setIsBottomSheetVisible': {
      const visible = !!cloneDeep(action.payload);
      return {
        ...state,
        isBottomSheetVisible: visible,
      };
    }

    case 'setSettings': {
      if (!state.isBottomSheetVisible) {
        return {
          ...state,
          isBottomSheetVisible: true,
          bottomSheetType: 'settings',
        };
      }
      if (state.isBottomSheetVisible && state.bottomSheetType !== 'settings') {
        return {
          ...state,
          bottomSheetType: 'settings',
        };
      }
      return {
        ...state,
        isBottomSheetVisible: false,
      };
    }

    case 'setSelectedFeatureWithType': {
      const { feature, type, showBottomSheet } = cloneDeep(action.payload);
      return {
        ...state,
        selectedFeature: feature,
        bottomSheetType: type,
        isBottomSheetVisible: showBottomSheet,
      };
    }
    case 'setRanchIds':
      return {
        ...state,
        ranchIds: action.payload,
      };
    case 'setRanchesAllGeoJSON':
      return {
        ...state,
        ranchesAllGeoJSON: action.payload,
      };
    case 'setRanchesPointsGeoJSON':
      return {
        ...state,
        ranchesPointsGeoJSON: action.payload,
      };
    case 'setLatestReload':
      return {
        ...state,
        latestReload: Date.now(),
      };
    case 'setSensorsByRanchIdGeoJSON':
      return {
        ...state,
        sensorsByRanchIdGeoJSON: action.payload,
        isDataLoading: false,
      };
    case 'setIsImageryPanelVisible':
      return {
        ...state,
        isImageryPanelVisible: action.payload,
      };
    case 'setSelectedImageryData':
      return {
        ...state,
        selectedImageryData: action.payload,
      };
    case 'setShowMap3D':
      return {
        ...state,
        showMap3D: action.payload,
      };
    case 'setAnomalyGeoJSON': {
      return {
        ...state,
        anomalyGeoJSON: action.payload,
      };
    }
    case 'setSideBySideMapVisible':
      return {
        ...state,
        sideBySideMapVisible: action.payload,
      };
    case 'reset':
      return initReducerMapMobile();
    default:
      throw new Error('Unkown action type');
  }
}
