import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import { initReducerSatelliteImagery, reducerSatelliteImagery } from './reducerSatellite';
import { SatelliteImagery } from '../../map/components/satelliteimagery/SatelliteImagery';
import {
  useRanchBlockPreference,
  useSatelliteImagery,
  useSatelliteDataDatesAndTypes,
  useSatelliteDateRangePreparation,
  useDefaultSelectedImageryData,
  useRequestSatelliteData,
} from '../../../helper/satelliteHooks';
import { isMobile } from '../../../utils/detectDevice';
import { isImageryUpdateNeeded } from '../../../helper/mapHelper';

const {
  selectSelectedRanch,
  selectSelectedBlock,
  selectSelectedBlocks,
} = selectors;

export function SatelliteImageryWrapper(props) {
  const {
    ranchId, dispatchMap, childComponent, isLeft,
  } = props;

  const selectedRanchIdFromState = useSelector((state) => selectSelectedRanch(state));
  const selectedBlockIdFromState = useSelector((state) => selectSelectedBlock(state));
  const selectedBlockIdsFromState = useSelector((state) => selectSelectedBlocks(state));
  const blockIds = selectedBlockIdsFromState?.payload;

  // use local reducer to handle state of the component
  const [stateSatellite, satelliteDispatch] = useReducer(
    reducerSatelliteImagery,
    initReducerSatelliteImagery(),
    initReducerSatelliteImagery,
  );

  const [stateSatellite1, satelliteDispatch1] = useReducer(
    reducerSatelliteImagery,
    initReducerSatelliteImagery(),
    initReducerSatelliteImagery,
  );

  const satelliteDispatchArg = isLeft ? satelliteDispatch : satelliteDispatch1;
  const {
    selectedImageryData, isImageryPanelVisible,
    selectedRanchId, selectedBlockId, latestReload,
    satelliteDates, selectedSatelliteDataType, selectedSatelliteDataSource,
    selectedSatelliteDatesFiltered, selectedSatelliteDate, selectedSatelliteDatesDataRange,
    dateRangeWithDisplayData,
  } = isLeft ? stateSatellite : stateSatellite1;

  const callApi = isImageryUpdateNeeded(selectedImageryData.data, selectedSatelliteDate,
    selectedSatelliteDatesDataRange, selectedSatelliteDataType, selectedBlockIdFromState?.payload,
    ranchId, selectedRanchIdFromState?.payload?.[0], blockIds);

  // Return the imagery data to parent component when changing
  useEffect(() => {
    if (selectedImageryData && dispatchMap) {
      dispatchMap({
        type: 'setSelectedImageryData',
        payload: selectedImageryData,
      });
    }
  }, [dispatchMap, selectedImageryData]);

  // To close the satellite imagery drawer for desktop
  useEffect(() => {
    if (!isImageryPanelVisible && dispatchMap && !isMobile) {
      dispatchMap({
        type: 'setIsImageryPanelVisible',
        payload: isImageryPanelVisible,
      });
    }
  }, [dispatchMap, isImageryPanelVisible]);

  // Set ranch/block preference to local reducer
  useRanchBlockPreference(
    selectedBlockIdFromState?.payload?.length ? undefined : selectedRanchIdFromState,
    selectedBlockIdFromState,
    satelliteDispatchArg,
  );

  // get satellite imagery using local reducer
  useSatelliteImagery(
    selectedRanchId,
    selectedBlockId,
    latestReload,
    callApi,
    satelliteDispatchArg,
  );

  // prepare satellite data dates and types using local reducer
  useSatelliteDataDatesAndTypes(
    satelliteDates,
    selectedSatelliteDataType,
    selectedSatelliteDataSource,
    satelliteDispatchArg,
  );

  // SatelliteDateRange preparation using local reducer
  useSatelliteDateRangePreparation(
    selectedSatelliteDatesFiltered,
    selectedSatelliteDate,
    selectedSatelliteDatesDataRange,
    selectedSatelliteDataType,
    selectedSatelliteDataSource,
    selectedRanchId,
    selectedBlockId,
    satelliteDispatchArg,
  );

  // To maintain the selected imagery data on the map
  useDefaultSelectedImageryData(
    dateRangeWithDisplayData,
    selectedImageryData,
    selectedSatelliteDate,
    satelliteDispatchArg,
  );

  // request satellite imagery data
  useRequestSatelliteData(selectedSatelliteDatesDataRange, callApi, satelliteDispatchArg);

  return (
    <div className="flex-row padding-10" id="satellite-imagery-section">
      <div className="flex-column switch-box layout-width">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {!!ranchId && (
          <SatelliteImagery
            dispatchMapPage={satelliteDispatchArg}
            satelliteDates={satelliteDates}
            selectedSatelliteDate={selectedSatelliteDate}
            selectedSatelliteDataType={selectedSatelliteDataType}
            selectedSatelliteDataSource={selectedSatelliteDataSource}
            selectedSatelliteDatesFiltered={selectedSatelliteDatesFiltered}
            dateRangeWithDisplayData={dateRangeWithDisplayData}
            selectedImageryData={selectedImageryData}
            isMobile={isMobile}
            childComponent={childComponent}
          />
          )}
        </div>
      </div>
    </div>
  );
}

SatelliteImageryWrapper.propTypes = {
  ranchId: PropTypes.number,
  dispatchMap: PropTypes.func,
  childComponent: PropTypes.func,
  isLeft: PropTypes.bool,
};

SatelliteImageryWrapper.defaultProps = {
  ranchId: null,
  dispatchMap: null,
  childComponent: null,
  isLeft: true,
};
