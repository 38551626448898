import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  notification,
  Input,
} from 'antd';
import { useTracking } from '../../../helper/mixpanel';
import { blockAnomalyErrorReport } from '../../../helper/anomalyHelper';
import AnomalyModalButtons from './AnomalyModalButtons';
import AnomalyLayers from './AnomalyLayers';
import AnomalyModalContents from './AnomalyModalContents';

export default function AnomalyModal(props) {
  const {
    modal, setModal, latLngText, showMap, selMapShowSoilType,
    anomalyData, anomalyDispatch, isDisabled,
    deleteAnomaly, selectedImageryData,
  } = props;
  const tracking = useTracking();
  const { t } = useTranslation();
  const [description, setDescription] = useState(null);
  const isUserCreated = anomalyData && anomalyData.properties
    && anomalyData.properties.is_user_created;

  function renderErrorReportContents() {
    return (
      <div className="recommendation-add-comment-section schedule-font-style">
        {t('Issue Description')}
        <div className="schedule-options-share-modal">
          <Input
            placeholder={t('Description')}
            value={description}
            onChange={(d) => setDescription(d.target.value)}
          />
        </div>
      </div>
    );
  }

  function renderConfirmDelete() {
    return (
      <div className="recommendation-add-comment-section schedule-font-style">
        <div className="schedule-options-share-modal">
          {t(`This anomaly was ${isUserCreated ? '' : 'not'} created by the user.`)}
        </div>
        <div className="schedule-options-share-modal">
          {t('Are you sure want to delete the selected anomaly?')}
        </div>
      </div>
    );
  }

  function onErrorReportButtonClick() {
    const errorObj = {
      recommendation_id: anomalyData?.properties?.id,
      recommendation_type: 'anomaly',
      issue_description: description,
    };

    blockAnomalyErrorReport(errorObj, (success) => {
      if (success) {
        notification.success({
          message: t('Anomaly error has been reported successfully'),
        });
      } else {
        notification.error({
          message: t('Failed to report the anomaly error'),
        });
      }
      setDescription(null);
    });
    tracking.track('Clicked Error Report Button');
  }

  function onModalButtonClick(type, isReport) {
    setModal({ ...modal, visible: false });
    if (type === 'cancel' && isReport) setDescription(null);
    else if (isReport) {
      onErrorReportButtonClick();
    }
  }

  function renderReportCustomButtons(btnText, isReport) {
    return [
      <div className="flex-row">
        <Button onClick={() => onModalButtonClick('cancel', isReport)} block>
          {t('Cancel')}
        </Button>
        <Button
          type="primary"
          onClick={() => onModalButtonClick(btnText, isReport)}
          block
        >
          {t(btnText)}
        </Button>
      </div>,
    ];
  }

  function getModalTitle() {
    let titleText = null;
    if (modal.type === 'report') titleText = t('Error Report');
    if (modal.type === 'delete') titleText = t('Delete Anomaly');

    return titleText;
  }

  function getFooterButtons() {
    let buttonElements = null;
    if (modal.type === 'report') buttonElements = renderReportCustomButtons('Report', true);
    if (modal.type === 'delete') {
      buttonElements = (
        <AnomalyModalButtons
          btnText="Delete"
          isDelete
          isConfirmDelete
          anomalyDispatch={anomalyDispatch}
          modal={modal}
          setModal={setModal}
          deleteAnomaly={deleteAnomaly}
          isDisabled={isDisabled}
        />
      );
    }

    return buttonElements;
  }

  return (
    <Modal
      visible={modal.visible}
      centered
      closable
      onCancel={() => onModalButtonClick('cancel', modal.type === 'report')}
      footer={getFooterButtons()}
      title={getModalTitle()}
    >
      {modal.type === 'action' && (
      <AnomalyModalContents
        actionType="action"
        setModal={setModal}
        latLngText={latLngText}
        showMap={showMap}
      />
      )}
      {modal.type === 'button' && <AnomalyModalContents actionType="learnMore" />}
      {modal.type === 'layer' && (
      <AnomalyLayers
        selMapShowSoilType={selMapShowSoilType}
        selectedImageryData={selectedImageryData}
        anomalyDispatch={anomalyDispatch}
      />
      )}
      {modal.type === 'report' && renderErrorReportContents()}
      {modal.type === 'delete' && renderConfirmDelete()}
    </Modal>
  );
}

AnomalyModal.propTypes = {
  showMap: PropTypes.bool,
  modal: PropTypes.shape({
    type: PropTypes.string,
    visible: PropTypes.bool,
  }),
  deleteAnomaly: PropTypes.bool,
  setModal: PropTypes.func,
  selectedImageryData: PropTypes.shape({
    visible: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.any),
  }),
  selMapShowSoilType: PropTypes.string,
  anomalyData: PropTypes.shape({
    properties: PropTypes.shape({
      is_user_created: PropTypes.bool,
      id: PropTypes.number,
    }),
  }),
  anomalyDispatch: PropTypes.func,
  isDisabled: PropTypes.bool,
  latLngText: PropTypes.string,
};

AnomalyModal.defaultProps = {
  showMap: true,
  modal: null,
  deleteAnomaly: false,
  selectedImageryData: null,
  setModal: null,
  selMapShowSoilType: null,
  anomalyData: null,
  anomalyDispatch: null,
  isDisabled: true,
  latLngText: null,
};
