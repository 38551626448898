import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, notification } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import { PageHeader } from '../components/PageHeader';
import { CardLayout } from '../components/CardLayout';
import { useTracking } from '../../../helper/mixpanel';
import resImage from '../../../assets/images/res-image.png';
import { requestImagingServicesForBlock } from '../../../helper/anomalyHelper';
import { recommendationAnomalyFilter, getRecommendationIdentifier } from '../../../helper/common';

const {
  selectRecommendationForBlock,
} = selectors;

export function SchedulingInvestigationPage() {
  const { t } = useTranslation();
  const tracking = useTracking();
  const location = useLocation();
  const history = useHistory();
  const { blockId } = useParams();
  const recommendation = useSelector((state) => recommendationAnomalyFilter(
    selectRecommendationForBlock(state, blockId),
  ));

  const { selectedTab, anomaly, from } = location.state ?? {};

  function customOnClick(type) {
    if (type === 'investigate') {
      requestImagingServicesForBlock((success) => {
        if (success) {
          notification.success({
            message: t('Imaging services request sent successfully!'),
          });
        } else {
          notification.error({
            message: t('Imaging services request failed'),
          });
        }
      });
      if (tracking) tracking.track('Clicked Scheduling Investigate Button');
    } else if (type === 'dismiss') {
      history.push({
        pathname: `/recommendation/anomaly/detail/${getRecommendationIdentifier(recommendation)}`,
        state: { selectedTab, anomaly },
      });
      if (tracking) tracking.track('Dismissed Scheduling Investigation');
    }
  }

  return (
    <div className="page-content page-container margin-10 recommendation-container">
      <PageHeader
        showBack
        toPath={from || '/recommendation'}
        title={t('Scheduling Investigation')}
        state={{ selectedTab, anomaly }}
      />
      <CardLayout key="1">
        <div className="recommendation-detail-card1-container">
          {t('Would you like to schedule a drone scouting flight using FarmX’s scouting service?')}
        </div>
        <div className="recommendation-detail-card1-container">
          {t('This service will provide you with rich, high resolution images'
            + ' so you can better assess the condition of your crops.')}
        </div>
        <div className="recommendation-detail-card1-container">
          {`${t('Example Images')}:`}
          <img
            src={resImage}
            alt="scheduling-investigation"
            style={{ height: 'auto', maxWidth: '100%' }}
          />
        </div>
      </CardLayout>
      <div className="action-footer recommendation-detail-actions
      recommendation-anomaly-action-buttons
      scheduling-investigation-button"
      >
        <Button
          size="large"
          block
          type="primary"
          onClick={() => customOnClick('investigate')}
          data-testid="investigate-button"
        >
          {t('INVESTIGATE')}
        </Button>
      </div>
    </div>
  );
}
