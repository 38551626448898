/* eslint-disable quote-props */
// Disabled quote-props to maintain a clear JSON structure

import { mapPresentationModes } from 'farmx-web-ui';
import { CROP_HEALTH } from '../../../helper/articleIds';

const { title: cropHealth } = mapPresentationModes.cropHealth;

const legendConfig = {
  'Irrigation Recommendation': {
    title: 'Irrigation Recommendation',
    statement: 'The following are the colors and states based on irrigation state value',
    legendMap: {
      'Critical': 'color-state-critical',
      'Bad': 'color-state-error',
      'Poor': 'color-state-warning',
      'Good': 'color-state-ok',
      'Unknown': 'color-state-unknown',
    },
  },
  'Soil Moisture State': {
    title: 'Soil Moisture State',
    statement: `The following are the colors and states associated with each recommendation.
              These values come from Soil Moisture Probes, ETc values, or both.`,
    legendMap: {
      'Very Dry': 'color-state-critical',
      'Dry': 'color-state-error',
      'Getting Dry': 'color-state-warning',
      'Well Irrigated': 'color-state-ok',
      'Saturated': 'color-state-over',
      'Unknown': 'color-state-unknown',
    },
  },
  'Connectivity': {
    title: 'Connectivity',
    statement: 'The following are the colors and states based on last posted',
    legendMap: {
      'Offline': 'color-state-critical',
      'Degraded': 'color-state-warning',
      'Online': 'color-state-ok',
      'Unknown': 'color-state-unknown',
    },
  },
  'Water Pressure': {
    title: 'Water Pressure',
    statement: 'The following are the colors and states based on average pressure',
    legendMap: {
      'Irrigating': 'color-state-over',
      'Not irrigating': 'color-state-not-irrigating',
      'Unknown': 'color-state-unknown',
    },
  },
  'Control': {
    title: 'Control',
    statement: `The following are the colors and states
            based on sensor type and control state status`,
    legendMap: {
      'Irrigating': 'color-state-over',
      'Not irrigating': 'color-state-not-irrigating',
      'Unknown': 'color-state-unknown',
    },
  },
  'Provision': {
    title: 'Provision',
    statement: 'The following are the colors and states based on provision state status',
    legendMap: {
      'Critical': 'color-state-critical',
      'Good': 'color-state-ok',
      'Unknown': 'color-state-unknown',
    },
  },
  'Anomalies': {
    title: 'Anomalies',
    statement: 'The following are the colors and states based on anomaly type',
    legendMap: {
      'Disease': 'color-state-disease',
      'Missing': 'color-state-missing',
      'Irrigation': 'color-state-irrigation',
    },
  },
  [cropHealth]: {
    articleId: CROP_HEALTH,
  },
};

export default legendConfig;
