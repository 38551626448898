import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Comment, Typography, Button, Image,
} from 'antd';
import Moment from 'react-moment';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { selectors } from 'farmx-redux-core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


/**
 * Component to represent a single comment.
 *
 * TODO:
 *  - action buttons should only be visible when the user is author
 */
export const AnomalyComment = (props) => {
  const { t } = useTranslation();
  const {
    comment, deleteComment, editComment,
  } = props;
  const {
    username, text, image_path: image, date_created: date, user: commentUser,
  } = comment || {};
  const userInfo = useSelector(selectors.selectLoginUserInfo)?.payload || {};
  const { user_id: loggedInUser } = userInfo || {};
  const showActionButtons = useMemo(() => (
    loggedInUser === commentUser
  ), [commentUser, loggedInUser]);

  /**
   * actions: Array of action buttons:
   * - Delete
   * - Edit
   */
  const actions = showActionButtons ? [
    <div key="comment-delete" className="comment-action-buttons">
      <Button danger icon={<DeleteOutlined />} onClick={() => deleteComment(comment)} />
    </div>,
    <div key="comment-edit" className="comment-action-buttons">
      <Button icon={<EditOutlined className="edit" />} onClick={() => editComment(comment)} />
    </div>,
  ] : null;

  return (
    <div className="anomaly-comment anomaly-comment-section">
      <Comment
        actions={actions}
        author={`${username} - `}
        content={(
          <>
            {text ? (
              <div>
                <Typography.Text>
                  {text}
                </Typography.Text>
              </div>
            ) : null}
            {image ? (
              <div>
                <Image
                  alt={t('Anomaly comment')}
                  width={100}
                  src={image}
                  preview={false}
                />
              </div>
            ) : null}
          </>
        )}
        datetime={(
          <Moment fromNow>{date}</Moment>
        )}
      />
    </div>
  );
};

AnomalyComment.defaultProps = {
  editComment: () => { },
  deleteComment: () => { },
};

AnomalyComment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
    image: PropTypes.string,
    date_created: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    user: PropTypes.shape(),
  }).isRequired,
  editComment: PropTypes.func,
  deleteComment: PropTypes.func,
};
