export const colorUnknown = '#989898';
export const colorCritical = '#e8101e';
export const colorError = '#F26F30';
export const colorWarning = '#faad14';
export const colorOk = 'rgb(61, 200, 115)';
export const colorOver = '#1890ff';
export const soilDepthPalette = [
  "#ffa200",
  "#fb9818",
  "#f68d24",
  "#f0832d",
  "#ea7933",
  "#e47039",
  "#dd663d",
  "#d65d41",
  "#ce5445",
  "#c64b48",
  "#bd424b",
  "#b53a4d",
  "#ab314f",
  "#a22951",
  "#982153",
  "#8d1954",
  "#821256",
  "#770a57",
  "#6b0458",
  "#5f0059",
  "#50005a",
  "#41005b",
  "#31005c",
];

export function RgbToHsv({ r, g, b }) {
  const max = Math.max(r, g, b); const min = Math.min(r, g, b);
  const d = max - min;
  let h;
  const s = (max === 0 ? 0 : d / max);
  const v = max / 255;

  switch (max) {
    case min: h = 0; break;
    case r: h = (g - b) + d * (g < b ? 6 : 0); h /= 6 * d; break;
    case g: h = (b - r) + d * 2; h /= 6 * d; break;
    case b: h = (r - g) + d * 4; h /= 6 * d; break;
    default:
      break;
  }

  return {
    h,
    s,
    v,
  };
}

export function HsvToRgb({ h, s, v }) {
  let r; let g; let b;
  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    default:
      r = v;
      g = p;
      b = q;
      break;
  }
  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
}
