import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hooks, selectors } from 'farmx-redux-core';
import { PageHeader } from '../components/PageHeader';
import { WeatherDetail } from './WeatherDetail';
import { WeatherSummary } from './ListCards/WeatherSummary';
import { RanchBlockSelect } from './RanchBlockSelect';
import { ChillHoursAndPortions } from './ChillHoursAndPortions';
import { CumulativePrecipitations } from './CumulativePrecipitations';
import { GrowingDegree } from './GrowingDegree';
import { isMobile } from '../../../utils/detectDevice';

export function WeatherList() {
  const { t } = useTranslation();
  const [selectedObj, setSelectedObj] = useState({});
  const { blockIds } = hooks.useRanchBlockSelection();
  const { weatherData, list } = useSelector((store) => (
    selectors.selectWeatherDataAndList(store, blockIds)
  )) || {};
  const { loading } = weatherData;
  const units = weatherData.units || {
    airTemperature: 'degrees_fahrenheit',
    precipitation: 'inches',
  };
  const showDetails = Boolean(!loading && weatherData.weatherForecast);

  return (
    <div className="div-default padding-10 page-content device-summary-list">
      <div className="desktop-container">
        <PageHeader
          showBack
          title={t('Weather')}
          toPath="/list"
        />
        {isMobile && (
        <RanchBlockSelect
          selectedObj={selectedObj}
          setSelectedObj={setSelectedObj}
        />
        )}
        <div className="list-container">
          <WeatherSummary
            list={list}
          />
        </div>
        {showDetails && (
          <WeatherDetail
            weatherForecast={weatherData.weatherForecast || []}
            weatherHistory={weatherData.weatherHistory || []}
            fromUnits={units}
          />
        )}
        <div className="chill-hours-and-portions margin-bottom-less">
          <CumulativePrecipitations fromUnits={units} />
          <GrowingDegree />
        </div>
        <ChillHoursAndPortions />
      </div>
    </div>
  );
}
