import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Form, Typography, Input, Select,
} from 'antd';

const anomalyTypeOptions = [{ value: 'unidentified', label: 'Unidentified' },
  { value: 'disease', label: 'Disease' }, { value: 'missing', label: 'Missing tree' },
  { value: 'under_irrigated', label: 'Under-Irrigated' },
  { value: 'over_irrigated', label: 'Over-irrigated' },
  { value: 'standing_water', label: 'Standing Water' },
  { value: 'crop_stress', label: 'Crop Stress' },
  { value: 'canopy_size', label: 'Canopy Size' }];

const anomalySeverityOptions = [{ value: 'low', label: 'Low' },
  { value: 'moderate', label: 'Moderate' },
  { value: 'major', label: 'Major' }, { value: 'critical', label: 'Critical' }];

export default function AnomalyCreateEditFormElements(props) {
  const { type, form, formValues } = props;
  const { t } = useTranslation();

  function renderDropDownElem(options, defaultValue, onChange, testId) {
    return (
      <Select
        data-test-id={testId}
        style={{ marginLeft: '10px', fontSize: '16px' }}
        defaultValue={defaultValue}
        onChange={onChange}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        popupClassName="map-anomaly-select-option"
      >
        {options.map((d) => (
          <Select.Option value={d.value}>
            {t(d.label)}
          </Select.Option>
        ))}
      </Select>
    );
  }

  return (
    <div>
      {type !== 'edit' ? (
        <>
          <Form.Item name="description" className="form-margin">
            <div className="margin-bottom-10 flex-row justify-content-space-between text-wrap">
              <Typography.Text
                style={{ fontWeight: 'bold', fontSize: '16px' }}
                className="flex-center"
              >
                {`${t('Description')}`}
              </Typography.Text>
              <Input.TextArea
                placeholder="Description"
                data-test-id="anomaly-description"
                defaultValue={formValues.current.description}
                onChange={(e) => form.setFieldsValue({ description: e.target.value })}
                rows={3}
                className="margin-left-10"
              />
            </div>
          </Form.Item>
          <Form.Item name="type">
            <div className="margin-bottom-10 flex-row justify-content-space-between text-wrap">
              <Typography.Text
                style={{ fontWeight: 'bold', fontSize: '16px' }}
                className="flex-center"
              >
                {`${t('Type')}`}
              </Typography.Text>
              {renderDropDownElem(anomalyTypeOptions,
                formValues.current.type, (e) => form.setFieldsValue({ type: e }), 'anomaly-type')}
            </div>
          </Form.Item>
        </>
      ) : (
        <Form.Item name="anomaly_severity">
          <div className="margin-bottom-10 flex-row justify-content-space-between">
            <Typography.Text
              style={{ fontWeight: 'bold', fontSize: '16px' }}
              className="flex-center edit-anomaly-form"
            >
              {`${t('Anomaly Severity')}`}
            </Typography.Text>
            {renderDropDownElem(anomalySeverityOptions,
              formValues.current.anomaly_severity,
              (e) => form.setFieldsValue({ anomaly_severity: e }),
              'anomaly-severity')}
          </div>
        </Form.Item>
      )}
    </div>
  );
}

AnomalyCreateEditFormElements.propTypes = {
  type: PropTypes.string,
  formValues: PropTypes.shape(),
  form: PropTypes.func,
};

AnomalyCreateEditFormElements.defaultProps = {
  type: null,
  formValues: {},
  form: null,
};
