import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { recommendationApi } from 'farmx-api';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  notification,
  Typography,
  DatePicker as DatePickerDate,
} from 'antd';
import {
  DatePicker as DatePickerTime,
} from 'antd-mobile-v2';
import enUs from 'antd-mobile-v2/lib/date-picker/locale/en_US';

import moment from 'moment';
import { actions, selectors, hooks } from 'farmx-redux-core';
import { Slider as CircularSlider } from 'farmx-web-ui';
import { PageHeader } from '../components/PageHeader';
import { IrrigationEquation } from '../components/IrrigationEquation';
import { EtcDeficitPercentCard } from './EtcDeficitPercentCard';

import { CardLayout } from '../components/CardLayout';
import { getActionText } from './recommendation';
import { useTracking } from '../../../helper/mixpanel';
import { getBlockArea } from '../../../helper/block';

import './recommendation-action.css';
import '../schedule/Schedule.less';
import {
  getGallonsValue, showWarningCard,
  getIrrigationStateCutoffs, getIrrigationStateColors, parseDurationStr,
  useScheduledValveForIrrigationRatio,
} from '../../../helper/scheduleHelper';
import WarningCard from '../components/WarningCard';
import { recommendationAnomalyFilter } from '../../../helper/common';

const {
  selectRecommendationForBlock,
  selectBlockById,
} = selectors;

const {
  loadRecommendationForBlock,
  updateRecommendation,
} = actions;

const initializeStartDate = () => moment().startOf('day').add(moment().hour() + 1, 'hours');
const disabledDate = (current) => current.clone().startOf('day').clone().toDate()
  .getTime() < moment().startOf('day').toDate().getTime();
const dateFormat = 'dddd, D MMMM YYYY';
const timeFormat = 'h:mma';

const SCHEDULED_DAYS_PER_WEEK = 5;

export function RecommendationAction() {
  const { blockId } = useParams();
  const { t } = useTranslation();
  const tracking = useTracking();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const recommendation = useSelector((state) => recommendationAnomalyFilter(
    selectRecommendationForBlock(state, blockId),
  ));
  const block = useSelector((state) => selectBlockById(state, blockId));

  const getUserUnits = hooks.useUnits();

  const defaultDurationHours = 6;
  const {
    irrigationDuration: irrigationDurationStr,
    scheduledDuration: scheduledDurationStr,
    wiltingPointDuration: wiltingPointDurationStr,
    refillPointDuration: refillPointDurationStr,
    fieldCapacityDuration: fieldCapacityDurationStr,
  } = recommendation || {};

  const [duration, setDuration] = useState(defaultDurationHours * 60);
  const [startDate, setStartDate] = useState(initializeStartDate());
  const backPath = (location.state && location.state.from) || '/recommendation';

  const scheduledDuration = parseDurationStr(scheduledDurationStr);
  const irrigationDuration = parseDurationStr(irrigationDurationStr);
  const recommendedTotalDuration = scheduledDuration && irrigationDuration ? (
    scheduledDuration.clone().add(irrigationDuration)
  ) : null;
  const wiltingPointDuration = parseDurationStr(wiltingPointDurationStr);
  const refillPointDuration = parseDurationStr(refillPointDurationStr);
  const fieldCapacityDuration = parseDurationStr(fieldCapacityDurationStr);

  const maxDurationHours = fieldCapacityDuration && fieldCapacityDuration > 0
    ? fieldCapacityDuration.asHours() * 2 : 72;

  function getDurationHeader() {
    if (recommendedTotalDuration) {
      if (irrigationDuration.asMinutes() >= 0) {
        return t('Adjust Irrigation Duration');
      }
      if (scheduledDuration.asMinutes() > 0) {
        return t('Reduce Irrigation by Duration');
      }
    }
    return t('Adjust Irrigation Duration');
  }

  function hideDurationSlider() {
    return (
      irrigationDuration
      && irrigationDuration.asMinutes() <= 0
      && scheduledDuration.asMinutes() <= 0
    );
  }

  function onChangeStartTime(newTime) {
    return setStartDate(moment(newTime));
  }

  useEffect(() => {
    if (blockId) tracking.track('Loaded Recommendation Detail Page', { blockId });
  }, [tracking, blockId]);

  useEffect(() => {
    if (blockId) {
      dispatch(loadRecommendationForBlock(blockId));
    }
  }, [dispatch, blockId]);

  function onChangeDurationSlider(newDuration) {
    setDuration(newDuration);
  }

  const irrigationDurationMinutes = irrigationDuration && irrigationDuration.asMinutes();
  useEffect(() => {
    if (irrigationDurationMinutes !== null) {
      setDuration(Math.abs(irrigationDurationMinutes));
    }
  }, [irrigationDurationMinutes, setDuration]);

  const endDate = startDate.clone().add(duration, 'minutes');
  const totalDuration = moment.duration(endDate.diff(startDate));
  const totalHours = totalDuration.asHours();

  const applicationRate = (block && block.application_rate) || 0;
  const gallonsValue = getGallonsValue(applicationRate || 0, totalHours);
  const waterDetails = getUserUnits(gallonsValue, 'gallons', 'volume');
  const areaSquareMeters = getBlockArea(block);
  const { value: irrigationCubicMeters } = getUserUnits(gallonsValue, 'cubic_meters', 'volume');
  const irrigationMeters = areaSquareMeters ? irrigationCubicMeters / areaSquareMeters : 0;
  const irrigationMillimeters = recommendation.irrigationModel === 'etc_deficit'
    ? irrigationMeters : irrigationMeters * 1000;
  const waterLimit = ((block && block.application_rate) || 0)
    * (totalHours / SCHEDULED_DAYS_PER_WEEK);
  const showWarning = showWarningCard(waterLimit, block);

  const cutOffValues = useScheduledValveForIrrigationRatio(recommendation.etcHistoric,
    recommendation.etcForecast, recommendation.irrigationApplied,
    applicationRate, maxDurationHours, areaSquareMeters);

  const irrigationStateCutoffs = getIrrigationStateCutoffs(irrigationDuration, wiltingPointDuration,
    refillPointDuration, fieldCapacityDuration, recommendation.irrigationModel,
    maxDurationHours, cutOffValues);

  const irrigationStateColors = getIrrigationStateColors(irrigationDuration, wiltingPointDuration,
    refillPointDuration, fieldCapacityDuration, recommendation.irrigationModel);

  // TODO: loading
  if (!recommendation) {
    return (
      <div>Recommendation not found</div>
    );
  }

  return (
    <div className="page-content page-container margin-10 recommendation-container">
      <PageHeader showBack toPath={backPath} title={`${getActionText(recommendation.action)}: ${recommendation.blockName}`} />
      <IrrigationEquation
        deficit={Number(recommendation.deficit)}
        etcForecast={Number(recommendation.etcForecast)}
        irrigationDuration={recommendation.irrigationDuration}
      />
      {!hideDurationSlider() ? (
        <CardLayout>
          <div className="margin-bottom-10">
            <Typography.Text style={{ fontWeight: 'bold', fontSize: '16px' }}>
              {`${getDurationHeader()}: `}
            </Typography.Text>
          </div>
          <div className="flex-column date-picker-container">
            <div className="flex-col">
              <div className="duration-slider-container">
                <CircularSlider
                  className="circular-slider"
                  value={duration}
                  onChange={onChangeDurationSlider}
                  sliderWidth={200}
                  trackSize={16}
                  hourMax={maxDurationHours}
                  hourStep={1}
                  minMax={60}
                  minStep={1}
                  knobSize={40}
                  colors={irrigationStateColors}
                  cutoffValues={irrigationStateCutoffs}
                  waterUnit={waterDetails.label}
                  waterValue={waterDetails.value}
                />
              </div>
            </div>
            <div className="flex-row justify-content-space-between date-time-picker">
              <DatePickerDate
                className="flex-grow-1"
                format={dateFormat}
                size="large"
                allowClear={false}
                inputReadOnly
                value={startDate}
                suffixIcon={null}
                disabledDate={disabledDate}
                onChange={setStartDate}
              />
              <DatePickerTime
                mode="time"
                minuteStep={1}
                use12Hours
                value={startDate.toDate()}
                onChange={onChangeStartTime}
                locale={enUs}
              >
                <Button size="large" className="time-picker-btn">
                  {`${startDate.format(timeFormat)}`}
                </Button>
              </DatePickerTime>
            </div>
          </div>
        </CardLayout>
      ) : null}
      {recommendation.irrigationModel === 'etc_deficit' && (
        <EtcDeficitPercentCard
          irrigationApplied={recommendation.irrigationApplied}
          etcHistoric={recommendation.etcHistoric}
          etcForecast={recommendation.etcForecast}
          irrigationScheduled={waterDetails.value}
          units={recommendation.units}
          areaSquareMeters={areaSquareMeters}
        />
      )}
      {showWarning && <WarningCard waterValue={block.maximum_daily_water} />}
      <div className="recommendation-action-card-btn-container recommendation-buttons">
        <Button
          type="primary"
          size="large"
          block
          onClick={() => {
            dispatch(updateRecommendation({
              id: recommendation.id,
              data: {
                isFrozen: true,
                freezeType: 'action',
                dateFrozen: moment().toISOString(),
              },
            }));
            let finalDuration = moment.duration({ minutes: duration });
            finalDuration = (irrigationDuration < 0) ? -finalDuration : finalDuration;
            finalDuration = scheduledDuration ? finalDuration.add(scheduledDuration) : scheduledDuration;
            recommendationApi.createRecommendationSchedule({
              blockId: recommendation.block,
              dateStart: recommendation.dateIrrigationStart,
              dateEnd: moment(recommendation.dateIrrigationStart).add(
                moment.duration(recommendation.duration),
              ).toISOString(),
              dateIrrigationStart: startDate.toISOString(),
              duration: finalDuration.format('d HH:mm:ss'),
            }).then(() => {
              notification.success({
                message: t('Updated irrigation'),
                description: t('Click to view schedule'),
                onClick: () => {
                  history.push('/schedule');
                },
              });
              history.push('/recommendation');
            }).catch(() => {
              notification.error({ message: t('Failed to update irrigation') });
            });
            tracking.track('Clicked confirm recommendation action', recommendation);
          }}
        >
          {`${t(getActionText(recommendation.action))}`}
        </Button>
      </div>
    </div>
  );
}
