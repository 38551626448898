import React, {
  useEffect, useState, useRef, useMemo, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ChartComponent } from 'farmx-web-ui';
import Moment from 'moment';
import { sensorApi, helpers } from 'farmx-api';
import { actions, selectors } from 'farmx-redux-core';
import {
  colorCritical, colorWarning, colorOver, colorOk, soilDepthPalette,
} from '../../utils/colors';
import Resizer from './Resizer';

const { fieldToHeader } = helpers;
const { loadSensorData } = sensorApi;
const { loadSensorDetail } = actions;

/**
 * This method uses soilDepthPalette to create
 * mapping based on the sensor status.
 * Example color mapping looks like:
 * {'soil_moisture_8': '#00429d'}
 *
 * It returns a function to get a color. This function accepts a string
 * and returns related color.
 */
const getColors = (sensorStatus) => {
  const { depth1, depth2, depthCount } = sensorStatus || {};
  const colorMapping = {};
  if (depth1 && depth2 && depthCount) {
    const depthSize = depth2 - depth1;
    for (let depthNum = 1; depthNum <= depthCount; depthNum += 1) {
      const currentDepth = depthNum * depthSize;
      const key = `soil_moisture_${currentDepth}`;
      const depthRounded = 2 * Math.ceil(currentDepth / 2);
      const paletteIndex = (depthRounded - 4) / 2;
      colorMapping[key] = soilDepthPalette[paletteIndex];
    }
  }
  return (key) => colorMapping[key] || colorOk;
};

/**
 * Dynamically generates the variables required to get api response.
 *
 * Given if the sensorStatus has following data:
 * { depth1: 8, depth2: 16, ... depthCount: 6 }
 *
 * It will return
 * ['soil_moisture_8', 'soil_moisture_16', ... 'soil_moisture_48']
 */
const getDepthVariables = (sensorStatus) => {
  const { depth1, depthCount } = sensorStatus || {};
  if (depth1 && depthCount) {
    const vars = [];
    for (let i = 1; i <= depthCount; i += 1) {
      const depth = sensorStatus[`depth${i}`];
      vars.push(`soil_moisture_${depth}`);
    }
    return vars;
  }
  return [];
};

/**
 * Compute a common min and max value for the graph.
 * These common values can be then used in yAxis obj as opposed to
 * individual min and max values.
 * It eliminates the chance of overlapping of yAxis labels.
 */
const computeMinAndMax = (data, sensorStatus) => {
  let newMin = Infinity;
  let newMax = -Infinity;

  try {
    const depthSize = sensorStatus.depth2 - sensorStatus.depth1;
    Object.keys(data).forEach((dataKey) => {
      const dataValue = data[dataKey][0];
      const depthIndex = (dataKey.split('soil_moisture_')[1]) / depthSize;
      const fieldCapacity = sensorStatus[`fieldCapacity${depthIndex}`];
      const refillPoint = sensorStatus[`refillPoint${depthIndex}`];
      const wiltingPoint = sensorStatus[`wiltingPoint${depthIndex}`];
      const currentMin = Math.min(
        dataValue.moisture_zone_lower || parseFloat(dataValue.min), parseFloat(dataValue.min),
      );
      const currentMax = Math.max(
        dataValue.moisture_zone_upper || parseFloat(dataValue.max), parseFloat(dataValue.max),
      );
      newMin = Math.min(newMin, currentMin, fieldCapacity, refillPoint, wiltingPoint);
      newMax = Math.max(newMax, currentMax, fieldCapacity, refillPoint, wiltingPoint);
      if (isNaN(newMin)) {
        if (dataKey === 'water_pressure') {
          newMin = dataValue.min;
        } else {
          newMin = Math.min(dataValue.moisture_zone_lower, dataValue.min);
        }
      }
      if (isNaN(newMax)) {
        if (dataKey === 'water_pressure') {
          newMax = dataValue.min;
        } else {
          newMax = Math.max(dataValue.moisture_zone_upper, dataValue.max);
        }
      }
    });
  } catch {
    return { min: undefined, max: undefined };
  }
  return { min: newMin, max: newMax };
};

const updateSoilCutoff = (responseData, sensorStatus) => {
  const data = { ...responseData };
  try {
    const depthSize = sensorStatus.depth2 - sensorStatus.depth1;
    Object.keys(data).forEach((dataKey) => {
      const depthIndex = dataKey.split('soil_moisture_')[1];
      const depthNum = depthIndex / depthSize;
      data[dataKey][0].fieldCapacity = sensorStatus[`fieldCapacity${depthNum}`];
      data[dataKey][0].refillPoint = sensorStatus[`refillPoint${depthNum}`];
      data[dataKey][0].wiltingPoint = sensorStatus[`wiltingPoint${depthNum}`];
      data[dataKey][0].isRootzone = sensorStatus[`isRootzone${depthNum}`];
    });
  } catch {
    return data;
  }
  return data;
};

export default function SensorDataChart(props) {
  const {
    sensor: sensorProps,
    variables,
    startDate,
    endDate,
    mergeAxes,
    compact,
    soilChartOption,
    updateChartLoadingMap,
    updateChartLegend,
    showIndividualDepthGraph,
    updateYAxisMinAndMax,
    yAxisMinAndMax,
    makeYAxisSame,
  } = props;

  const { type, identifier, id: sensorId } = sensorProps;
  const dispatch = useDispatch();
  const sensorStatus = useSelector(
    (state) => selectors.selectSensorStatus(state, type, identifier),
  );
  const getColor = useMemo(() => getColors(sensorStatus), [sensorStatus]);
  const chartRef = useRef();
  const individualChartRef = useRef([]);
  const [localChartData, setLocalChartData] = useState(null);
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [height, setHeight] = useState(null);
  const [individualGraphHeight, setIndividualGraphHeight] = useState({});
  const [loading, setLoading] = useState({});
  const updateLoading = (key, value) => {
    setLoading((existingState) => ({
      ...existingState,
      [key]: value,
    }));
  };

  const validInput = !!(
    sensorId
    && type
    && variables
    && variables.length
    && startDate
    && endDate
  );

  // load initial data
  useEffect(() => {
    if (type && identifier) {
      dispatch(loadSensorDetail({ type, identifier }));
    }
  }, [dispatch, type, identifier]);

  // update data when config changes
  // Disabled eslint rule to avoid multiple API calls for same details
  useEffect(() => {
    function refreshData() {
      const chartObj = chartRef.current?.highchartsComponent?.current?.chart;
      /**
       * Update localChartData with dummy data while the chart api is loading.
       * This dummy data ensures the correct dates are displayed on the x-axis
       * when fetching the data.
       */
      if (localChartData?.[sensorId]?.soil_moisture_rootzone_vwc) {
        if (startDate && endDate) {
          const difference = endDate.diff(startDate, 'days');
          /**
           * Create data in the format:
           * [
           *  [1672511400000, 0],
           *  [1675189800000, 0],
           *  ...
           * ]
           */
          const data = new Array(difference + 1).fill(0).map((item, index) => ([
            new Moment(startDate).add('days', index).valueOf(), 0,
          ]));
          setLocalChartData({
            [sensorId]: {
              soil_moisture_rootzone_vwc: [{
                ...localChartData?.[sensorId]?.soil_moisture_rootzone_vwc[0],
                data,
              }],
            },
          });
        }
      }

      if (!validInput) {
        if (chartObj) {
          chartObj.hideLoading();
          updateChartLoadingMap({ [identifier]: false });
          updateLoading(sensorId, false);
        }
        setLocalChartData(null);
        return;
      }
      if (chartObj) {
        chartObj.showLoading();
        updateChartLoadingMap({ [identifier]: true });
        updateLoading(sensorId, true);
      }
      const chartVariables = (soilChartOption === 'soilDepth' && sensorProps.type !== 'water_pressure')
        ? getDepthVariables(sensorStatus)
        : variables;
      loadSensorData(type, sensorId, chartVariables, startDate, endDate).then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          const computedMinAndMax = computeMinAndMax(data, sensorStatus);
          setMin(computedMinAndMax.min);
          setMax(computedMinAndMax.max);
          updateYAxisMinAndMax(computedMinAndMax.min, computedMinAndMax.max, sensorProps.type);
          setLocalChartData({ [sensorId]: response.data });
          if (soilChartOption === 'soilDepth' && sensorProps.type !== 'water_pressure') {
            const responseDataWithSoilCutoff = updateSoilCutoff(response.data, sensorStatus);
            setLocalChartData({ [sensorId]: responseDataWithSoilCutoff });
          } else {
            setLocalChartData({ [sensorId]: response.data });
          }
        }
        if (chartObj) {
          chartObj.hideLoading();
          updateChartLoadingMap({ [identifier]: false });
          updateLoading(sensorId, false);
        }
      });
    }
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validInput, startDate, endDate, soilChartOption]);

  function preparePlotLines(linesConfig) {
    const defaultStyle = {
      zIndex: 100,
      dashStyle: 'dash',
      width: 2,
    };
    const plotLinesArr = linesConfig.map((configObj) => ({ ...configObj, ...defaultStyle }));
    const filteredConfig = linesConfig.filter((d) => d?.type !== 'middle');
    const plotBandConfigArr = filteredConfig.map((d) => ({
      from: d.from,
      to: d.to,
      color: d.color,
    }));
    return { plotLines: plotLinesArr, plotBands: plotBandConfigArr };
  }

  const computeMinForLocalBuildConfig = (sensorPropsType, chartData) => {
    if (makeYAxisSame) {
      return sensorPropsType === 'water_pressure'
        ? yAxisMinAndMax.waterPressure.min
        : yAxisMinAndMax.soilMoisture.min;
    }
    return (min || Math.min(
      chartData.moisture_zone_lower || parseFloat(chartData.min), parseFloat(chartData.min),
    ));
  };

  const computeMaxForLocalBuildConfig = (sensorPropsType, chartData) => {
    if (makeYAxisSame) {
      return sensorPropsType === 'water_pressure'
        ? yAxisMinAndMax.waterPressure.max
        : yAxisMinAndMax.soilMoisture.max;
    }

    return (max || Math.max(
      chartData.moisture_zone_upper || parseFloat(chartData.max), parseFloat(chartData.max),
    ));
  };

  function buildConfig(seriesData) {
    if (!seriesData) return {};

    const yAxisByUnit = {};
    const allSeries = Object.entries(seriesData).map(([key, value]) => {
      if (!value.length) return undefined;
      const chartData = value[0];
      if (!chartData.data.length) return undefined;

      let { data: variableData } = chartData;

      // detect values as strings and convert
      if (typeof variableData[0][1] === 'string') {
        variableData = variableData.map((datum) => [datum[0], parseFloat(datum[1])]);
      }

      let multiplier = 1;
      // fix units of %
      if (chartData.units === '%') {
        variableData = variableData.map((datum) => [datum[0], 100 * datum[1]]);
        multiplier = 100;
      }

      const decimals = 1;

      const chartUnits = chartData.units || '';
      const axisKey = mergeAxes ? chartUnits : chartUnits + key;
      const regex = /&deg;/g;
      const units = chartUnits.replace(regex, 'º');
      const unitsStr = ` (${units})`;
      const variableName = fieldToHeader(key);
      const legendTitle = variableName + unitsStr;

      let plotLines = [];
      let plotBands = [];
      const soilData = seriesData.soil_moisture_rootzone_vwc;
      if (chartData && soilData) {
        const linesConfig = [{
          color: colorOver,
          value: chartData.moisture_zone_upper * multiplier,
          type: 'upper',
          from: chartData.moisture_zone_upper * multiplier,
          to: (chartData.moisture_zone_upper * multiplier || 0) + 1 * multiplier,
          className: 'upper-band-line',
        },
        { color: colorWarning, value: chartData.moisture_zone_middle * multiplier, type: 'middle' },
        {
          color: colorCritical,
          value: chartData.moisture_zone_lower * multiplier,
          type: 'lower',
          from: 0,
          to: chartData.moisture_zone_lower * multiplier,
          className: 'lower-band-line',
        }];
        const plotLinesConfig = preparePlotLines(linesConfig);
        plotLines = plotLinesConfig.plotLines;
        plotBands = plotLinesConfig.plotBands;
      }
      let yAxis = yAxisByUnit[axisKey];
      if (!yAxis) {
        yAxis = {
          title: null,
          id: axisKey,
          opposite: false,
          min: computeMinForLocalBuildConfig(sensorProps.type, chartData) * multiplier,
          max: computeMaxForLocalBuildConfig(sensorProps.type, chartData) * multiplier,
          plotLines,
          plotBands,
        };
        yAxisByUnit[axisKey] = yAxis;
      } else {
        yAxis.title = null;
        yAxis.min = Math.min(yAxis.min, chartData.min);
        yAxis.max = Math.max(yAxis.max, chartData.max);
        yAxis.plotLines = plotLines;
      }

      if (compact) {
        yAxis.title = null;
        yAxis.labels = {
          x: 0,
          y: -2,
          align: 'left',
        };
      }

      const seriesOptions = {
        name: legendTitle,
        sensorName: chartData.sensor_name,
        isRootzone: chartData.isRootzone,
        type: 'line',
        data: variableData,
        yAxis: axisKey,
        tooltip: {
          valueDecimals: decimals,
        },
      };
      if (type?.includes('soil')) {
        const seriesOptionsColor = getColor(key);
        updateChartLegend(
          identifier,
          key,
          {
            color: getColor(key),
            isRootzone: chartData.isRootzone,
          },
        );
        seriesOptions.color = seriesOptionsColor;

        if (soilChartOption === 'soilDepth') {
          seriesOptions.additionalPlotLines = [
            {
              value: chartData.wiltingPoint * multiplier,
              color: colorCritical,
              width: 2,
              id: 'moisture_zone_lower',
              dashStyle: 'dash',
            },
            {
              value: chartData.refillPoint * multiplier,
              color: colorWarning,
              width: 2,
              id: 'moisture_zone_middle',
              dashStyle: 'dash',
            },
            {
              value: chartData.fieldCapacity * multiplier,
              color: colorOver,
              width: 2,
              id: 'moisture_zone_upper',
              dashStyle: 'dash',
            },
          ];
          /**
           * Display dashed cutoff lines when hover over any series.
           */
          seriesOptions.point = {
            events: {
              mouseOver() {
                try {
                  const yAxisRef = chartRef.current?.highchartsComponent?.current?.chart?.yAxis[0];
                  if (yAxisRef) {
                    yAxisRef.addPlotLine({
                      value: chartData.wiltingPoint * multiplier,
                      color: colorCritical,
                      width: 2,
                      id: 'moisture_zone_lower',
                      dashStyle: 'dash',
                    });
                    yAxisRef.addPlotLine({
                      value: chartData.refillPoint * multiplier,
                      color: colorWarning,
                      width: 2,
                      id: 'moisture_zone_middle',
                      dashStyle: 'dash',
                    });
                    yAxisRef.addPlotLine({
                      value: chartData.fieldCapacity * multiplier,
                      color: colorOver,
                      width: 2,
                      id: 'moisture_zone_upper',
                      dashStyle: 'dash',
                    });
                  }
                } catch (error) {
                  console.log('Error in hover', error);
                }
              },
              mouseOut() {
                try {
                  const yAxisRef = chartRef.current?.highchartsComponent?.current?.chart?.yAxis[0];
                  if (yAxisRef) {
                    yAxisRef.removePlotLine('moisture_zone_lower');
                    yAxisRef.removePlotLine('moisture_zone_middle');
                    yAxisRef.removePlotLine('moisture_zone_upper');
                  }
                } catch (error) {
                  console.log('mouseOut error', error);
                }
              },
            },
          };
        }
      }
      return seriesOptions;
    });
    return {
      yAxis: Object.values(yAxisByUnit),
      series: allSeries.filter(Boolean),
    };
  }

  const config = buildConfig(localChartData?.[sensorId]);

  const xAxisLabels = compact
    ? {
      y: 10,
      x: 1,
      align: 'left',
    } : {};

  const spacing = compact
    ? [1, 1, 1, 1] : [10, 10, 15, 10];

  useEffect(() => {
    try {
      chartRef.current.highchartsComponent.current.chart.zoom();
    } catch {
      console.log('Error in resetting zoom');
    }
  }, [startDate, endDate]);

  const renderIsRootzonePill = (isRootzone) => (isRootzone ? (
    <span
      className="legend-pill"
      style={{ backgroundColor: colorOk }}
    >
      Rootzone
    </span>
  ) : (
    <span
      className="legend-pill"
      style={{ backgroundColor: 'grey' }}
    >
      Not Rootzone
    </span>
  ));

  const renderIndividualChartTitle = (seriesItem) => {
    const {
      sensorName, color, yAxis, name, isRootzone,
    } = seriesItem || {};
    try {
      return (
        <div className="individual-chart-header">
          <span className="sensor-name">{`${sensorName}`}</span>
          <span>Soil Moisture VWC</span>
          <span
            className="legend-pill"
            style={{ backgroundColor: color }}
          >
            {`${yAxis.split('_')[2]} in`}
          </span>
          {renderIsRootzonePill(isRootzone)}
        </div>
      );
    } catch {
      return `${sensorName} ${name}`;
    }
  };
  const onResize = useCallback((event, chartHeight) => {
    setHeight(chartHeight);
  }, []);
  const onResizeIndividual = useCallback((chartHeight, key) => {
    setIndividualGraphHeight((existingIndividualGraphHeight) => ({
      ...existingIndividualGraphHeight,
      [key]: chartHeight,
    }));
  }, []);
  const displayIndividualGraph = showIndividualDepthGraph && sensorProps.type !== 'water_pressure';

  /**
   * If the data is loading, we set chart to show loading.
   */
  useEffect(() => {
    const chartObj = chartRef.current?.highchartsComponent?.current?.chart;
    if (loading[sensorId]) {
      chartObj.showLoading();
    } else {
      chartObj.hideLoading();
    }
  }, [chartRef, loading, sensorId]);

  return (
    <div className="chart-component-wrapper">
      {!displayIndividualGraph && (
        <Resizer onResize={onResize}>
          <div className="chart-component-main" style={{ height }}>
            <ChartComponent
              loading={loading[sensorId]}
              ref={chartRef}
              options={{
                legend: {
                  enabled: false,
                },
                chart: {
                  zoomType: 'xy',
                  spacing,
                },
                xAxis: {
                  type: 'datetime',
                  ordinal: false,
                  gridLineWidth: 1,
                  labels: xAxisLabels,
                  alternateGridColor: 'rgba(200,200,200,0.1)',
                  min: startDate?.valueOf(),
                  max: endDate?.valueOf(),
                },
                plotOptions: type?.includes('soil') ? {
                  series: {
                    color: colorOk,
                  },
                } : {},
                ...config,
              }}
            />
          </div>
        </Resizer>
      )}
      {displayIndividualGraph && (
        <div className="nested-chart-component-wrapper">
          {config && config.series && config.series.map((seriesItem, index) => (
            <div
              className="nested-individual-chart-main"
              key={seriesItem.yAxis}
            >
              <div className="nested-individual-chart-header">
                {renderIndividualChartTitle(seriesItem)}
              </div>
              <Resizer
                onResize={(event, chartHeight) => onResizeIndividual(chartHeight, seriesItem.yAxis)}
              >
                <div className="nested-individual-chart" style={{ height: individualGraphHeight[seriesItem.yAxis] || null }}>
                  <ChartComponent
                    ref={(el) => {
                      individualChartRef.current[index] = el;
                    }}
                    options={{
                      legend: {
                        enabled: false,
                      },
                      chart: {
                        zoomType: 'xy',
                        spacing,
                      },
                      xAxis: {
                        type: 'datetime',
                        ordinal: false,
                        gridLineWidth: 1,
                        labels: xAxisLabels,
                        alternateGridColor: 'rgba(200,200,200,0.1)',
                        min: startDate?.valueOf(),
                        max: endDate?.valueOf(),
                      },
                      plotOptions: type?.includes('soil') ? {
                        series: {
                          color: colorOk,
                        },
                      } : {},
                      series: [seriesItem],
                      yAxis: {
                        ...config.yAxis[index],
                        plotLines: seriesItem.additionalPlotLines,
                      },
                    }}
                  />
                </div>
              </Resizer>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

SensorDataChart.propTypes = {
  compact: PropTypes.bool,
  variables: PropTypes.arrayOf(PropTypes.string),
  startDate: PropTypes.instanceOf(Moment),
  endDate: PropTypes.instanceOf(Moment),
  sensor: PropTypes.shape({
    type: PropTypes.string,
    identifier: PropTypes.string,
    id: PropTypes.number,
  }),
  mergeAxes: PropTypes.bool,
  soilChartOption: PropTypes.string,
  updateChartLoadingMap: PropTypes.func,
  updateChartLegend: PropTypes.func,
  showIndividualDepthGraph: PropTypes.bool,
  updateYAxisMinAndMax: PropTypes.func,
  makeYAxisSame: PropTypes.bool,
  yAxisMinAndMax: PropTypes.shape({
    waterPressure: { min: PropTypes.number, max: PropTypes.number },
    soilMoisture: { min: PropTypes.number, max: PropTypes.number },
  }),
};

SensorDataChart.defaultProps = {
  compact: true,
  variables: [],
  startDate: null,
  endDate: null,
  sensor: null,
  mergeAxes: false,
  soilChartOption: '',
  updateChartLoadingMap: () => { },
  updateChartLegend: () => { },
  showIndividualDepthGraph: false,
  updateYAxisMinAndMax: () => { },
  yAxisMinAndMax: {
    waterPressure: { min: Infinity, max: -Infinity },
    soilMoisture: { min: Infinity, max: -Infinity },
  },
  makeYAxisSame: false,
};
