import React, {
  useReducer, useRef, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions, hooks } from 'farmx-redux-core';
import {
  useLoadMapData,
  useRecenter,
  useReDraw,
  prepData,
  useGetDataToRenderMap,
} from './mapHooks';
import { initReducerMapMobile, reducerMapMobile } from './reducerMapMobile';
import './map.css';
import { presModeTitles } from './MapSettings';
import { reDrawSatelliteImagery } from './reDraw';
import LegendModal from './LegendModal';
import legendConfig from './legendConfig';
import { SatelliteImageryWrapper } from './SatelliteImageryWrapper';
import { getDataForReDraw } from '../../../helper/mapHelper';
import { Map3D } from '../../map/components/threed/Map3D';
import { isMobile } from '../../../utils/detectDevice';
import ShowDetails from './ShowDetails';
import MapTabsControl from './MapTabsControl';
import MapSettingModal from './MapSettingsModal';
import AnomalyCreateEditModal from './AnomalyCreateEditModal';
import MapControlBox from './MapControlBox';
import MapWrapper from './MapWrapper';

const {
  setMapPresentationModes,
} = actions;

const {
  selectLoginUserInfo,
} = selectors;

const { useRanchBlockSelection } = hooks;

const REFRESH_INTERVAL = 1000 * 60 * 15;

export function MapView({ ranchId, blockId, selectedObjFromState }) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState({});
  const [isAnomalyControlEnabled, setIsAnomalyControlEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sheetRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [mapSettingModal, setMapSettingModal] = useState(false);
  const [layerOption, setLayerOption] = useState(false);
  const [createAnomaly, setCreateAnomaly] = useState({ visible: false });
  const formValues = useRef({ type: 'missing', description: null, anomaly_severity: 'moderate' });
  const refreshStatusInterval = useRef(null);
  const ranchIds = [(selectedObjFromState?.type === 'ranch')
    ? Number(selectedObjFromState.value) : ranchId];
  const userInfo = useSelector(selectLoginUserInfo).payload;
  const isAdmin = userInfo && userInfo.admin;

  // use local reducer to handle state of the component
  const [stateMap, dispatchMap] = useReducer(
    reducerMapMobile,
    initReducerMapMobile(),
    initReducerMapMobile,
  );

  // use local reducer to handle state of the component
  const [stateMap1, dispatchMap1] = useReducer(
    reducerMapMobile,
    initReducerMapMobile(),
    initReducerMapMobile,
  );

  // Patch fix for map settings dropdown selection issue
  useEffect(() => {
    const selectedSettings = JSON.parse(localStorage.getItem('presMode'));
    if (selectedSettings) {
      dispatch(setMapPresentationModes(selectedSettings));
    }
    return () => {
      localStorage.removeItem('presMode');
    };
  }, [dispatch]);

  const isLeft = stateMap?.layersControl?.side === 'left';

  useLoadMapData(dispatchMap, ranchId, dispatch);

  const mapFeatureGroupRef = useRef(null);

  const { blockIds } = useRanchBlockSelection();
  // Special case to show high res base map for SR Mockup Demo Field
  const showHighResBaseMap = ranchId === 74025;
  const blkId = (selectedObjFromState?.type === 'block')
    ? Number(selectedObjFromState.value) : blockId;

  const {
    ranchSensors,
    ranchGeoJSON,
    allRanchesPoints,
    allRanchesFeaturesData,
    selShowAllSensors,
    presMode,
    selMapShowSoilType,
    selMapShowLabels,
    blocksGeoJSON,
  } = useGetDataToRenderMap(ranchId, isAdmin, blockId, blockIds, selectedObjFromState, dispatchMap);

  const dataForReDraw = getDataForReDraw(ranchSensors, presMode,
    stateMap.anomalyGeoJSON, isLeft ? stateMap.selectedFeature : stateMap1.selectedFeature);

  useReDraw(
    mapFeatureGroupRef,
    dataForReDraw,
    presMode,
    isLeft ? stateMap.selectedFeature : stateMap1.selectedFeature,
    isLeft ? dispatchMap : dispatchMap1,
    selMapShowLabels,
    selShowAllSensors,
    allRanchesPoints,
    ranchId,
    stateMap.zoomSensorsVisible,
    isLeft ? stateMap.isBottomSheetVisible : stateMap1.isBottomSheetVisible,
  );

  useRecenter(
    blkId,
    ranchId,
    blocksGeoJSON,
    ranchGeoJSON,
    allRanchesFeaturesData,
    isLeft ? dispatchMap : dispatchMap1,
    (selectedObjFromState && selectedObjFromState.type),
    isLeft ? stateMap.isBottomSheetVisible : stateMap1.isBottomSheetVisible,
    isLeft ? stateMap.bottomSheetType : stateMap1.bottomSheetType,
  );

  useEffect(() => {
    if (selMapShowSoilType && !stateMap.showLayer?.soil?.left?.visible && isLeft) {
      dispatchMap({
        type: 'setShowLayer',
        payload: {
          ...stateMap.showLayer,
          soil: {
            left: { visible: selMapShowSoilType },
            right: { visible: stateMap.showLayer.soil.right.visible },
          },
        },
      });
    }
  }, [isLeft, selMapShowSoilType, stateMap.showLayer]);

  function refreshUntilUnmount() {
    refreshStatusInterval.current = setInterval(() => {
      // To reset all the states in every 15 mins
      dispatchMap({ type: 'reset' });
      prepData(dispatchMap, dispatch, ranchIds).catch();
      dispatchMap({ type: 'setLatestReload' });
    }, REFRESH_INTERVAL);
  }

  function clearRefreshInterval() {
    if (refreshStatusInterval.current) {
      clearInterval(refreshStatusInterval.current);
      refreshStatusInterval.current = null;
    }
  }

  // Trigger the refresh interval when component loads
  useEffect(() => {
    refreshUntilUnmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Clear the interval when component unmounts
  useEffect(() => () => {
    clearRefreshInterval();
  }, []);

  // To enable to satellite imagery control and show imagery when ranch/block is change
  const customDispatchMap = isLeft ? dispatchMap : dispatchMap1;
  useEffect(() => {
    if ((ranchId || blockId) && (stateMap.selectedImageryData.data?.length
      || stateMap1.selectedImageryData.data?.length) && isMobile) {
      customDispatchMap({
        type: 'setIsImageryPanelVisible',
        payload: true,
      });
      customDispatchMap({
        type: 'setIsBottomSheetVisible',
        payload: true,
      });
    }
    // To avoid multiple useEffect trigger also this will be refactored in future
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockId, ranchId]);

  // To close sidebar when select different ranch/block
  useEffect(() => {
    if (!isMobile) {
      dispatchMap({
        type: 'setSelectedFeatureWithType',
        payload: {
          selectedFeature: undefined,
          bottomSheetType: undefined,
          showBottomSheet: !!(blockId || ranchId),
        },
      });
    }
  }, [blockId, ranchId]);

  const imageryDataOpacityRef = useRef(stateMap.selectedImageryDataOpacity);
  useEffect(() => {
    if (!stateMap.showMap3D && !stateMap1.sideBySideMapVisible) {
      reDrawSatelliteImagery({
        mapFeatureGroupRef,
        selectedImageryData: stateMap.selectedImageryData,
        selectedImageryDataOpacity: imageryDataOpacityRef.current,
      });
    }
  });

  function renderSatelliteImagery(dispatchMapArg, stateArg) {
    return stateArg.bottomSheetType !== 'settings' && stateArg.isImageryPanelVisible
      && (
        <SatelliteImageryWrapper
          ranchId={ranchId}
          dispatchMap={dispatchMapArg}
          isLeft={isLeft}
        />
      );
  }

  function renderMap(dispatchMapArg, stateMapArg) {
    return !stateMap.showMap3D ? (
      <MapWrapper
        setModal={setModal}
        presMode={presMode}
        createAnomaly={createAnomaly}
        setCreateAnomaly={setCreateAnomaly}
        stateMapArg={stateMapArg}
        setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
        dispatchMapArg={dispatchMapArg}
        dispatchMap={dispatchMap}
        selectedObjFromState={selectedObjFromState}
        isAnomalyControlEnabled={isAnomalyControlEnabled}
        blockId={blkId || blockId}
        ranchId={ranchId}
        ranchGeoJSON={ranchGeoJSON}
        blocksGeoJSON={blocksGeoJSON}
        mapFeatureGroupRef={mapFeatureGroupRef}
        isLeft={isLeft}
        setLayerOption={setLayerOption}
        setMapSettingModal={setMapSettingModal}
        stateMap={stateMap}
        stateMap1={stateMap1}
        modal={modal}
        formValues={formValues}
        setIsLoading={setIsLoading}
        showHighResBaseMap={showHighResBaseMap}
      />
    ) : null;
  }

  function renderTabsControlForMobile() {
    return isMobile && (
      <div
        style={{
          position: 'relative',
          bottom: '-5px',
          zIndex: '501',
        }}
      >
        <MapTabsControl
          dispatchMap1={dispatchMap1}
          dispatchMap={dispatchMap}
          layersControl={stateMap.layersControl}
          sideBySideMapVisible={stateMap.sideBySideMapVisible}
          isImageryPanelVisible={isLeft ? stateMap.isImageryPanelVisible
            : stateMap1.isImageryPanelVisible}
        />
      </div>
    );
  }

  function renderShowDetails(dispatchMapArg, stateMapArg) {
    return (
      <ShowDetails
        blockId={blockId}
        ranchId={ranchId}
        selectedObjFromState={selectedObjFromState}
        isMobile={isMobile}
        presentationMode={presMode}
        isBottomSheetVisible={stateMapArg.isBottomSheetVisible}
        selectedFeature={stateMapArg.selectedFeature}
        dispatchMap={dispatchMapArg}
        sheetRef={sheetRef}
        bottomSheetType={stateMapArg.bottomSheetType}
        isImageryPanelVisible={stateMapArg.isImageryPanelVisible}
        selectedImageryData={stateMapArg.selectedImageryData}
        isExpandBtnVisible={stateMapArg.isExpandBtnVisible}
        expandedGeoJSON={stateMapArg.expandedGeoJSON}
        childComponent={renderTabsControlForMobile}
        isLeft={isLeft}
        onClick={(d) => {
          if (d?.type === 'close') {
            if (createAnomaly?.type === 'edit') {
              setIsAnomalyControlEnabled(false);
              setCreateAnomaly({ visible: false });
            }
          } else {
            formValues.current.anomaly_severity = stateMapArg.selectedFeature
              .properties.anomaly_severity;
            setModal({ visible: true, type: 'edit-severity' });
          }
        }}
        editable={presMode === 'anomaly' && stateMapArg.selectedFeature
          ? stateMapArg.selectedFeature.properties.is_user_created : false}
      />
    );
  }

  function renderMapControlBox() {
    return (
      <div className="map-component-mobile">
        <MapControlBox
          presMode={presMode}
          createAnomaly={createAnomaly}
          setCreateAnomaly={setCreateAnomaly}
          setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
          setModal={setModal}
          isBottomSheetVisible={stateMap.isBottomSheetVisible}
          isImageryPanelVisible={stateMap.isImageryPanelVisible}
          stateMapArg={isLeft ? stateMap : stateMap1}
          selectedFeature={isLeft ? stateMap.selectedFeature : stateMap1.selectedFeature}
          selectedObjFromState={selectedObjFromState}
          dispatchMap={dispatchMap}
          isAnomalyControlEnabled={isAnomalyControlEnabled}
          blockId={blockId}
          ranchId={ranchId}
          setShowModal={setShowModal}
          setMapSettingModal={setMapSettingModal}
          setLayerOption={setLayerOption}
          anomalyGeoJSON={stateMap.anomalyGeoJSON}
          sideBySideMapVisible={stateMap.sideBySideMapVisible}
        />
        {isLeft ? renderMap(dispatchMap, stateMap) : renderMap(dispatchMap1, stateMap1)}
      </div>
    );
  }

  const CONDITION_CHECK = stateMap.isBottomSheetVisible && stateMap1.isBottomSheetVisible;

  return (
    <div className="map-mobile">
      <div className={!isMobile && CONDITION_CHECK
        ? 'map-desktop-container' : 'map-mobile-container'}
      >
        {renderMapControlBox()}
      </div>

      {modal.visible && (
      <AnomalyCreateEditModal
        modal={modal}
        setModal={setModal}
        setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
        isLoading={isLoading}
        formValues={formValues}
        setCreateAnomaly={setCreateAnomaly}
        blockId={blkId}
        selectedFeature={stateMap.selectedFeature}
        dispatchMap={dispatchMap}
      />
      )}

      {!isMobile && !stateMap.showMap3D && (
        <div
          style={{
            position: 'absolute',
            bottom: '-2px',
            width: '500px',
            zIndex: '501',
            marginRight: (stateMap.isImageryPanelVisible && stateMap.isBottomSheetVisible)
              || (stateMap1.isImageryPanelVisible && stateMap1.isBottomSheetVisible)
              ? '400px' : '0',
          }}
        >
          <MapTabsControl
            dispatchMap1={dispatchMap1}
            dispatchMap={dispatchMap}
            layersControl={stateMap.layersControl}
            sideBySideMapVisible={stateMap.sideBySideMapVisible}
            isImageryPanelVisible={isLeft ? stateMap.isImageryPanelVisible
              : stateMap1.isImageryPanelVisible}
          />
          {renderSatelliteImagery(isLeft ? dispatchMap : dispatchMap1,
            isLeft ? stateMap : stateMap1)}
        </div>
      )}

      <MapSettingModal
        dispatchMap={dispatchMap}
        dispatchMap1={dispatchMap1}
        dispatchMapArg={isLeft ? dispatchMap : dispatchMap1}
        stateMap={stateMap}
        stateMap1={stateMap1}
        stateMapArg={isLeft ? stateMap : stateMap1}
        layerOption={layerOption}
        selMapShowSoilType={isLeft ? stateMap.showLayer?.soil?.left?.visible
          : stateMap1.showLayer?.soil?.right?.visible}
        mapSettingModal={mapSettingModal}
        setMapSettingModal={setMapSettingModal}
      />
      <LegendModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        legendDetails={legendConfig[presModeTitles[presMode]]}
        articleId={legendConfig[presModeTitles[presMode]]?.articleId}
      />
      {blockId && stateMap.showMap3D && !isMobile ? (
        <Map3D
          dispatchMapPage={dispatchMap}
          selectedBlockId={blkId}
        />
      ) : null}

      {isLeft ? renderShowDetails(dispatchMap, stateMap)
        : renderShowDetails(dispatchMap1, stateMap1)}
    </div>
  );
}

MapView.propTypes = {
  ranchId: PropTypes.number.isRequired,
  blockId: PropTypes.number.isRequired,
  selectedObjFromState: PropTypes.shape().isRequired,
};
