import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Button,
  Typography,
  Select,
  notification,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { blockApi, farmApi } from 'farmx-api';
import { actions, selectors, hooks } from 'farmx-redux-core';
import { PageHeader } from '../components/PageHeader';
import { IrrigationEquation } from '../components/IrrigationEquation';
import { StatusTag } from '../components/StatusTag';
import { CardLayout } from '../components/CardLayout';
import { getTextForIrrigationState, getStateForIrrigationState, getActionText } from './recommendation';
import { useTracking } from '../../../helper/mixpanel';
import { PercentAvailableWaterGraph } from './PercentAvailableWaterGraph';
import { EtcDeficitGraph } from './EtcDeficitGraph';
import { VWCMultilineGraph } from './VWCMultilineGraph';
import './recommendation-detail.css';
import RanchBlockTitle from '../components/RanchBlockTitle';
import { recommendationAnomalyFilter } from '../../../helper/common';

const {
  selectRecommendationForBlock,
} = selectors;

const {
  loadRecommendationForBlock,
  updateRecommendation,
} = actions;

export function RecommendationDetail(props) {
  const {
    showMap, onClick, propBlockId, showGraphButtons,
  } = props;
  const { t } = useTranslation();
  const getUserUnits = hooks.useUnits();
  const tracking = useTracking();
  const { identifier } = useParams();
  const blkId = parseInt(identifier, 10);
  const blockId = blkId || propBlockId;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [infoType, setInfoType] = useState(!showMap ? 'deficit' : 'vwc');
  const [VWCData, setVWCData] = useState(null);
  const [days, setDays] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentWaterData, setCurrentWaterData] = useState();
  const { useBlockNames, useRanchNamesForBlocks } = hooks;
  const blockName = useBlockNames([Number(blockId)]);
  const ranchName = useRanchNamesForBlocks([Number(blockId)]);
  const { selectedTab } = location.state ?? {};
  const recommendation = useSelector((state) => recommendationAnomalyFilter(
    selectRecommendationForBlock(state, blockId),
  ));
  const units = recommendation?.units || {
    deficit: 'millimeters',
    etcForecast: 'millimeters',
    totalWater: 'millimeters',
  };

  const irrigationModel = recommendation?.irrigationModel || 'soil_water_deficit';

  useEffect(() => {
    if (blockId) {
      tracking.track('Loaded Recommendation Detail Page', { blockId });
    }
  }, [tracking, blockId]);

  useEffect(() => {
    if (blockId) {
      dispatch(loadRecommendationForBlock(blockId));
    }
  }, [dispatch, blockId]);

  // To get the block rootzonw-vwc-graph data points
  useEffect(() => {
    if (infoType === 'vwc') {
      const startDate = moment().subtract(days, 'days').toISOString();
      const endDate = moment().toISOString();
      setLoading(true);
      try {
        blockApi.getBlockRootzoneVWCGraph({ blockId, startDate, endDate }).then((response) => {
          if (response.data) setVWCData(response.data.map((d) => ({ x: d[0], y: d[1] })));
          setLoading(false);
        }).catch(() => {
          notification.error({
            message: t('Failed to load rootzone-vwc-graph points'),
          });
          setLoading(false);
        });
      } catch {
        setLoading(false);
      }
    }
  }, [blockId, days, infoType, t]);

  useEffect(() => {
    farmApi.getBlockAvailableWater(blockId).then((response) => {
      setCurrentWaterData(response.data.currentWaterStats);
    });
  }, [blockId, setCurrentWaterData]);

  let averageRefillPoint = 0;
  let averageWiltingPoint = 0;
  let averageFieldCapacity = 0;
  if (currentWaterData) {
    // To get the average refillPoint
    averageRefillPoint = currentWaterData.depthStats.reduce(
      (partialSum, a) => a.refillPoint + partialSum,
      0,
    ) / currentWaterData.depthStats.length;

    // To get the average wiltingPoint
    averageWiltingPoint = currentWaterData.depthStats.reduce(
      (partialSum, a) => a.wiltingPoint + partialSum,
      0,
    ) / currentWaterData.depthStats.length;

    // To get the average fieldCapacity
    averageFieldCapacity = currentWaterData.depthStats.reduce(
      (partialSum, a) => a.fieldCapacity + partialSum,
      0,
    ) / currentWaterData.depthStats.length;
  }

  // TODO: loading
  if (!recommendation) {
    return (
      <div>Recommendation not found</div>
    );
  }

  function roundValue(value) {
    return Math.round(value * 100) / 100;
  }

  const dendrometerCount = 0;
  const duration = recommendation ? moment.duration(recommendation.duration).asDays() : null;

  function getSoilMessage() {
    if (recommendation.deficit === null || recommendation.deficit === undefined) {
      return null;
    }
    const { value: deficitConverted, label: depthLabel } = getUserUnits(recommendation.deficit, units.deficit, 'depth', {
      decimalPlaces: 2,
    });
    if (deficitConverted >= 0) {
      return `${t('The root zone is')} ${roundValue(deficitConverted)} ${t(depthLabel)}
       ${t('below field capacity on average')}.`;
    }
    return `${t('The root zone is')} ${-roundValue(deficitConverted)} ${t(depthLabel)}
    ${t('above field capacity on average')}.`;
  }

  function getEtcDeficitMessage() {
    if (recommendation.deficit === null || recommendation.deficit === undefined) {
      return null;
    }
    const { value: deficitConverted, label: depthLabel } = getUserUnits(recommendation.deficit, units.deficit, 'depth', {
      decimalPlaces: 2,
    });
    if (deficitConverted >= 0) {
      return `${t('The applied irrigation is')} ${roundValue(deficitConverted)} ${t(depthLabel)}
       ${t('below the measured ETc this season')}.`;
    }
    return `${t('The applied irrigation is')} ${-roundValue(deficitConverted)} ${t(depthLabel)}
    ${t('above the measured ETc this season')}.`;
  }

  function getDeficitMessage() {
    if (irrigationModel === 'soil_water_deficit') {
      return getSoilMessage();
    }
    if (irrigationModel === 'etc_deficit') {
      return getEtcDeficitMessage();
    }
    return null;
  }

  function getEtcMessage() {
    if (recommendation.etcForecast === null || recommendation.etcForecast === undefined) {
      return null;
    }
    const {
      value: etcForecastConverted,
      label: depthLabel,
    } = getUserUnits(recommendation.etcForecast, units.etcForecast, 'depth', {
      decimalPlaces: 2,
    });
    if (recommendation.deficit < 0) {
      return `${t('The surplus is predicted to decrease by')} ${roundValue(etcForecastConverted)}
       ${t(depthLabel)} ${t('over the next')} ${duration} ${t('days')}
       ${t('due to the local ETc forecast')}.`;
    }
    return `${t('The deficit is predicted to increase by')} ${roundValue(etcForecastConverted)}
     ${t(depthLabel)} ${t('over the next')} ${duration} ${t('days')}
      ${t('due to the local ETc forecast')}.`;
  }

  function getIrrigationDescriptionLong() {
    const messages = [];
    const deficitMessage = getDeficitMessage();
    const etcMessage = getEtcMessage();
    const dendrometerMessage = `${t(recommendation.cropStress)} ${t('is indicated across')}
     ${dendrometerCount} ${t('dendrometers')}`;

    if (deficitMessage) {
      messages.push(deficitMessage);
    }
    if (etcMessage) {
      messages.push(etcMessage);
    }
    if (dendrometerCount && recommendation.cropStress) {
      messages.push(dendrometerMessage);
    }
    return messages.join(' ');
  }

  function renderGraphButtons() {
    return (
      <div style={{ width: '50%' }}>
        {!showMap && (
        <Button
          style={{ width: '60px', padding: '0' }}
          type={infoType === 'deficit' ? 'primary' : 'default'}
          onClick={() => {
            if (infoType !== 'deficit') {
              setInfoType('deficit');
            }
          }}
        >
          {t('Deficit')}
        </Button>
        )}

        {showMap && (
        <Button
          style={{ width: '60px', padding: '0' }}
          type="default"
          onClick={() => {
            if (onClick) onClick('map');
          }}
        >
          {t('Map')}
        </Button>
        )}

        <Button
          style={{ width: '60px', padding: '0' }}
          type={(infoType === 'vwc' || showMap) ? 'primary' : 'default'}
          onClick={() => {
            if (!showMap && infoType !== 'vwc') {
              setInfoType('vwc');
            }
            if (showMap && onClick) onClick('vwc');
          }}
        >
          {t('VWC')}
        </Button>
      </div>
    );
  }

  function renderSoilGraphs() {
    return (
      <div className="recommendation-detail-card2-container">
        <div className="recommendation-detail-card2-top">
          {showGraphButtons && !showMap ? renderGraphButtons() : (
            <div style={{ width: '50%' }}>
              <div className="recommendation-detail-card2-deficit">
                <Typography.Text>{t('Percentage VWC')}</Typography.Text>
              </div>
            </div>
          )}
          {infoType === 'deficit' && (
            <div className="recommendation-detail-card2-deficit">
              <Typography.Text>{t('Percent Available Water')}</Typography.Text>
            </div>
          )}

          {infoType === 'vwc' && (
            <div style={{ display: 'flex' }}>
              <Select defaultValue={10} value={days} onSelect={setDays}>
                <Select.Option value={10}>{`10 ${t('Days')}`}</Select.Option>
                <Select.Option value={20}>{`20 ${t('Days')}`}</Select.Option>
              </Select>
            </div>
          )}
        </div>
        {infoType === 'deficit' && (
          <div style={{ backgroundColor: '#aaa', height: '200px' }}>
            <PercentAvailableWaterGraph blockId={Number(recommendation.block)} />
          </div>
        )}
        {infoType === 'vwc' && showGraphButtons && !showMap && (
          <div className="recommendation-detail-card2-deficit">
            <Typography.Text>{t('Percentage VWC')}</Typography.Text>
          </div>
        )}
        {infoType === 'vwc' && (
          <div style={{ backgroundColor: '#aaa', height: '200px', marginTop: '10px' }}>
            <VWCMultilineGraph
              fieldCapacity={averageFieldCapacity}
              wiltingPoint={averageWiltingPoint}
              refillPoint={averageRefillPoint}
              data={VWCData}
              loading={loading}
              chartType="line"
              editMode={[null]}
            />
            {loading && VWCData && <LoadingOutlined className="absolute-center" />}
          </div>
        )}
      </div>
    );
  }

  function renderEtcGraphs() {
    return (
      <div className="recommendation-detail-card2-container">
        <div className="recommendation-detail-card2-top">
          <div className="recommendation-detail-card2-deficit">
            <Typography.Text>{t('Etc Deficit')}</Typography.Text>
          </div>
        </div>
        <div style={{ backgroundColor: '#aaa', height: '200px' }}>
          <EtcDeficitGraph
            irrigationApplied={recommendation.irrigationApplied}
            etcHistoric={recommendation.etcHistoric}
            etcForecast={recommendation.etcForecast}
            irrigationScheduled={recommendation.irrigationScheduled}
            units={recommendation.units}
          />
        </div>
      </div>
    );
  }

  function renderGraphs() {
    if (irrigationModel === 'soil_water_deficit') {
      return renderSoilGraphs();
    }

    if (irrigationModel === 'etc_deficit') {
      return renderEtcGraphs();
    }

    return null;
  }


  const translatedDescription = t(recommendation.description);

  const ranchBlockName = `${ranchName[blockId]} > ${blockName[blockId]}`;

  function getRecommendationMessage(modelType) {
    let modelTypeText = null;
    if (modelType === 'etc_deficit') modelTypeText = 'ETc';
    if (modelType === 'soil_water_deficit') modelTypeText = 'Soil';
    return modelTypeText ? (
      <small>
        <em>
          {t(`This recommendation was generated using the ${modelTypeText} deficit model`)}
        </em>
      </small>
    ) : null;
  }

  function renderRecommendationActionButtons() {
    return (
      <div className="action-footer recommendation-detail-actions recommendation-buttons">
        {recommendation.action !== 'no_action' ? (
          <Button
            size="large"
            block
            type="primary"
            onClick={() => {
              history.push({
                pathname: `/recommendation/action/${blockId}`,
                state: {
                  from: location.pathname,
                  selectedTab,
                },
              });
              tracking.track('Clicked recommendation action', recommendation);
            }}
          >
            {getActionText(recommendation.action)}
          </Button>
        ) : (
          <Button
            size="large"
            block
            type="primary"
            onClick={() => {
              history.push({
                pathname: '/schedule',
                state: {
                  from: location.pathname,
                  blockId,
                },
              });
              tracking.track('Clicked view schedule', recommendation);
            }}
          >
            {t('View Schedule')}
          </Button>
        )}
        {recommendation.isFrozen ? (
          <Button
            size="large"
            block
            onClick={() => {
              dispatch(updateRecommendation({
                id: recommendation.id,
                type: 'irrigation',
                data: {
                  isFrozen: false,
                  dateFrozen: moment().toISOString(),
                },
              }));
              history.push({
                pathname: '/recommendation',
                state: { selectedTab },
              });
              tracking.track('Clicked Undo Dismissed recommendation', recommendation);
            }}
          >
            {t('Undo Dismiss')}
          </Button>
        ) : (
          <Button
            size="large"
            block
            onClick={() => {
              dispatch(updateRecommendation({
                id: recommendation.id,
                type: 'irrigation',
                data: {
                  isFrozen: true,
                  dateFrozen: moment().toISOString(),
                  freezeType: 'dismiss',
                },
              }));
              history.push({
                pathname: '/recommendation',
                state: { selectedTab },
              });
              tracking.track('Dismissed recommendation', recommendation);
            }}
          >
            {t('Dismiss')}
          </Button>
        )}
      </div>
    );
  }

  return (
    <>
      {!showMap ? (
        <div className="page-content page-container margin-10 recommendation-container">
          <PageHeader
            showBack
            toPath="/recommendation"
            title={<RanchBlockTitle title={ranchBlockName} />}
            state={{ selectedTab }}
          />
          <CardLayout key="1">
            <div className="recommendation-detail-card1-container">
              <StatusTag
                width="100%"
                text={t(getTextForIrrigationState(recommendation.state))}
                state={getStateForIrrigationState(recommendation.state)}
                tagStyle={{ fontSize: '16px', padding: '4px', marginBottom: '10px' }}
              />
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <Typography.Text>{translatedDescription}</Typography.Text>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <Typography.Text>{getIrrigationDescriptionLong()}</Typography.Text>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {getRecommendationMessage(irrigationModel)}
              </div>
            </div>
          </CardLayout>
          <CardLayout key="2">
            {renderGraphs()}
          </CardLayout>

          <IrrigationEquation
            deficit={Number(recommendation.deficit)}
            etcForecast={Number(recommendation.etcForecast)}
            irrigationDuration={recommendation.irrigationDuration}
            units={units}
          />
          {renderRecommendationActionButtons()}
        </div>
      ) : renderGraphs()}
    </>
  );
}

RecommendationDetail.propTypes = {
  showMap: PropTypes.bool,
  onClick: PropTypes.func,
  propBlockId: PropTypes.number,
  showGraphButtons: PropTypes.bool,
};

RecommendationDetail.defaultProps = {
  showMap: false,
  onClick: null,
  propBlockId: null,
  showGraphButtons: true,
};
