import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Select, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import isEqual from 'react-fast-compare';
import { selectors, actions } from 'farmx-redux-core';
import { RanchBlockSelectMobile } from 'farmx-web-ui';
import { FaSlidersH, FaLayerGroup } from 'react-icons/fa';
import { presModeTitles } from './MapSettings';
import HelpButton from '../components/HelpButton';
import MapControlButtons from './MapControlButtons';
import AnomalyControls from './Anomaly/AnomalyControls';
import AnomalyButtons from './Anomaly/AnomalyButtons';
import { isMobile } from '../../../utils/detectDevice';
import { getPresentationModes } from '../../../helper/mapHelper';

const {
  setRanchBlockSelection,
  setMapPresentationModes,
  setRanch,
  setBlocks,
} = actions;

const {
  selectLoginUserInfo,
  selectMapPresentationModes,
} = selectors;

const availableModes = [
  'soilstatus',
  'connectivity',
  'waterpressure',
  'control',
  'irrigationRecommendation',
  'anomaly',
  'cropHealth',
];

export default function MapControlBox(props) {
  const {
    dispatchMap, setIsAnomalyControlEnabled, presMode, selectedObjFromState,
    isAnomalyControlEnabled, stateMapArg, ranchId, blockId, isBottomSheetVisible,
    isImageryPanelVisible, anomalyGeoJSON, createAnomaly, setCreateAnomaly,
    selectedFeature, setModal, setShowModal, setLayerOption, setMapSettingModal,
    sideBySideMapVisible,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userInfo = useSelector(selectLoginUserInfo).payload;
  const isAdmin = userInfo && userInfo.admin;
  const selMapPresentationModes = useSelector(
    (state) => selectMapPresentationModes(state), isEqual,
  );

  function onRanchBlockClick(id, type) {
    if (type === 'block' && !isMobile) dispatch(setBlocks([Number(id)]));
    if (type === 'ranch' && !isMobile) dispatch(setRanch(Number(id)));
    dispatchMap({
      type: 'setSelectedFeatureWithType',
      payload: {
        selectedFeature: undefined,
        bottomSheetType: undefined,
        showBottomSheet: true,
      },
    });
  }

  const layerOptionButton = () => (
    <Button
      size="large"
      type="primary"
      icon={<FaLayerGroup />}
      style={{ backgroundColor: 'rgba(0,0,0,0.7)', color: 'white', border: 'none' }}
      onClick={() => {
        setLayerOption(true);
        setMapSettingModal(true);
      }}
    />
  );

  function handleSelectedRanchBlock(d) {
    if (d && d.value !== undefined) {
      dispatch(setRanchBlockSelection(d));
      onRanchBlockClick();
    }
  }
  const mapViewOptions = useMemo(() => Object
    .keys(getPresentationModes(selMapPresentationModes))
  // exclude modes not yet implemented
    .filter((mode) => availableModes.includes(mode))
    .map((mode) => ({
      key: mode,
      label: presModeTitles[mode],
      value: mode,
    })), [selMapPresentationModes]);

  return (
    <div className="map-mobile-screen">
      <div className={!isMobile ? 'controls-all-container map-desktop-control-box'
        : 'controls-all-container'}
      >
        {isMobile && (
        <div className="div-select-container ranch-block-select-container">
          <RanchBlockSelectMobile
            getSelectedRanchBlock={handleSelectedRanchBlock}
            selected={selectedObjFromState}
            admin={isAdmin}
          />
        </div>
        )}
        <div className="controls-box">
          {!isAnomalyControlEnabled && (
          <>
            <div className="controls-left">
              <div className="map-view-select-wrapper">
                <span className="map-view-select-icon"><FaSlidersH /></span>
                <Select
                  className="map-view-select"
                  value={t(presModeTitles[presMode])}
                  style={{
                    minWidth: 220,
                    color: 'white',
                    fontSize: 16,
                  }}
                  onChange={(e) => {
                    localStorage.setItem('presMode', JSON.stringify({ [e]: true }));
                    dispatch(setMapPresentationModes({ [e]: true }));
                  }}
                  options={mapViewOptions}
                  suffixIcon={<DownOutlined style={{ color: 'white' }} />}
                />
              </div>
            </div>
            <div className="help-icon">
              <HelpButton onClick={() => setShowModal(true)} />
            </div>
          </>
          )}
          <div className="controls-right">
            {isMobile && (
            <>
              {!isAnomalyControlEnabled && layerOptionButton()}
              {!isAnomalyControlEnabled && (
              <MapControlButtons
                isMobile={isMobile}
                isImageryPanelVisible={stateMapArg.isImageryPanelVisible}
                selectedObjFromState={selectedObjFromState}
                ranchId={ranchId}
                blockId={blockId}
                dispatchMap={dispatchMap}
                isBottomSheetVisible={isBottomSheetVisible}
                selectedImageryData={stateMapArg.selectedImageryData}
                anomalyGeoJSON={anomalyGeoJSON}
                presMode={presMode}
              />
              )}
              <AnomalyControls
                presMode={presMode}
                createAnomaly={createAnomaly}
                setCreateAnomaly={setCreateAnomaly}
                selectedFeature={selectedFeature}
                setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
              />
            </>
            )}
          </div>
          {isMobile && (
          <AnomalyButtons
            presMode={presMode}
            createAnomaly={createAnomaly}
            setCreateAnomaly={setCreateAnomaly}
            stateMapArg={stateMapArg}
            setIsAnomalyControlEnabled={setIsAnomalyControlEnabled}
            isImageryPanelVisible={isImageryPanelVisible}
            sideBySideMapVisible={sideBySideMapVisible}
            setModal={setModal}
          />
          )}
        </div>
      </div>
    </div>
  );
}

MapControlBox.propTypes = {
  presMode: PropTypes.string,
  createAnomaly: PropTypes.func,
  setCreateAnomaly: PropTypes.func,
  setIsAnomalyControlEnabled: PropTypes.func,
  setModal: PropTypes.func,
  isBottomSheetVisible: PropTypes.bool,
  isImageryPanelVisible: PropTypes.bool,
  stateMapArg: PropTypes.shape(),
  selectedFeature: PropTypes.shape(),
  selectedObjFromState: PropTypes.shape(),
  dispatchMap: PropTypes.func,
  isAnomalyControlEnabled: PropTypes.bool,
  blockId: PropTypes.number,
  ranchId: PropTypes.number,
  setShowModal: PropTypes.func,
  setLayerOption: PropTypes.func,
  setMapSettingModal: PropTypes.func,
  anomalyGeoJSON: PropTypes.shape(),
  sideBySideMapVisible: PropTypes.bool,
};

MapControlBox.defaultProps = {
  setModal: null,
  presMode: null,
  createAnomaly: null,
  setCreateAnomaly: null,
  stateMapArg: null,
  setIsAnomalyControlEnabled: null,
  isBottomSheetVisible: false,
  isImageryPanelVisible: false,
  selectedFeature: null,
  dispatchMap: null,
  selectedObjFromState: {},
  isAnomalyControlEnabled: false,
  blockId: null,
  ranchId: null,
  setShowModal: null,
  setLayerOption: null,
  setMapSettingModal: null,
  anomalyGeoJSON: null,
  sideBySideMapVisible: false,
};
