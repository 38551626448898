import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Form, Input, Button, Typography, Divider,
} from 'antd';
import { authService } from 'farmx-api';
import { useTranslation } from 'react-i18next';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import FarmXLogo from '../../assets/images/farmx_logo_white.png';
import './LoginPageMobile.less';

export default function LoginPageMobile() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { from } = location.state || { from: { pathname: '/' } };
  const [loginFailed, setLoginFailed] = useState(false);

  async function onFinish(values) {
    const { username, password } = values;
    const success = await authService.login(username, password);
    setLoginFailed(!success);
    if (success) {
      history.replace(from);
    }
  }

  return (
    <div className="container mobile">
      <div className="login-container">
        <div className="logo">
          <img className="login-logo" alt="FarmX" src={FarmXLogo} />
        </div>
        <Divider className="login-divider" />
        <Form
          className="login-form"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="username"
            label={t('Username')}
            rules={[{
              required: true,
              message: t('Please input your username'),
              min: 1,
            }]}
          >
            <Input
              data-test-id="login-username"
              autoComplete="username"
              autoCapitalize="none"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label={t('Password')}
            rules={[{ required: true, message: t('Please input your password') }]}
          >
            <Input.Password
              data-test-id="login-password"
              autoComplete="current-password"
              id="current-password"
              prefix={<LockOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item className="submit-form-item">
            <Button
              type="link"
              htmlType="submit"
              className="login-link"
              href="/reset-password"
            >
              {t('Forgot Password?')}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              data-test-id="login-submit"
            >
              {t('Login')}
            </Button>
          </Form.Item>
          <Typography.Text type="danger" className="login-message">
            {loginFailed ? t('Login failed') : null}
          </Typography.Text>

        </Form>
      </div>
    </div>
  );
}
