/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Layout,
  Button,
  DatePicker,
  Select,
  Typography,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import {
  FastBackwardOutlined,
  StepBackwardOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  StepForwardOutlined,
  FastForwardOutlined,
  CloseOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import isEqual from 'react-fast-compare';
import { StyleSheet, css } from 'aphrodite';
import { FaCloud } from 'react-icons/fa';

const isApp = matchMedia('(display-mode: standalone)').matches;
const padding = 6;
const dateFormat = 'D MMM YY';

const dataSourcesOptions = [
  <Select.Option value="satellite" key={1}>Satellite</Select.Option>,
  <Select.Option value="aerial" key={2}>Aerial</Select.Option>,
];

const dataTypesObj = {
  NDVI: 'Plant Vigor (NDVI)',
  NDVI_Histogram_Equalization: 'Plant Vigor (NDVI) enhanced',
  NDWI_SWIR: 'Water Content (NDWI)',
  NDWI_SWIR_Histogram_Equalization: 'Water Content (NDWI) enhanced',
  CloudMask: 'Cloud Mask',
  Thermal: 'Thermal',
  Multiband: 'Multi Band',
  SATVI: 'SATVI',
  TrueColor: 'True Color',
  FalseColor: 'False Color',
  EVI: 'EVI',
  EVI2: 'EVI2',
  CHL_Red_Edge: 'CHL Red Edge',
  CVI: 'CVI',
  CHL_Red_Edge_Histogram_Equalization: 'CHL Red Edge enhanced',
  CWSI: 'CWSI',
  DryBiomassIndex: 'Dry Biomass Index',
  DryBiomassIndex_Histogram_Equalization: 'Dry Biomass Index enhanced',
  Thermal_Histogram_Equalization: 'Thermal enhanced',
};

const allowedDataTypes = ['NDVI_Histogram_Equalization',
  'NDWI_SWIR_Histogram_Equalization', 'NDRE'];

const getDataTypeOptionsByDataSource = (satelliteDates, selectedSatelliteDataSource) => {
  if (!(satelliteDates && selectedSatelliteDataSource)) {
    return [];
  }

  if (selectedSatelliteDataSource === 'satellite') {
    const filteredDataTypes = satelliteDates.availableSatelliteTypes
      .filter((d) => allowedDataTypes.includes(d));
    return filteredDataTypes
      .map((st) => <Select.Option value={st} key={st}>{dataTypesObj[st] || st}</Select.Option>);
  }

  if (selectedSatelliteDataSource === 'aerial') {
    const filteredDataTypes = satelliteDates.availableAerialTypes
      .filter((d) => allowedDataTypes.includes(d));
    return filteredDataTypes
      .map((st) => <Select.Option value={st} key={st}>{dataTypesObj[st] || st}</Select.Option>);
  }

  return [];
};

function getClosest(arr, target, current, lessThan) {
  /* assumes arr is sorted ascending */
  if (lessThan === true) {
    const filtered = arr.filter((v) => v.momentDate.valueOf() <= target.valueOf());
    if (!filtered.length) {
      // if nothing was found return the smallest value less than current
      return arr.filter((v) => v.momentDate.valueOf() < current.valueOf())[0];
    }
    return filtered.slice(-1)[0];
  }

  const filtered = arr.filter((v) => v.momentDate.valueOf() >= target.valueOf());
  if (!filtered.length) {
    // if nothing was found return the largest value greater than current
    return arr.filter((v) => v.momentDate.valueOf() > current.valueOf()).slice(-1)[0];
  }
  return filtered[0];
}

function getDate(cond, availableDatesCollection, currDate) {
  if (!availableDatesCollection) {
    return undefined;
  }

  if (!currDate) {
    return undefined;
  }

  if (!availableDatesCollection?.datesArray) {
    return undefined;
  }

  const availableDates = availableDatesCollection.datesArray;

  if (availableDates.length < 2) {
    return undefined;
  }

  if (cond.startsWith('Prev') && currDate.format('YYYY-MM-DD') === availableDates[0].dateString) {
    return undefined;
  }

  if (cond.startsWith('Next')
    && currDate.format('YYYY-MM-DD') === availableDates[availableDates.length - 1].dateString) {
    return undefined;
  }

  let target;
  let lessThan;

  switch (cond) {
    case 'PrevYear': {
      lessThan = true;
      target = moment(currDate).subtract(1, 'years')
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
      break;
    }
    case 'PrevMonth': {
      lessThan = true;
      target = moment(currDate).subtract(1, 'months')
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
      break;
    }
    case 'Prev': {
      lessThan = true;
      target = moment(currDate).subtract(1, 'days')
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
      break;
    }
    case 'Next': {
      lessThan = false;
      target = moment(currDate).add(1, 'days')
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
      break;
    }
    case 'NextMonth': {
      lessThan = false;
      target = moment(currDate).add(1, 'months')
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
      break;
    }
    case 'NextYear': {
      lessThan = false;
      target = moment(currDate).add(1, 'years')
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
      break;
    }
    default: throw new Error('unknown cond in getDate');
  }

  return getClosest(availableDates, target, currDate, lessThan);
}

const getDateHandler = (
  cond,
  dispatchMapPage,
  selectedSatelliteDatesFiltered,
  selectedSatelliteDate,
) => () => {
  const dataObj = getDate(cond, selectedSatelliteDatesFiltered, selectedSatelliteDate);
  if (dataObj?.momentDate) {
    dispatchMapPage({
      type: 'setSelectedSatelliteDate',
      payload: dataObj.momentDate,
    });
  }
};

function getModalDivHeight() {
  const footerDivElement = document.querySelector('.bottom-tabbar-container');
  const footerHeight = footerDivElement ? footerDivElement.offsetHeight : 0;
  const bottomSheetDivElement = document.getElementById('satellite-imagery-section');
  const bottomSheetHeight = (bottomSheetDivElement && bottomSheetDivElement.offsetHeight);

  return { footerHeight, drawerHeight: bottomSheetHeight };
}

// Constructing class for the modal position on bottom drawer
function getCustomClassName() {
  const dropdownElem = document.querySelector('div[data-rsbs-overlay]');
  const elem = document.getElementById('satellite-imagery-section');
  const elemTop = elem ? elem.offsetTop : 0;
  const dropdownElemTop = dropdownElem ? dropdownElem.offsetTop : 0;
  const createdStyles = StyleSheet.create({
    topPosition: {
      top: isApp ? `${(dropdownElemTop + padding)}px !important`
        : `${(dropdownElemTop + elemTop + padding)}px !important`,
    },
  });

  return `${css(createdStyles.topPosition)}`;
}

export const SatelliteImagery = ({
  dispatchMapPage,
  satelliteDates,
  selectedSatelliteDate,
  selectedSatelliteDataType,
  selectedSatelliteDataSource,
  selectedSatelliteDatesFiltered,
  dateRangeWithDisplayData,
  selectedImageryData,
  isMobile,
  childComponent,
}) => {
  const { t } = useTranslation();
  const modalHeight = getModalDivHeight();

  function checkIsCloudCoverageRangeCrossed(value) {
    const cloudCoverageArr = value?.data?.map((d) => d && d.cloud_coverage);
    const isRangeCrossed = cloudCoverageArr?.reduce((a, o) => (o && o > 0.10) || a, false);
    return isRangeCrossed;
  }

  function showMessageByCloudCoverage(cloudData) {
    if (cloudData) {
      const isRangeCrossed = checkIsCloudCoverageRangeCrossed(cloudData);
      if (isRangeCrossed) {
        notification.info({
          message: t('Cloud Coverage Exceeds 10%'),
        });
      }
    }
  }

  function renderCloudIcon(data) {
    if (data) {
      const isRangeCrossed = checkIsCloudCoverageRangeCrossed(data);
      return (
        <>
          {isRangeCrossed && (
          <div
            className="cloud-coverage-icon-container"
            onClick={() => {
              dispatchMapPage({
                type: 'setSelectedImageryData',
                payload: {
                  visible: true,
                  data: data.data,
                },
              });
              dispatchMapPage({ type: 'setSelectedSatelliteDate', payload: data.momentDate });
              showMessageByCloudCoverage(data);
            }}
            aria-hidden="true"
          >
            <FaCloud
              className="cloud-coverage-icon"
            />
          </div>
          )}
        </>
      );
    }
    return null;
  }

  return (
    <>
      {/* To display satellite data type dropdown on top */}
      {isMobile && (
      <div>
        <Select
          labelInValue
          style={{
            marginRight: '2px',
          }}
          value={{ value: selectedSatelliteDataType }}
          onChange={(v) => {
            dispatchMapPage({
              type: 'setSelectedSatelliteDataType',
              payload: v.value,
            });
          }}
          className="layout-width"
          dropdownClassName={satelliteDates
            ? `data-type-popup-dropdown ${getCustomClassName()}` : undefined}
          listHeight={(modalHeight.drawerHeight) - (modalHeight.footerHeight)}
          popupClassName='satellite-data-type-list-options'
        >
          {getDataTypeOptionsByDataSource(satelliteDates, selectedSatelliteDataSource)}
        </Select>
        <div className="margin-top-10">
          <Typography.Title level={4}>
            {t('Select an available date')}
          </Typography.Title>
        </div>
      </div>
      )}
      <div>
        {childComponent && childComponent()}
      </div>
      <Layout
        className={isMobile ? 'layout-height' : ''}
      >
        <div
          style={{ height: '60px' }}
          className="mobile-header-color"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              marginTop: '1em',
              marginBottom: '1em',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <div>
                <Button
                  type="primary"
                  icon={<FastBackwardOutlined />}
                  ghost
                  size="large"
                  disabled={!getDate('PrevYear', selectedSatelliteDatesFiltered, selectedSatelliteDate)}
                  onClick={getDateHandler('PrevYear', dispatchMapPage, selectedSatelliteDatesFiltered, selectedSatelliteDate)}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                  }}
                />
              </div>
              <div>
                <Button
                  type="primary"
                  icon={<StepBackwardOutlined />}
                  ghost
                  size="large"
                  disabled={!getDate('PrevMonth', selectedSatelliteDatesFiltered, selectedSatelliteDate)}
                  onClick={getDateHandler('PrevMonth', dispatchMapPage, selectedSatelliteDatesFiltered, selectedSatelliteDate)}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                  }}
                />
              </div>
              <div>
                <Button
                  type="primary"
                  icon={<CaretLeftOutlined />}
                  ghost
                  size="large"
                  disabled={!getDate('Prev', selectedSatelliteDatesFiltered, selectedSatelliteDate)}
                  onClick={getDateHandler('Prev', dispatchMapPage, selectedSatelliteDatesFiltered, selectedSatelliteDate)}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                  }}
                />
              </div>
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                {!!selectedSatelliteDate && (
                <DatePicker
                  size="small"
                  allowClear={false}
                  value={selectedSatelliteDate}
                  format={dateFormat}
                  disabledDate={(momentDate) => {
                    if (selectedSatelliteDatesFiltered?.datesObj) {
                      return !selectedSatelliteDatesFiltered.datesObj[momentDate.format('YYYY-MM-DD')];
                    }
                    return true;
                  }}
                  inputReadOnly
                  onChange={(momentDate) => {
                    dispatchMapPage({ type: 'setSelectedSatelliteDate', payload: momentDate });
                  }}
                  {...(isMobile ? { suffixIcon: null } : {})}
                />
                )}

              </div>
              <div>
                <Button
                  type="primary"
                  icon={<CaretRightOutlined />}
                  ghost
                  size="large"
                  disabled={!getDate('Next', selectedSatelliteDatesFiltered, selectedSatelliteDate)}
                  onClick={getDateHandler('Next', dispatchMapPage, selectedSatelliteDatesFiltered, selectedSatelliteDate)}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                  }}
                />
              </div>
              <div>
                <Button
                  type="primary"
                  icon={<StepForwardOutlined />}
                  ghost
                  size="large"
                  disabled={!getDate('NextMonth', selectedSatelliteDatesFiltered, selectedSatelliteDate)}
                  onClick={getDateHandler('NextMonth', dispatchMapPage, selectedSatelliteDatesFiltered, selectedSatelliteDate)}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                  }}
                />
              </div>
              <div>
                <Button
                  type="primary"
                  icon={<FastForwardOutlined />}
                  ghost
                  size="large"
                  disabled={!getDate('NextYear', selectedSatelliteDatesFiltered, selectedSatelliteDate)}
                  onClick={getDateHandler('NextYear', dispatchMapPage, selectedSatelliteDatesFiltered, selectedSatelliteDate)}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                  }}
                />
              </div>
            </div>

            {/* To display close icon only on desktop */}
            {!isMobile && (
            <div
              style={{
                width: '44px',
                marginRight: '10px',
                marginLeft: '2px',
              }}
            >
              <Button
                type="primary"
                icon={<CloseOutlined />}
                ghost
                size="medium"
                onClick={() => {
                  dispatchMapPage({
                    type: 'setIsImageryPanelVisible',
                    payload: false,
                  });
                }}
                style={{
                  border: 'none',
                  boxShadow: 'none',
                }}
              />
            </div>
            )}
          </div>
        </div>

        <div
          style={{
            minHeight: '120px',
            paddingLeft: '10px',
            paddingRight: '10px',
            backgroundColor: 'white',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          {!!dateRangeWithDisplayData && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {dateRangeWithDisplayData.map((d) => (
              <div
                key={JSON.stringify(d)}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                className={!!d?.data && isEqual(selectedImageryData?.data, d?.data) ? 'satellite-imagery-container-highlight' : ''}
              >
                <div
                  className={!!d?.data && isEqual(selectedImageryData?.data, d?.data) ? 'satellite-imagery-date-highlight' : ''}
                >
                  {d.momentDate.format(isMobile ? 'M/D/YY' : dateFormat)}
                </div>
                <div>
                  {!!d.data && (
                    <div>
                      <img
                        src={d.data[0].png_url}
                        alt={d.data[0].scene_id}
                        style={{
                          height: '60px',
                          width: '100%',
                          verticalAlign: 'middle',
                          border: 'none',
                        }}
                        onClick={() => {
                          dispatchMapPage({
                            type: 'setSelectedImageryData',
                            payload: {
                              visible: true,
                              data: d.data,
                            },
                          });
                          dispatchMapPage({ type: 'setSelectedSatelliteDate', payload: d.momentDate });
                          showMessageByCloudCoverage(d);
                        }}
                        aria-hidden="true"
                      />
                        {renderCloudIcon(d)}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          )}

          <div
            style={{
              height: '30px',
              width: '100%',
              fontSize: '10px',
              marginTop: '2px',
            }}
          >
            {(() => {
              if (dateRangeWithDisplayData && dateRangeWithDisplayData.length) {
                const dateRangeWithDisplayDataLength = dateRangeWithDisplayData.length;
                for (let i = 0; i < dateRangeWithDisplayDataLength; i += 1) {
                  const dateData = dateRangeWithDisplayData[i];
                  if (dateData && dateData?.data?.length && dateData?.data[0]?.data_key_url) {
                    return (
                      <>
                        <img
                          src={dateData?.data[0]?.data_key_url}
                          alt={dateData?.data[0]?.scene_id}
                          style={{
                            width: '100%',
                            height: '12px',
                            verticalAlign: 'top',
                            border: 'none',
                            borderTop: '1px solid #333',
                          }}
                        />
                        <div
                          style={{
                            width: '100%',
                            height: '10px',
                            fontSize: '12px',
                            display: 'flex',
                            flexDirection: 'row',
                            verticalAlign: 'top',
                          }}
                        >
                          <div
                            style={{
                              textAlign: 'left',
                              flex: '1 0',
                            }}
                          >
                            0 (low)
                          </div>
                          <div
                            style={{
                              textAlign: 'center',
                              flex: '1 0',
                            }}
                          >
                            0.5
                          </div>
                          <div
                            style={{
                              textAlign: 'right',
                              flex: '1 0',
                            }}
                          >
                            1 (high)
                          </div>
                        </div>
                      </>
                    );
                  }
                }
              }
              return null;
            })()}
          </div>
        </div>

        {/* To display satellite data type and image type dropdowns only on desktop */}
        {!isMobile && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Select
              labelInValue
              style={{
                width: 200,
                marginLeft: '10px',
                marginRight: '2px',
              }}
              value={{ value: selectedSatelliteDataType }}
              onChange={(v) => {
                dispatchMapPage({
                  type: 'setSelectedSatelliteDataType',
                  payload: v.value,
                });
              }}
              popupClassName='satellite-data-type-list-options'
            >
              {getDataTypeOptionsByDataSource(satelliteDates, selectedSatelliteDataSource)}
            </Select>

            <Select
              style={{ width: 96 }}
              value={selectedSatelliteDataSource}
              onChange={(v) => {
                dispatchMapPage({
                  type: 'setSelectedSatelliteDataSource',
                  payload: v,
                });
              }}
            >
              {dataSourcesOptions.map((o) => o)}
            </Select>
          </div>

          <Button
            disabled={!selectedImageryData?.data}
            type="primary"
            icon={selectedImageryData && selectedImageryData.visible
              ? <EyeOutlined />
              : <EyeInvisibleOutlined />}
            ghost
            size="large"
            style={{
              border: 'none',
              boxShadow: 'none',
            }}
            onClick={() => {
              const imgData = cloneDeep(selectedImageryData);
              dispatchMapPage({
                type: 'setSelectedImageryData',
                payload: {
                  ...imgData,
                  visible: !imgData.visible,
                },
              });
            }}
          />
        </div>
        )}
      </Layout>
    </>
  );
};

SatelliteImagery.propTypes = {
  dispatchMapPage: PropTypes.func.isRequired,
  satelliteDates: PropTypes.shape({
    availableAerialTypes: PropTypes.array,
    availableDataSources: PropTypes.array,
    availableSatelliteTypes: PropTypes.array,
    datesArray: PropTypes.array,
    datesObj: PropTypes.object,
    id: PropTypes.number,
    type: PropTypes.string,
    uniqueDataTypes: PropTypes.object,
  }),
  selectedSatelliteDate: PropTypes.instanceOf(moment),
  selectedSatelliteDataType: PropTypes.string,
  selectedSatelliteDataSource: PropTypes.string,
  selectedSatelliteDatesFiltered: PropTypes.shape({
    datesArray: PropTypes.array,
    datesObj: PropTypes.object,
    id: PropTypes.number,
    type: PropTypes.string,
  }),
  dateRangeWithDisplayData: PropTypes.arrayOf(
    PropTypes.object,
  ),
  selectedImageryData: PropTypes.shape({
    visible: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
  isMobile: PropTypes.bool,
  childComponent: PropTypes.func,
};

SatelliteImagery.defaultProps = {
  satelliteDates: undefined,
  selectedSatelliteDate: undefined,
  selectedSatelliteDataType: undefined,
  selectedSatelliteDataSource: undefined,
  selectedSatelliteDatesFiltered: undefined,
  dateRangeWithDisplayData: undefined,
  isMobile: true,
  childComponent: null,
};
