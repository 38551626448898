import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { selectors, hooks } from 'farmx-redux-core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { Link } from 'react-router-dom';
import { SoilSensorSummary } from './ListCards/SoilSensorSummary';
import { PageHeader } from '../components/PageHeader';
import { SoilSensorListItem } from './SoilSensorListItem';
import { installState } from '../../../constants';
import { RanchBlockSelect } from './RanchBlockSelect';
import { getLoadingStatusForListOfSensors } from '../../../helper/listHelper';
import { isMobile } from '../../../utils/detectDevice';

const {
  selectAllSensorsForBlockIdsByType,
} = selectors;

export function SoilSensorList(props) {
  const { history } = props;
  const [selectedObj, setSelectedObj] = useState({});
  const { t } = useTranslation();
  const state = useSelector((store) => store);
  const sensorStatus = state.sensorsData.status.entities;
  const [loading, setLoading] = useState(false);
  const [selectedMetrics, setSelectedMetrics] = useState('vwc');
  const changeMetricSelection = useCallback((e) => setSelectedMetrics(e.target.value), []);
  const displayOptions = [
    { label: `% ${t('VWC')}`, value: 'vwc' },
    { label: `% ${t('Available')}`, value: 'percent' },
    { label: t('Volume'), value: 'volume' },
  ];
  const { blockIds } = hooks.useRanchBlockSelection();
  const sensors = selectAllSensorsForBlockIdsByType(state, {
    blockIds,
    installState: installState.INSTALLED,
  });
  const list = [
    ...(sensors.aquacheck_soil || []),
    ...(sensors.pixl_soil || []),
  ];


  useEffect(() => {
    if (history && history.location && history.location.selectedObj) {
      setSelectedObj(history.location.selectedObj);
    }
  }, [history]);

  useEffect(() => {
    setLoading(getLoadingStatusForListOfSensors(list, sensorStatus));
  }, [list, sensorStatus]);

  const showList = Boolean(!loading && list.length);

  return (
    <div className="div-default soil-sensors-wrapper padding-10 page-content device-summary-list">
      <div className="desktop-container">
        <PageHeader
          showBack
          title={t('Soil Sensors')}
          toPath="/list"
        />
        {isMobile && (
        <RanchBlockSelect
          selectedObj={selectedObj}
          setSelectedObj={setSelectedObj}
        />
        )}
        <div className="mobile-radio-toggle">
          <Radio.Group
            buttonStyle="solid"
            onChange={changeMetricSelection}
            options={displayOptions}
            optionType="button"
            size="large"
            value={selectedMetrics}
          />
        </div>
        <div className="sensor-list-inner">
          <div className="list-container">
            <SoilSensorSummary
              selectedMetrics={selectedMetrics}
              list={list}
            />
            {showList && list.map((item) => (
              <Link key={item.id} to={`/list/soil-sensor/${item.type}/${item.identifier}`}>
                <SoilSensorListItem
                  key={item.id}
                  details={item}
                  status={sensorStatus[`${item.type}/${item.identifier}`]}
                  selectedMetrics={selectedMetrics}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

SoilSensorList.defaultProps = {
  history: {},
};

SoilSensorList.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    location: PropTypes.shape({
      selectedObj: PropTypes.object,
    }),
  }),
};
