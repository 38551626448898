export const initReducerAnomaly = () => ({
  satelliteDates: undefined,
  selectedSatelliteDate: undefined,
  selectedSatelliteDataType: 'NDVI_Histogram_Equalization',
  selectedSatelliteDataSource: 'satellite',
  selectedSatelliteDatesFiltered: undefined,
  selectedSatelliteDatesDataRange: undefined,
  dateRangeWithDisplayData: undefined,
  selectedImageryData: {
    visible: true,
    data: undefined,
  },
  deleteAnomaly: { delete: false, save: false, cancel: false },
});

export function reducerAnomaly(state, action) {
  switch (action.type) {
    case 'setSelectedImageryData':
      return {
        ...state,
        selectedImageryData: action.payload,
      };
    case 'setDateRangeWithDisplayData':
      return {
        ...state,
        dateRangeWithDisplayData: action.payload,
      };
    case 'setSelectedSatelliteDatesDataRange':
      return {
        ...state,
        selectedSatelliteDatesDataRange: action.payload,
      };
    case 'setSelectedSatelliteDatesFiltered':
      return {
        ...state,
        selectedSatelliteDatesFiltered: action.payload,
      };
    case 'setSelectedSatelliteDataSource':
      return {
        ...state,
        selectedSatelliteDataSource: action.payload,
      };
    case 'setSelectedSatelliteDataType':
      return {
        ...state,
        selectedSatelliteDataType: action.payload,
      };
    case 'setSelectedSatelliteDate':
      return {
        ...state,
        selectedSatelliteDate: action.payload,
      };
    case 'setSatelliteDates':
      return {
        ...state,
        satelliteDates: action.payload,
      };
    case 'setDeleteAnomaly':
      return {
        ...state,
        deleteAnomaly: action.payload,
      };
    case 'reset':
      return initReducerAnomaly();
    default:
      throw new Error('Unkown action type');
  }
}
