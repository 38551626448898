import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { actions, selectors } from 'farmx-redux-core';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'react-fast-compare';
import {
  Button,
  Typography,
  notification,
  Switch,
} from 'antd';
import { Map as MapComponent } from 'farmx-web-ui';
import { FeatureGroup, TileLayer } from 'react-leaflet';
import { useTracking } from '../../../helper/mixpanel';
import { useBlocksGeoJSON, useAllRanchesGeoJSON } from '../map/mapHooks';
import { isMobile } from '../../../utils/detectDevice';
import { RecommendationDetail } from './RecommendationDetail';
import { getLayerBounds, deleteBlockAnomaly, softDeleteBlockAnomaly } from '../../../helper/mapHelper';
import { reDrawSatelliteImagery } from '../map/reDraw';

const mapBoxPadding = {
  top: isMobile ? 10 : 30,
  left: isMobile ? 10 : 30,
  bottom: isMobile ? 10 : 30,
  right: isMobile ? 10 : 130,
};

const {
  cleanSensorsFetchState,
  loadAndPrepareMapDataByRanchIds,
} = actions;

const {
  selectBlocksFeaturesByRanchId,
  selectRanchesFeatures,
  selectLoginUserInfo,
} = selectors;

export default function AnomalyMap(props) {
  const {
    blockId, showMap, ranchId, deleteAnomaly, selectedImageryData,
    selMapShowSoilType, anomalyData, onLatLngClick, mapFeatureGroupRef,
    layerOptionButton, isMapTab, setTab, setIsDisabled,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tracking = useTracking();
  const [toggleZoom, setToggleZoom] = useState(true);
  const userInfo = useSelector(selectLoginUserInfo).payload;
  const { coordinates } = anomalyData?.properties?.center || {};
  const isUserCreated = anomalyData && anomalyData.properties
    && anomalyData.properties.is_user_created;

  useEffect(() => {
    if (showMap) {
      dispatch(cleanSensorsFetchState());
      dispatch(loadAndPrepareMapDataByRanchIds([ranchId]));
    }
  }, [dispatch, ranchId, showMap]);

  const blocksGeoJSON = useBlocksGeoJSON(
    useSelector((state) => selectBlocksFeaturesByRanchId(state, ranchId), isEqual),
  );

  const ranchFeatures = useSelector((state) => selectRanchesFeatures(state));
  const [ranchGeoJSON] = useAllRanchesGeoJSON(
    ranchFeatures,
    ranchId,
  );

  const recenterGeoJSON = {
    type: 'FeatureCollection',
    features: blocksGeoJSON?.features?.filter((d) => (d?.id === Number(blockId))),
  };

  let anomalyGeoJSON = getLayerBounds(anomalyData);
  anomalyGeoJSON.customZoom = true;
  if (!toggleZoom) {
    tracking.track('Zoom Out Anomaly');
    const blockFeatures = blocksGeoJSON.features.filter((d) => d?.id === Number(blockId));
    const blockGEOJSON = {
      type: 'FeatureCollection',
      features: [...blockFeatures, anomalyGeoJSON],
    };
    anomalyGeoJSON = { ...anomalyGeoJSON, ...blockGEOJSON };
  }

  function renderGraphButtons() {
    return showMap ? (
      <div style={{ width: '50%' }}>
        <Button
          style={{ width: '60px', padding: '0' }}
          type={isMapTab ? 'primary' : 'default'}
          onClick={() => {
            setTab('map');
          }}
        >
          {t('Map')}
        </Button>

        <Button
          style={{ width: '60px', padding: '0' }}
          type={(!isMapTab) ? 'primary' : 'default'}
          onClick={() => {
            setTab('vwc');
          }}
        >
          {t('VWC')}
        </Button>
      </div>
    ) : null;
  }

  function showNotification(isOk) {
    if (isOk) {
      notification.success({
        message: t('Anomaly Deleted Successfully!'),
      });
    } else {
      notification.error({
        message: t('Failed To Delete Anomaly'),
      });
    }
  }

  function softDeleteAnomaly(data) {
    if (data) {
      const coordinatesToDelete = data.map((d) => d?.feature?.geometry?.coordinates);
      const deleteObj = {
        bounds: {
          type: 'MultiPolygon',
          coordinates: coordinatesToDelete,
        },
        block: Number(blockId),
        created_by: userInfo && userInfo.user_id,
      };

      const anomalyId = anomalyData && anomalyData.properties.id;
      if (isUserCreated) {
        deleteBlockAnomaly(anomalyId, (isOk) => {
          showNotification(isOk);
        });
      } else {
        softDeleteBlockAnomaly(deleteObj, (isOk) => {
          showNotification(isOk);
        });
      }
    }
  }

  useEffect(() => {
    if (showMap) {
      reDrawSatelliteImagery({
        mapFeatureGroupRef,
        selectedImageryData,
        selectedImageryDataOpacity: 90,
      });
    }
  });

  return (
    <>
      {isMapTab && (
        <>
          <div className="recommendation-detail-card2-container">
            <div className="recommendation-detail-card2-top">{renderGraphButtons()}</div>
            <div className="recommendation-detail-card2-top">
              <div className="recommendation-detail-card2-deficit">
                <Typography.Text>{`${t('Anomaly')}:`}</Typography.Text>
              </div>
              <Button
                type="primary"
                onClick={onLatLngClick}
                id={JSON.stringify({ lat: coordinates[1], lng: coordinates[0] })}
                className="lan-long-align"
              >
                {`lat: ${coordinates[1].toFixed(3)}  long: ${coordinates[0].toFixed(3)}`}
              </Button>
              <Switch
                style={{
                  width: '100px',
                  minWidth: '57px',
                }}
                checkedChildren={t('Zoomed')}
                unCheckedChildren={t('Zoom')}
                data-testid="anomaly-map-zoom-control"
                checked={toggleZoom}
                onChange={setToggleZoom}
              />
            </div>
            <div className="controls-box">
              <div className="controls-right anomaly-detail-layer">
                {layerOptionButton()}
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: '#aaa', height: '200px' }}>
            <MapComponent
              ranchesGeoJSON={ranchGeoJSON}
              recenterGeoJSON={recenterGeoJSON?.length ? recenterGeoJSON : undefined}
              blocksGeoJSON={blocksGeoJSON}
              isShowingImagery={selectedImageryData}
              mapBoxPadding={mapBoxPadding}
              presentationMode="anomaly"
              anomalyGeoJSON={anomalyGeoJSON}
              deleteAnomaly={deleteAnomaly}
              onSelectAnomaly={(d) => {
                setIsDisabled(d.enable);
                softDeleteAnomaly(d?.data);
              }}
            >
              <FeatureGroup
                ref={(r) => {
                  if (r && !mapFeatureGroupRef.current) {
                    mapFeatureGroupRef.current = r;
                  }
                }}
              >
                {selMapShowSoilType && (
                  <TileLayer
                    url="https://map.farmx.co/api/soil/wms/?x={x}&y={y}&z={z}"
                    name="soil"
                    maxZoom={19}
                    minZoom={0}
                    showOnSelector={false}
                    zIndex={1}
                  />
                )}
              </FeatureGroup>
            </MapComponent>
          </div>
        </>
      )}
      {!isMapTab && (
        <>
          <div className="recommendation-detail-card2-top map-anomaly-graph-buttons">
            {renderGraphButtons()}
          </div>
          <RecommendationDetail
            showMap
            propBlockId={blockId}
            onClick={(d) => setTab(d)}
            showGraphButtons={showMap}
          />
        </>
      )}
    </>
  );
}

AnomalyMap.propTypes = {
  blockId: PropTypes.number,
  ranchId: PropTypes.number,
  showMap: PropTypes.bool,
  deleteAnomaly: PropTypes.bool,
  selectedImageryData: PropTypes.shape({}),
  selMapShowSoilType: PropTypes.string,
  anomalyData: PropTypes.shape({
    properties: PropTypes.shape({
      is_user_created: PropTypes.bool,
      id: PropTypes.number,
    }),
  }),
  onLatLngClick: PropTypes.func,
  layerOptionButton: PropTypes.func,
  setTab: PropTypes.func,
  setIsDisabled: PropTypes.func,
  isMapTab: PropTypes.bool,
  mapFeatureGroupRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
};

AnomalyMap.defaultProps = {
  blockId: null,
  ranchId: null,
  showMap: true,
  deleteAnomaly: false,
  selectedImageryData: null,
  selMapShowSoilType: null,
  anomalyData: null,
  onLatLngClick: null,
  layerOptionButton: null,
  setTab: null,
  isMapTab: false,
  setIsDisabled: false,
  mapFeatureGroupRef: {},
};
