/* eslint-disable camelcase */
import React, {
  useState, useEffect, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Typography,
} from 'antd';
import { FaLayerGroup, FaTrash } from 'react-icons/fa';
import { actions, selectors, hooks } from 'farmx-redux-core';
import { getAnomalyIcon } from 'farmx-web-ui';
import isEqual from 'react-fast-compare';
import { PageHeader } from '../components/PageHeader';
import { StatusTag } from '../components/StatusTag';
import { CardLayout } from '../components/CardLayout';
import { useTracking } from '../../../helper/mixpanel';
import './recommendation-detail.css';
import RanchBlockTitle from '../components/RanchBlockTitle';
import { getLayerBounds } from '../../../helper/mapHelper';
import { isMobile } from '../../../utils/detectDevice';
import { reducerAnomaly, initReducerAnomaly } from './reducerAnomaly';
import {
  useSatelliteImagery,
  useSatelliteDataDatesAndTypes,
  useSatelliteDateRangePreparation,
  useDefaultSelectedImageryData,
  useRequestSatelliteData,
} from '../../../helper/satelliteHooks';
import { AnomalyAction } from './AnomalyAction';
import { getStateForAnomalySeverity, useAnomalyDataForRecommendation } from '../../../helper/anomalyHelper';
import { getHumanReadableAnomalyType } from '../../../helper/common';
import { VWCAndPressureDetails } from './VWCAndPressureDetails';
import { AnomalyDetails } from '../map/Anomaly/AnomalyDetails';
import AnomalyMap from './AnomalyMap';
import AnomalyModal from './AnomalyModal';
import AnomalyModalButtons from './AnomalyModalButtons';
import { getActionText } from './recommendation';

const {
  cleanSensorsFetchState,
  loadAndPrepareMapDataByRanchIds,
  loadAnomaliesForBlock,
} = actions;

const {
  selectBlockById,
  selectMapShowSoilType,
  selectRecommendationForBlock,
  selectAnomaliesForBlock,
} = selectors;

export function ImageryAnomalyDetailPage({
  propAnomaly, propBlockId, showMap, showActionButton,
}) {
  const { t } = useTranslation();
  const tracking = useTracking();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { identifier } = useParams();
  const { useBlockNames, useRanchNamesForBlocks } = hooks;

  const blockId = parseInt(identifier, 10);
  const { selectedTab, anomaly } = location.state ?? {};
  const blockName = useBlockNames([Number(blockId) || propBlockId]);
  const ranchName = useRanchNamesForBlocks([Number(blockId) || propBlockId]);
  const blockObj = useSelector((state) => selectBlockById(state, blockId || propBlockId));
  const recommendations = useSelector((state) => selectRecommendationForBlock(state,
    blockId || propBlockId))
    ?.filter((d) => d?.type === 'imagery_anomaly');

  let propAnomalyWithRecommendation = [];
  if (!anomaly) {
    propAnomalyWithRecommendation = recommendations
      .filter((a) => a?.block === (blockId || propBlockId))
      .map((a) => ({ ...a, anomaly: propAnomaly }));
  }

  const [modal, setModal] = useState({});
  const [latLngText, setLatLng] = useState(null);
  const [tab, setTab] = useState(showMap ? 'map' : 'vwc');
  const isMapTab = tab === 'map';

  const ranchId = blockObj?.ranch;
  const anomalyForBlock = useSelector((state) => selectAnomaliesForBlock(state,
    blockId || propBlockId));
  const anomalyIds = recommendations.map((d) => d?.imageryAnomaly);
  const filteredAnomaly = anomalyForBlock.filter((d) => anomalyIds.includes(d?.properties?.id));

  useEffect(() => {
    if (!anomaly) {
      dispatch(loadAnomaliesForBlock(blockId));
    }
  }, [anomaly, blockId, dispatch]);

  const {
    anomalyData,
    anomalyDataForRender, recommendation,
  } = useAnomalyDataForRecommendation(anomaly || propAnomalyWithRecommendation,
    recommendations, anomalyForBlock, propAnomaly);

  const blkId = anomalyData?.properties?.block;
  const { coordinates } = anomalyData?.properties?.center || {};

  const selMapShowSoilType = useSelector(
    (state) => selectMapShowSoilType(state), isEqual,
  );

  // use local reducer to handle state of the component
  const [stateAnomaly, anomalyDispatch] = useReducer(
    reducerAnomaly,
    initReducerAnomaly(),
    initReducerAnomaly,
  );

  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (showMap) {
      dispatch(cleanSensorsFetchState());
      dispatch(loadAndPrepareMapDataByRanchIds([ranchId]));
    }
  }, [dispatch, ranchId, showMap]);

  // get satellite imagery using local reducer
  useSatelliteImagery(
    ranchId,
    blockId || propBlockId,
    stateAnomaly.latestReload,
    true,
    anomalyDispatch,
  );

  // prepare satellite data dates and types using local reducer
  useSatelliteDataDatesAndTypes(
    stateAnomaly.satelliteDates,
    stateAnomaly.selectedSatelliteDataType,
    stateAnomaly.selectedSatelliteDataSource,
    anomalyDispatch,
  );

  // SatelliteDateRange preparation using local reducer
  useSatelliteDateRangePreparation(
    stateAnomaly.selectedSatelliteDatesFiltered,
    stateAnomaly.selectedSatelliteDate,
    stateAnomaly.selectedSatelliteDatesDataRange,
    stateAnomaly.selectedSatelliteDataType,
    stateAnomaly.selectedSatelliteDataSource,
    ranchId,
    blockId || propBlockId,
    anomalyDispatch,
  );

  // To maintain the selected imagery data on the map
  useDefaultSelectedImageryData(
    stateAnomaly.dateRangeWithDisplayData,
    stateAnomaly.selectedImageryData,
    stateAnomaly.selectedSatelliteDate,
    anomalyDispatch,
  );

  // request satellite imagery data
  useRequestSatelliteData(stateAnomaly.selectedSatelliteDatesDataRange, true, anomalyDispatch);

  useEffect(() => {
    if (blockId || propBlockId) {
      tracking.track('Zoomed Anomaly');
      tracking.track('Anomaly Detail Page Loaded', { blockId: (blockId || propBlockId), anomaly });
    }
  }, [tracking, blockId, anomaly, propBlockId]);

  // TODO: loading
  if (!anomaly && !propAnomaly && !filteredAnomaly) {
    history.push({ pathname: '/recommendation' });

    return (
      <div>{t('Anomaly not found')}</div>
    );
  }

  const layerOptionButton = () => (
    <>
      <Button
        size="large"
        type="primary"
        icon={<FaLayerGroup />}
        className="recommendation-map-button"
        onClick={() => setModal({ visible: true, type: 'layer' })}
      />
      <Button
        size="large"
        type="primary"
        icon={<FaTrash />}
        className="recommendation-map-button custom-button-margin"
        onClick={() => {
          anomalyDispatch({
            type: 'setDeleteAnomaly',
            payload: { delete: !stateAnomaly.deleteAnomaly.delete, save: false, cancel: false },
          });
        }}
      />
    </>
  );

  function onLatLngClick(e) {
    setModal({ visible: true, type: 'action' });
    setLatLng(e.currentTarget.id);
  }

  const ranchBlockName = `${ranchName[blockId]} > ${blockName[blockId]}`;

  return (
    <div className={`page-content ${showMap ? 'page-container' : ''} margin-10 recommendation-container`}>
      <div className={!isMobile ? 'anomaly-details-container'
        : 'anomaly-details-mobile-container anomaly-details-margin'}
      >
        {showMap && (
          <PageHeader
            showBack
            toPath="/recommendation"
            title={<RanchBlockTitle title={ranchBlockName} />}
            state={{ selectedTab }}
          />
        )}
        <CardLayout key="1">
          <div className="recommendation-detail-card1-container">
            {anomalyDataForRender?.length ? anomalyDataForRender.map((d, index) => (
              <div style={{ width: '100%' }}>
                <StatusTag
                  width="100%"
                  text={`${t(getHumanReadableAnomalyType(d.properties.anomaly_type || d.properties.anomalyType
                    || propAnomaly?.properties?.anomaly_type))}`}
                  state={
                    getStateForAnomalySeverity((d.properties && (d.properties.anomaly_severity
                      || d.properties.state)) || propAnomaly?.properties?.anomaly_severity)
                  }
                  tagStyle={{ fontSize: '16px', padding: '4px', marginBottom: '10px' }}
                  icon={getAnomalyIcon(d.properties.anomaly_type
                    || propAnomaly?.properties?.anomaly_type)}
                />
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <Typography.Text>
                    {`${recommendation?.description
                      || d?.properties?.description || propAnomaly?.properties?.description || ''}`}
                  </Typography.Text>
                </div>
              </div>
            )) : null}
          </div>
        </CardLayout>
        <CardLayout key="2">
          <AnomalyMap
            blockId={blkId}
            ranchId={ranchId}
            deleteAnomaly={stateAnomaly.deleteAnomaly}
            selectedImageryData={stateAnomaly.selectedImageryData}
            anomalyData={anomalyData}
            anomalyGeoJSON={getLayerBounds(anomalyData)}
            selMapShowSoilType={selMapShowSoilType}
            onLatLngClick={onLatLngClick}
            layerOptionButton={layerOptionButton}
            isMapTab={isMapTab}
            setTab={setTab}
            setIsDisabled={setIsDisabled}
          />
          {stateAnomaly.deleteAnomaly.delete
            && (
              <AnomalyModalButtons
                btnText="Delete"
                isDelete={stateAnomaly.deleteAnomaly.delete}
                anomalyDispatch={anomalyDispatch}
                setModal={setModal}
                modal={modal}
                deleteAnomaly={stateAnomaly.deleteAnomaly}
                isDisabled={isDisabled}
              />
            )}
        </CardLayout>
        {!isMapTab
          && (
            <div className="home-page-pressure-sensor-details">
              <VWCAndPressureDetails blockIds={[Number(blockId)]} />
            </div>
          )}
        <AnomalyModal
          modal={modal}
          setModal={setModal}
          deleteAnomaly={stateAnomaly.deleteAnomaly}
          selectedImageryData={stateAnomaly.selectedImageryData}
          selMapShowSoilType={selMapShowSoilType}
          anomalyData={anomalyData}
          anomalyDispatch={anomalyDispatch}
          isDisabled={isDisabled}
          latLngText={latLngText}
          showMap={showMap}
        />
        {!showMap && (
          <>
            <CardLayout key="3">
              <Button
                type="text"
                onClick={onLatLngClick}
                id={JSON.stringify({ lat: coordinates[1], lng: coordinates[0] })}
                data-testid="anomaly-map-lat-lng-section"
              >
                {`lat: ${coordinates[1].toFixed(3)}, long: ${coordinates[0].toFixed(3)}`}
              </Button>
            </CardLayout>
          </>
        )}
        <CardLayout key="4">
          <Typography.Title level={3}>{`${t('Comments')}`}</Typography.Title>
          <AnomalyDetails anomalyId={anomalyData?.properties?.id} />
        </CardLayout>
        <div className="action-footer error-report-button">
          <Button
            size="large"
            type="link"
            block
            onClick={() => setModal({ visible: true, type: 'report' })}
          >
            {t('Notice an issue? Click here to report.')}
          </Button>
        </div>
      </div>
      {showActionButton ? (
        <AnomalyAction
          blockId={blockId}
          anomaly={anomaly}
          actionText={recommendation?.action}
        />
      ) : ''}

      <div className="action-footer recommendation-detail-actions">
        <Button
          size="large"
          type="primary"
          block
          onClick={() => {
            if (recommendation?.length && recommendation[0]) {
              const { block } = recommendation[0];
              history.push({
                pathname: `/recommendation/anomaly/investigate/${block}`,
              });
              tracking.track('Clicked anomaly action', anomalyData);
            }
          }}
        >
          {t(getActionText(recommendation?.length && recommendation[0].action))}
        </Button>
      </div>
    </div>
  );
}

ImageryAnomalyDetailPage.propTypes = {
  propAnomaly: PropTypes.shape(),
  propBlockId: PropTypes.number,
  showMap: PropTypes.bool,
  showActionButton: PropTypes.bool,
};

ImageryAnomalyDetailPage.defaultProps = {
  propAnomaly: null,
  propBlockId: null,
  showMap: true,
  showActionButton: true,
};
