import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { hooks } from 'farmx-redux-core';
import {
  Typography,
  Card,
} from 'antd';
import './IrrigationEquation.less';

export function IrrigationEquation({
  deficit, etcForecast, irrigationDuration, units,
}) {
  const { t } = useTranslation();
  const getUserUnits = hooks.useUnits();

  function parseDuration(duration) {
    if (duration === undefined || duration === null) {
      return t('?');
    }
    const hours = moment.duration(duration).asHours();
    if (Math.abs(hours) < 10) {
      return Math.round(hours * 10) / 10;
    }
    return Math.round(hours);
  }

  function parseWater(water, fromUnit) {
    if (water === undefined || water === null) {
      return t('?');
    }
    const { value: depthValue } = getUserUnits(water, fromUnit, 'depth', { decimalPlaces: 2 });
    if (Math.abs(depthValue) < 10) {
      return Math.round(depthValue * 100) / 100;
    }
    return Math.round(depthValue);
  }

  function getStatusColorClassName(value) {
    if (value === null || value === undefined) {
      return 'text-color-normal';
    }
    if (value < 0) {
      return 'text-color-over';
    }
    if (value > 0) {
      return 'text-color-critical';
    }
    return 'text-color-normal';
  }

  const { label: depthLabel } = getUserUnits(0, units.deficit, 'depth');
  const waterUnits = t(depthLabel);

  const durationString = parseDuration(irrigationDuration);
  const etcForecastString = parseWater(etcForecast, units.etcForecast);
  const deficitString = parseWater(deficit, units.deficit);

  const deficitStringClassName = getStatusColorClassName(deficit);

  return (
    <div className="irrigation-equation-card-container">
      <Card
        className="mobile-list-item irrigation-equation-container
        irrigation-equation-card-style width-100"
        bodyStyle={{ padding: '2px', width: '100%' }}
        bordered
      >
        <div
          className="flex-column height-100 width-100"
        >
          <div
            className="flex-row align-items-center justify-content-space-evenly height-100
            irrigation-equation-label-container"
          >
            <div
              className="flex-column align-items-center height-100 irrigation-equation-align-item"
            >
              <div
                className="flex-row align-items-center height-100"
              >
                <Typography.Text
                  className={`${deficitStringClassName} irrigation-label-value`}
                >
                  {deficitString}
                </Typography.Text>
                <div className="irrigation-label-container-unit">
                  <div className="irrigation-label-subContainer-unit">
                    <Typography.Text className="irrigation-label-unit">
                      {waterUnits}
                    </Typography.Text>
                  </div>
                </div>
              </div>

              <div className="deficit-label">
                <Typography.Text
                  className="deficit-label-text"
                >
                  {t('deficit')}
                </Typography.Text>
              </div>
            </div>

            <div><Typography.Text className="irrigation-label-unit-plus">+</Typography.Text></div>

            <div
              className="flex-column align-items-center height-100 irrigation-equation-align-item"
            >
              <div
                className="flex-row align-items-center height-100"
              >
                <Typography.Text className="irrigation-label-value ">
                  {etcForecastString}
                </Typography.Text>
                <div className="irrigation-label-container-unit">
                  <div className="irrigation-label-subContainer-unit">
                    <Typography.Text className="irrigation-label-unit">
                      {waterUnits}
                    </Typography.Text>
                  </div>
                </div>
              </div>

              <div className="etc-label">
                <Typography.Text
                  className="etc-label-text"
                >
                  {t('ETc')}
                </Typography.Text>
              </div>
            </div>

            <div className="irrigation-label-unit-equals">=</div>

            <div
              className="flex-column align-items-center height-100 irrigation-equation-align-item"
            >
              <div
                className="flex-row align-items-center height-100"
              >
                <Typography.Text className="irrigation-label-value">
                  {durationString}
                </Typography.Text>
                <div className="irrigation-label-container-unit">
                  <div className="irrigation-label-subContainer-unit">
                    <Typography.Text className="irrigation-label-unit">
                      {t('hours')}
                    </Typography.Text>
                  </div>
                </div>
              </div>

              <div className="irrigation-label ">
                <Typography.Text
                  className="irrigation-label-text"
                >
                  {t('irrigation')}
                </Typography.Text>
              </div>
            </div>

          </div>
          {irrigationDuration === null && (
          <div className="equation-message">
            {t('*Add information about your irrigation system to get irrigation duration recommendations.')}
          </div>
          )}
        </div>
      </Card>
    </div>
  );
}

IrrigationEquation.defaultProps = {
  units: {
    deficit: 'millimeters',
    etcForecast: 'millimeters',
    totalWater: 'millimeters',
  },
};

IrrigationEquation.propTypes = {
  deficit: PropTypes.number.isRequired,
  etcForecast: PropTypes.number.isRequired,
  irrigationDuration: PropTypes.string.isRequired,
  units: PropTypes.shape({
    deficit: PropTypes.string,
    etcForecast: PropTypes.string,
    totalWater: PropTypes.string,
  }),
};
