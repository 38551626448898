import React from 'react';
import PropTypes from 'prop-types';
import { selectors, hooks } from 'farmx-redux-core';
import { useSelector } from 'react-redux';
import { SensorListItem } from '../../list/SensorListItem';
import { isNotNull } from '../../../../helper/listHelper';

const { useUnits } = hooks;

export function PressureSensorItem(props) {
  const { sensorDetail } = props;
  const getUserUnits = useUnits();
  const { name, type, identifier } = sensorDetail || {};
  const sensorStatus = useSelector((state) => (
    selectors.selectSensorStatus(state, type, identifier)
  )) || {};
  const { loading, visible } = sensorStatus;
  const units = sensorStatus.units || {
    waterPressure: 'psi',
  };
  const convertedUnits = getUserUnits(sensorStatus.waterPressure || sensorDetail.waterPressure,
    units.waterPressure, 'pressure');
  const content = isNotNull(convertedUnits.value) ? `${convertedUnits.value} ${convertedUnits.label}` : '';
  const showData = visible !== false && !loading;
  const date = sensorStatus.online ? sensorStatus.lastUpdated : sensorStatus.latestDate;

  return showData ? (
    <SensorListItem
      alarmCount={sensorStatus.alarmCount}
      content={content}
      date={date}
      icon="pipe-valve"
      online={sensorStatus.online}
      title={name}
      identifier={identifier}
      type={type}
    />
  ) : null;
}

PressureSensorItem.defaultProps = {
  sensorDetail: {
    units: {
      waterPressure: 'psi',
    },
  },
};

PressureSensorItem.propTypes = {
  sensorDetail: PropTypes.shape({
    alarmCount: PropTypes.number,
    latestDate: PropTypes.string,
    name: PropTypes.string,
    online: PropTypes.bool,
    waterPressure: PropTypes.string,
    units: PropTypes.object,
    identifier: PropTypes.string,
    type: PropTypes.string,
  }),
};
