import React from 'react';
import PropTypes from 'prop-types';
import { actions } from 'farmx-redux-core';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { RWebShare } from 'react-web-share';
import { useTracking } from '../../../helper/mixpanel';
import { getActionText } from './recommendation';

const { updateRecommendation } = actions;

export function AnomalyAction(props) {
  const {
    onClick, blockId, anomaly, actionText,
  } = props;
  const { t } = useTranslation();
  const tracking = useTracking();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const showActionBtn = actionText && actionText !== 'no_action';
  const isFrozen = anomaly?.[0]?.isFrozen;

  const { selectedTab } = location.state ?? {};
  function customOnClick(type) {
    if (type === 'investigate') {
      if (onClick) onClick();
      history.push({
        pathname: `/recommendation/anomaly/investigate/${blockId}`,
        state: {
          from: location.pathname,
          selectedTab,
          anomaly,
        },
      });
      if (tracking) tracking.track('Clicked Investigate Button');
    } else if (type === 'dismiss') {
      dispatch(updateRecommendation({
        id: anomaly?.[0]?.id,
        type: 'imagery',
        data: {
          isFrozen: true,
          dateFrozen: moment().toISOString(),
          freezeType: 'dismiss',
        },
      }));
      if (!location?.pathname?.includes('map')) {
        history.push({
          pathname: '/recommendation',
          state: { selectedTab },
        });
      }
      if (tracking) tracking.track('Dismissed Anomaly Detail', anomaly?.[0]);
    } else if (isFrozen && type === 'undo_dismiss') {
      dispatch(updateRecommendation({
        id: anomaly?.[0]?.id,
        type: 'imagery',
        data: {
          isFrozen: false,
          dateFrozen: moment().toISOString(),
        },
      }));
      if (!location?.pathname?.includes('map')) {
        history.push({
          pathname: '/recommendation',
          state: { selectedTab },
        });
      }
      if (tracking) tracking.track('Clicked Undo Dismissed Anomaly Detail', anomaly?.[0]);
    }
  }

  const shareUrl = window.location.href;

  return (
    <div className="action-footer recommendation-detail-actions
     recommendation-anomaly-action-buttons anomaly-action-button scheduling-investigation-button"
    >
      {showActionBtn ? (
        <Button
          size="large"
          block
          type="primary"
          onClick={() => customOnClick('investigate')}
        >
          {t(getActionText(actionText))}
        </Button>
      ) : null }

      <RWebShare
        className="schedule-share"
        data={{
          text: anomaly?.description,
          url: shareUrl,
          title: t('Imagery Anomaly'),
        }}
        onClick={() => {
          if (tracking) tracking.track('Shared Imagery Anomaly');
        }}
      >
        <Button
          size="large"
          block
          data-testid="share-button"
        >
          {t('Share')}
        </Button>
      </RWebShare>

      <Button
        size="large"
        block
        onClick={() => customOnClick(isFrozen ? 'undo_dismiss' : 'dismiss')}
        data-testid="dismiss-button"
      >
        {isFrozen ? t('Undo Dismiss') : t('Dismiss')}
      </Button>
    </div>
  );
}

AnomalyAction.propTypes = {
  onClick: PropTypes.func,
  blockId: PropTypes.number,
  anomaly: PropTypes.shape({}),
  actionText: PropTypes.string,
};

AnomalyAction.defaultProps = {
  onClick: null,
  blockId: null,
  anomaly: null,
  actionText: null,
};
