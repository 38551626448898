import React from 'react';
import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { actions, selectors, hooks } from 'farmx-redux-core';
import { useSelector, useDispatch } from 'react-redux';
import { compareByName } from '../../helper/common';
import './RanchBlockBreadcrumb.less';

const {
  setRanch, setBlocks,
} = actions;

const {
  selectAllBlocks, selectAllRanches,
} = selectors;

const {
  useRanchBlockSelection, useBlockNames, useRanchNamesForBlocks, useRanchNames,
} = hooks;

export default function RanchBlockBreadcrumb() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { selectedObjFromState, blockArray } = useRanchBlockSelection();
  const ranchId = selectedObjFromState?.type === 'ranch'
    ? Number(selectedObjFromState?.value) : blockArray?.[0]?.ranchId;
  const blockId = selectedObjFromState?.type === 'block'
    ? Number(selectedObjFromState?.value) : undefined;
  const ranchName = useRanchNames([ranchId]);
  const blockName = useBlockNames([blockId]);
  const ranchNameForBlock = useRanchNamesForBlocks([Number(blockId)]);
  const allRanchesData = useSelector(selectAllRanches)?.sort(compareByName);
  const allBlocksData = useSelector(selectAllBlocks)?.sort(compareByName);
  const isNotAllRanches = selectedObjFromState?.type !== 'All Ranches';

  function onMenuItemClick(targetData, blockMode) {
    const parsedData = JSON.parse(targetData);
    const { id } = parsedData || {};

    if (blockMode) {
      dispatch(setBlocks([id]));
    } else dispatch(setRanch(id));
  }

  function generateOptions(data, isBlockMode) {
    const filteredData = isBlockMode ? [...data].filter((d) => d?.ranch?.id === ranchId) : data;
    return filteredData?.map((d) => ({
      key: JSON.stringify({ id: d?.id, name: d?.name }),
      label: (
        <div className="breadcrumb-select-container add-color-to-even-rows">
          <div>
            <span className="left-align left-options">{d.name}</span>
            {isBlockMode ? (
              <span className="right-align right-options">
                {d.ranch && d.ranch.name}
              </span>
            )
              : <span className="right-align right-options">{d.entity && d.entity.name}</span>}
          </div>
        </div>
      ),
      onClick: (x) => {
        onMenuItemClick(x?.key, isBlockMode);
      },
    }));
  }

  return isNotAllRanches ? (
    <div className="breadcrumb-select-container">
      <Breadcrumb className="breadcrumb-select">
        <Breadcrumb.Item
          menu={{ items: generateOptions(allRanchesData, false) }}
        >
          {ranchName[ranchId] || ranchNameForBlock[blockId]}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          menu={{ items: generateOptions(allBlocksData, true) }}
        >
          {blockName[blockId] || t('Select Block')}
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  ) : null;
}
