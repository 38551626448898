import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { actions, selectors, hooks } from 'farmx-redux-core';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import {
  PageHeader,
  DatePicker,
  Radio,
  Checkbox,
  Select,
} from 'antd';
import {
  ArrowLeftOutlined, LoadingOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SensorDataChart from './SensorDataChart';
import './graph.less';
import {
  prepareGraphConfig, getChartHeader, groupSensors,
  filterSensorByType,
} from '../../helper/graphHelper';
import {
  colorOk,
} from '../../utils/colors';

const {
  loadAllSensors,
  loadSensorStatus,
} = actions;

const {
  selectSensorsForBlockIds,
  selectLoadingSensors,
  selectSoilSensorsForBlockIds,
} = selectors;

const { useRanchBlockSelection } = hooks;

const graphSizeOptions = [
  { value: 'sm', label: 'Small' },
  { value: 'md', label: 'Medium' },
  { value: 'lg', label: 'Large' },
];

export function MultiGraphPage(props) {
  const { showBack } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // To handle the more sensors graph
  const [graphObj, setGraphObj] = useState({ 0: [] });
  const [count, setCount] = useState(0);
  const [items, setItems] = useState(graphObj[count]);
  const [filteredItems, setFilteredItems] = useState(graphObj[count]);
  const [showPressureGraphs, setShowPressureGraphs] = useState(true);
  const [showSoilDepthGraph, setShowSoilDepthGraph] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const chunkSize = 6;

  const now = moment();
  const defaultDateRange = [now.startOf('day').clone().subtract(2, 'weeks'), now.endOf('day')];

  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [graphs, setGraphs] = useState({}); // To maintain the prepared graph data to render
  const [graphSize, setGraphSize] = useState('md'); // To maintain the height of the rendered graph
  const [soilOption, setSoilOption] = useState('soilMoisture');
  const [makeYAxisSame, setMakeYAxisSame] = useState(false);
  const [waterPressureMinMaxArr, setWaterPressureMinMaxArr] = useState([]);
  const [soilMinMaxArr, setSoilMinMaxArr] = useState([]);
  const yAxisMinAndMax = {
    soilMoisture: { min: Math.min(...soilMinMaxArr), max: Math.max(...soilMinMaxArr) },
    waterPressure: {
      min: Math.min(...waterPressureMinMaxArr),
      max: Math.max(...waterPressureMinMaxArr),
    },
  };

  const updateYAxisMinAndMax = (min, max, type) => {
    if (min === undefined || max === undefined) return;
    if (type === 'water_pressure') {
      setWaterPressureMinMaxArr((existingArr) => (
        [...existingArr, min, max]
      ));
    } else {
      setSoilMinMaxArr((existingArr) => (
        [...existingArr, min, max]
      ));
    }
  };

  /**
   * We want to disable soil graph option dropdown when charts are loading.
   * If we don't disable and user keeps on changing the the value,
   * wrong charts can be displayed.
   * chartLoadingMap stores values in following format
   * {
   *  [identifier]: true,
   *  ...
   * }
   */
  const [chartLoadingMap, setChartLoadingMap] = useState({});
  const updateChartLoadingMap = (option) => {
    setChartLoadingMap((prevOptions) => ({
      ...prevOptions,
      ...option,
    }));
  };
  const [chartLegend, setChartLegend] = useState({});
  const updateChartLegend = (identifier, key, value) => {
    const { color, isRootzone } = value;
    if (chartLegend[identifier]) {
      if (
        chartLegend[identifier][key]?.color === color
        && chartLegend[identifier][key]?.isRootzone === isRootzone
      ) return;
      setChartLegend({
        ...chartLegend,
        [identifier]: {
          ...chartLegend[identifier],
          [key]: { color, isRootzone },
        },
      });
    } else {
      setChartLegend({
        ...chartLegend,
        [identifier]: {
          [key]: { color, isRootzone },
        },
      });
    }
  };
  const disableSoilOptionDropdown = useMemo(() => {
    if (Object.keys(chartLoadingMap).length) {
      return Object.keys(chartLoadingMap).some((chartIdentifier) => (
        chartLoadingMap[chartIdentifier]
      ));
    }
    return false;
  }, [chartLoadingMap]);

  // This will be handled in local state in future
  const graphConfig = 'moisture_pressure';
  const soilGraphVariable = 'soil_moisture_rootzone_vwc';
  const pressureGraphVariable = 'water_pressure';
  const graphVariables = {
    moisture_pressure: {
      soilVariables: [soilGraphVariable],
      pressureVariables: [pressureGraphVariable],
    },
  };

  const { selectedObjFromState, blockIds } = useRanchBlockSelection();

  const { type, value } = selectedObjFromState || {};
  const ranchId = type === 'ranch' ? Number(value) : null;
  const blockId = type === 'block' ? Number(value) : null;

  const soilSensorsForBlockIds = useSelector((state) => selectSoilSensorsForBlockIds(state,
    blockIds));
  const pressureSensorsForBlockIds = useSelector((state) => selectSensorsForBlockIds(state,
    blockIds, 'water_pressure'));
  const loadingSensors = useSelector((state) => selectLoadingSensors(state));

  const groupedSensorsForBlocks = groupSensors(soilSensorsForBlockIds,
    pressureSensorsForBlockIds, blockIds);

  // Split array into small chunks and store to local state with key
  useEffect(() => {
    if (graphs[graphConfig]) {
      let i;
      const chunk = chunkSize;
      const obj = {};
      for (i = 0; i < graphs[graphConfig].length; i += 1) {
        const chunkIndex = Math.floor(i / chunk);
        if (!obj[chunkIndex]) {
          obj[chunkIndex] = []; // start a new chunk
        }
        obj[chunkIndex].push(graphs[graphConfig][i]);
      }
      setGraphObj(obj);
      setItems(obj[0]);
      if (graphs[graphConfig].length > chunkSize) {
        setHasMoreItems(true);
      }
    }
  }, [graphs]);

  const prepareConfig = useCallback((sensorData) => {
    let config = [];
    const variables = graphVariables[graphConfig] || {};
    if (blockIds.length) {
      config = prepareGraphConfig(graphConfig, sensorData, SensorDataChart,
        variables);
    }

    if (JSON.stringify(config) !== JSON.stringify(graphs)) {
      setGraphs(config);
    }
  }, [blockIds.length, graphVariables, graphs]);

  // To set the prepared graph config to local state
  useEffect(() => {
    if (!showPressureGraphs) return;

    prepareConfig(groupedSensorsForBlocks);
  }, [groupedSensorsForBlocks, prepareConfig, showPressureGraphs]);

  // To prepare and set only the soil graph config
  useEffect(() => {
    if (showPressureGraphs) return;

    const filteredSMAs = filterSensorByType(groupedSensorsForBlocks, 'water_pressure');
    prepareConfig(filteredSMAs);
  }, [groupedSensorsForBlocks, prepareConfig, showPressureGraphs]);

  useEffect(() => {
    dispatch(loadAllSensors());
  }, [dispatch]);

  // Clear graphs state when blockId/ranchId changes
  useEffect(() => {
    setGraphs([]);
  }, [blockId, ranchId]);

  function onSizeChange(e) {
    setGraphSize(e.target.value);
  }

  function renderSizeSelect() {
    return (
      <Radio.Group
        options={graphSizeOptions}
        onChange={onSizeChange}
        value={graphSize}
        optionType="button"
      />
    );
  }

  const togglePressureGraphs = () => {
    setShowPressureGraphs(!showPressureGraphs);
    setCount(0);
  };
  const toggleMakeYAxisScaleSame = () => {
    setMakeYAxisSame(!makeYAxisSame);
  };

  const toggleSoilDepthGraphs = () => {
    setShowSoilDepthGraph(!showSoilDepthGraph);
  };

  /**
   * Filter the list of graphs to be rendered.
   */
  useEffect(() => {
    if (showPressureGraphs) {
      setFilteredItems(items);
    } else {
      setFilteredItems(items?.filter((item) => item.variable !== 'water_pressure'));
    }
  }, [items, showPressureGraphs]);

  const sizeClass = `chart-${graphSize}`;

  const renderChartHeader = (variable) => {
    let title = '';
    if (variable === 'soil_moisture_rootzone_vwc' && soilOption === 'soilDepth') {
      title = 'Soil Moisture VWC';
    } else {
      title = getChartHeader(variable);
    }
    return (
      <span
        className="chart-title"
      >
        {title}
      </span>
    );
  };

  const renderChartLegend = (chartLeg) => {
    if (!chartLeg || !Object.keys(chartLeg).length) return null;
    const filteredLegend = Object.keys(chartLeg).map((chartLegKey) => {
      if (chartLegKey === 'soil_moisture_rootzone_vwc') return null;
      const chartLegLabel = chartLegKey.split('soil_moisture_')[1];
      if (!chartLegLabel) return null;
      return {
        chartLegLabel,
        chartLegKey,
      };
    });
    return filteredLegend
      .filter((item) => item !== null)
      .sort((a, b) => a.chartLegLabel.localeCompare(b.chartLegLabel, undefined, {
        numeric: true,
        sensitivity: 'base',
      }))
      .map(({ chartLegKey, chartLegLabel }) => (
        <span
          className="legend-pill"
          style={{
            backgroundColor: chartLeg[chartLegKey].color,
            border: `3px solid ${chartLeg[chartLegKey].isRootzone ? colorOk : 'grey'}`,
          }}
        >
          {`${chartLegLabel} in`}
        </span>
      ));
  };

  function renderChartContainer(graph) {
    const chartLeg = chartLegend[graph.identifier];
    const showIndividualDepthGraph = soilOption === 'soilDepth' && showSoilDepthGraph;
    const displayIndividualGraph = showIndividualDepthGraph && graph.sensor.type !== 'water_pressure';

    return (
      <div key={graph?.key} className={`chart-container ${sizeClass}`}>
        {!displayIndividualGraph && (
          <div className="chart-header-container">
            <div className="chart-header-form">
              <div className="chart-sensor">
                {graph?.name || graph?.identifier}
                {renderChartHeader(graph?.variable)}
                {renderChartLegend(chartLeg)}
              </div>
            </div>
          </div>
        )}
        <div className="chart-body">
          <graph.component
            startDate={dateRange?.length ? dateRange[0] : null}
            endDate={dateRange?.length ? dateRange[1] : null}
            variables={[graph.variable]}
            sensor={graph.sensor}
            soilChartOption={soilOption}
            updateChartLoadingMap={updateChartLoadingMap}
            updateChartLegend={updateChartLegend}
            showIndividualDepthGraph={soilOption === 'soilDepth' && showSoilDepthGraph}
            yAxisMinAndMax={yAxisMinAndMax}
            makeYAxisSame={makeYAxisSame}
            updateYAxisMinAndMax={updateYAxisMinAndMax}
          />
        </div>
      </div>
    );
  }

  const fetchMoreData = useCallback(() => {
    function handleItems(configArr) {
      const prevItems = items;
      const newItems = prevItems.concat(configArr);
      return newItems;
    }

    if (graphObj[count + 1]) {
      setHasMoreItems(true);
      setItems(handleItems(graphObj[count + 1]));
      setCount(count + 1);
    } else {
      setHasMoreItems(false);
    }
  }, [count, graphObj, items]);

  const onSoilChartChange = (e) => {
    setSoilOption(e);
    setChartLegend({});
    setSoilMinMaxArr([]);
  };

  useEffect(() => {
    if (filteredItems && filteredItems.length) {
      filteredItems.forEach((graphItem) => {
        if (graphItem && graphItem.sensor) {
          dispatch(loadSensorStatus({
            type: graphItem.sensor.type,
            identifier: graphItem.sensor.identifier,
          }));
        }
      });
    }
  }, [dispatch, filteredItems]);

  return (
    <div className="graph-page multi-graph-page" id="graph-scrollable-container">
      <Helmet>
        <title>Graph</title>
      </Helmet>
      <PageHeader
        title="Sensor Graph"
        onBack={() => history.goBack()}
        backIcon={
          showBack
            ? <ArrowLeftOutlined /> : false
        }
        extra={renderSizeSelect()}
      />
      <div className="page-body padded graph-row-gap">
        <div className="graph-date-range-picker">
          <DatePicker.RangePicker
            showTime
            onChange={(d) => setDateRange(d)}
            value={dateRange}
            format="YYYY-MM-DD HH:mm:ss"
            defaultValue={dateRange}
            onOk={null}
            style={{ width: '100%' }}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          />
        </div>
        <div>
          <Checkbox onChange={toggleMakeYAxisScaleSame} checked={makeYAxisSame}>
            {t('Uniform Y-Axis')}
          </Checkbox>
          <Checkbox onChange={togglePressureGraphs} checked={showPressureGraphs}>
            {t('Show Pressure Graph')}
          </Checkbox>
          {soilOption === 'soilDepth' && (
            <Checkbox onChange={toggleSoilDepthGraphs} checked={showSoilDepthGraph}>
              {t('Show Graph for individual soil depths')}
            </Checkbox>
          )}
          <Select
            options={[
              { value: 'soilMoisture', label: t('Rootzone Moisture') },
              { value: 'soilDepth', label: t('Moisture All Depths') },
            ]}
            onChange={onSoilChartChange}
            value={soilOption}
            disabled={disableSoilOptionDropdown}
          />
        </div>

        <InfiniteScroll
          initialLoad={false}
          loadMore={fetchMoreData}
          hasMore={hasMoreItems}
          loader={<div className="loader" key="0"> Loading... </div>}
          useWindow={false}
          getScrollParent={() => document.getElementById('graph-scrollable-container')}
        >
          {filteredItems ? (
            <div className="graph-list">
              {filteredItems?.map((graph) => renderChartContainer(graph))}
            </div>
          ) : null}
          {!loadingSensors?.loading && (!filteredItems?.length || !items?.length)
            ? (
              <div className="mobile-list-item no-config">
                {t('No Data')}
              </div>
            ) : null}
          {loadingSensors?.loading ? (
            <div className="mobile-list-item no-config">
              <LoadingOutlined />
            </div>
          ) : null}
        </InfiniteScroll>
      </div>
    </div>
  );
}

MultiGraphPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  showBack: PropTypes.bool,
};

MultiGraphPage.defaultProps = {
  history: {
    goBack: () => { },
    push: () => { },
  },
  showBack: false,
};
