import React, { useMemo } from 'react';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { hooks, selectors } from 'farmx-redux-core';
import { truncate } from 'lodash';
import ConnectivityStateTag from '../../components/ConnectivityStateTag';
import { IconTitle } from '../IconTitle';
import { ValueUnitInfo } from '../ValueUnitInfo';
import { getConnectivityLabel } from '../../../../helper/listHelper';
import { CardLayout } from '../../components/CardLayout';
import { NoSummaryData } from './NoSummaryData';

const { useLoadStatus } = hooks;
const {
  selectSensorLoadingStatus,
  selectPumpSummary,
} = selectors;

export function PumpSummary() {
  const { t } = useTranslation();
  const { blockIds } = hooks.useRanchBlockSelection() || {};
  const list = useSelector((state) => (
    selectors.selectPumpControllersForBlockIds(state, blockIds, true)
  )) || [];

  const summary = useSelector((state) => selectPumpSummary(state, list)) || {};
  const loading = useSelector((state) => selectSensorLoadingStatus(state, list));
  const {
    openPumpsCount,
    openPumpsName,
    closePumpsName,
    allPumpsName,
    totalOnline,
  } = summary;
  const stateTag = useMemo(() => (
    getConnectivityLabel(totalOnline, list.length, t)
  ), [totalOnline, list, t]);
  const showNoDataInfo = (!loading && !list.length);

  useLoadStatus(list);

  if (showNoDataInfo) {
    return (
      <NoSummaryData
        icon="pipe-valve"
        title={t('Pumps')}
      />
    );
  }

  return (
    <CardLayout>
      <div className="flex-row space-between">
        <IconTitle iconName="pipe-valve" title={t('Pumps')} />
        {!loading && (
          <ConnectivityStateTag
            label={stateTag.label}
            color={stateTag.color}
          />
        )}
      </div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <div className="flex-row space-between margin-top-20">
            <ValueUnitInfo
              ellipsis
              value={openPumpsCount}
              unit={t('On_status')}
              info={openPumpsName?.join(', ')}
            />
            <ValueUnitInfo
              ellipsis
              value={list.length - openPumpsCount}
              unit={t('Off_status')}
              info={closePumpsName?.join(', ')}
              type="danger"
            />
          </div>
          <div className="flex-row flex-end margin-top-10">
            <Typography.Text className="card-footer-txt">
              {truncate(allPumpsName?.join(', '))}
            </Typography.Text>
          </div>
        </>
      )}
    </CardLayout>
  );
}
