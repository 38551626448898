import React, { useState, useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import {
  Switch, Redirect, Route, useLocation,
} from 'react-router-dom';
import { AppTemplate, PrivateRoute, UserMenu } from 'farmx-web-ui';
import { authService } from 'farmx-api';
import { hooks, actions } from 'farmx-redux-core';
import { useDispatch } from 'react-redux';
import { UserNotificationDropdown } from '../stream';
import Page404 from './Page404';
import Sidebar from './Sidebar';
import FarmXLogo from '../../assets/images/farmx_logo_white_86.png';
import { ROUTES } from './routes';
import { useLanguageSetting } from '../../useLanguageSetting';
import RanchBlockBreadcrumb from './RanchBlockBreadcrumb';
import FallbackRender from '../mobile/components/FallbackRender';
import { Feedback } from '../mobile/feedback/Feedback';

const {
  useSetURLFromState,
  useSetStateFromURL,
} = hooks;

const { setLoginUserInfo, loadAllSensors } = actions;

const myFallback = (props) => <FallbackRender {...props} />;

export default function Main() {
  // TODO: refactor the below to a custom hook
  const [userInfo, setUserUnfo] = useState();
  const [feedbackFormVisible, setFeedbackFormVisible] = useState(false);
  const location = useLocation();
  const { search, pathname } = location;
  const urlSearch = useRef(search);
  const dispatch = useDispatch();
  useLanguageSetting();

  // Hook to read values from the URL and set to state
  useSetStateFromURL(urlSearch);

  // Hook to update URL with state values by history.push()
  useSetURLFromState(urlSearch, search, pathname);

  useEffect(() => {
    authService.getUserInfo().then(setUserUnfo);
  }, [setUserUnfo]);

  // To store the user info to redux-state
  useEffect(() => {
    dispatch(setLoginUserInfo(userInfo));
  }, [dispatch, userInfo]);

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  useEffect(() => {
    if (userInfo) {
      dispatch(loadAllSensors());
    }
  }, [dispatch, userInfo]);

  function onMenuClick() {
    setFeedbackFormVisible(true);
  }

  return (
    <AppTemplate
      sidebarComponent={Sidebar}
      headerMenu={(
        <div className="user-menu-wrapper">
          <UserNotificationDropdown userInfo={userInfo} />
          <UserMenu userInfo={userInfo} onFeedbackMenuClick={() => onMenuClick()} />
        </div>
      )}
      ranchBlockBreadcrumb={RanchBlockBreadcrumb}
      logo={FarmXLogo}
      title="FarmX"
      theme="light"
    >
      <Sentry.ErrorBoundary fallback={myFallback} showDialog>
        <Switch>
          <Route exact path="/">
            <Redirect to={{ pathname: '/recommendation' }} />
          </Route>
          {Object.values(ROUTES).map(({
            component,
            exact,
            key,
            path,
          }) => (
            <PrivateRoute
              adminRequired={false}
              authService={authService}
              component={component}
              exact={exact}
              key={key}
              path={path}
            />
          ))}
          <PrivateRoute
            adminRequired={false}
            authService={authService}
            component={Page404}
          />
        </Switch>
        {feedbackFormVisible && (
          <Feedback
            onClick={(d) => {
              setFeedbackFormVisible(d);
            }}
          />
        )}
      </Sentry.ErrorBoundary>
    </AppTemplate>
  );
}
