import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BottomSheet } from 'react-spring-bottom-sheet';
import {
  Button, Tooltip, Typography, Switch, Drawer, Descriptions,
} from 'antd';
import { icons, sensorTitles } from 'farmx-web-ui';
import { FaTimes } from 'react-icons/fa';
import { selectors, hooks } from 'farmx-redux-core';
import { useSelector } from 'react-redux';
import { selectBlocksForRanchId } from 'farmx-redux-core/src/farm/selectors';
import { SensorDetailBody } from './SensorDetailBody';
import { BlockRecommendationSummary } from '../home/BlockRecommendationSummary';
import { SatelliteImageryWrapper } from './SatelliteImageryWrapper';
import 'react-spring-bottom-sheet/dist/style.css';
import { getBlockArea } from '../../../helper/block';
import { getHumanReadableAnomalyType } from '../../../helper/common';
import { MapHeader } from '../components/MapHeader';
import { ImageryAnomalyDetailPage } from '../home/ImageryAnomalyDetailPage';
import { AnomalyAction } from '../home/AnomalyAction';
import { PercentAvailableWaterGraph } from '../home/PercentAvailableWaterGraph';
import { SensorSummaryList } from '../list/SensorSummaryList';
import { IconTitle } from '../list/IconTitle';

// This will be replaced with correct logic
const headerHeight = 60;
const isApp = matchMedia('(display-mode: standalone)').matches;
const safeAreaTop = isApp ? 48 : 0;
const safeAreaBottom = isApp ? 34 : 0;
const padding = 20;
const drawerHeight = window.innerHeight - 50;

const {
  selectRanchById,
  selectBlockById,
} = selectors;

function getTitleForDrawer(selectedFeature, expandedGeoJSON) {
  if (selectedFeature && selectedFeature?.properties) {
    return selectedFeature.properties.name;
  }
  if (expandedGeoJSON?.features && expandedGeoJSON.features.length) {
    return expandedGeoJSON.features[0].properties.name;
  }
  return 'Details';
}

export default function ShowDetails({
  presentationMode,
  isBottomSheetVisible,
  selectedFeature,
  dispatchMap,
  sheetRef,
  bottomSheetType,
  isImageryPanelVisible,
  selectedObjFromState,
  selectedImageryData,
  isExpandBtnVisible,
  expandedGeoJSON,
  ranchId,
  blockId,
  isMobile,
  onClick,
  editable,
  childComponent,
  isLeft,
}) {
  const { t } = useTranslation();

  const ranch = useSelector((state) => selectRanchById(state, ranchId));
  const block = useSelector((state) => selectBlockById(state, blockId));
  const ranchBlocks = useSelector((state) => selectBlocksForRanchId(state, ranchId));
  const ranchArea = getBlockArea(ranch);
  const blockArea = getBlockArea(block);
  const getUserUnits = hooks.useUnits();
  const userRanchArea = getUserUnits(ranchArea, 'square_meters', 'area') || { value: '', label: '' };
  const userBlockArea = getUserUnits(blockArea, 'square_meters', 'area') || { value: '', label: '' };
  const displayRanchArea = `${userRanchArea.value} ${userRanchArea.label}`;
  const displayBlockArea = `${userBlockArea.value} ${userBlockArea.label}`;
  // Declared variables with conditions to avoid repeating codes
  const isSensor = bottomSheetType === 'sensor';
  const isSettings = bottomSheetType === 'settings';
  const isAnomaly = bottomSheetType === 'anomaly';
  const CONDITION_CHECK_1 = !isSettings && !isAnomaly && !selectedFeature && isBottomSheetVisible
    && (!isImageryPanelVisible || !isMobile);
  const CONDITION_CHECK_2 = !isSettings && !isAnomaly && !isSensor && !selectedFeature
    && !isImageryPanelVisible;
  const CONDITION_CHECK_3 = (isSettings && selectedImageryData.visible);
  const CONDITION_CHECK_4 = !isSettings && isImageryPanelVisible;
  const CONDITION_CHECK_5 = isAnomaly && selectedFeature;
  const minWidth = isBottomSheetVisible ? '400px' : '0px';

  function renderRanchInfo(obj) {
    if (!obj) {
      return null;
    }

    return (
      <div className="flex-column margin-top-10 ranch-map-detail sensor-detail-header">
        <IconTitle
          title={t('Summary')}
          iconName="ranch"
        />
        <Descriptions>
          <Descriptions.Item label={t('Crop')}>{obj && obj.crop}</Descriptions.Item>
          <Descriptions.Item label={t('Area')}>{displayRanchArea}</Descriptions.Item>
        </Descriptions>
        <SensorSummaryList
          blocks={ranchBlocks}
          selectedObj={null}
        />
      </div>
    );
  }

  function renderBlockInfo(obj) {
    if (obj == null) return null;
    return (
      <div className="flex-column margin-top-10 block-map-detail">
        <div className="sensor-header-type">{obj && obj.crop}</div>
        <Typography.Text>{displayBlockArea}</Typography.Text>
        <BlockRecommendationSummary block={obj} />
        {obj.id && (
          <div className="percent-available-water-graph-container">
            <div>
              <Typography.Text>{t('Percent Available Water')}</Typography.Text>
            </div>
            <PercentAvailableWaterGraph
              blockId={obj.id}
              chartOptions={{
                pointWidth: 15,
                height: 200,
              }}
            />
          </div>
        )}
      </div>
    );
  }

  function bottomSheetHeaderTooltip(text, subText) {
    return (
      <Tooltip // 28 is maximum text length to display.
        title={text.length > 28 ? text : ''}
        trigger="click"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        <div className="sensor-header-name sensor-header-name-ellipsis">{text}</div>
        <div className="sensor-header-type mobile-sensor-header-align">{t(subText || '')}</div>
      </Tooltip>
    );
  }

  function getAnomalyHeader() {
    const anomalyHeader = selectedFeature?.properties
      ? `${selectedFeature.name} -
      ${t(getHumanReadableAnomalyType(selectedFeature.properties.anomaly_type))}
      [${t(selectedFeature.properties.anomaly_severity)}]`
      : null;
    return bottomSheetHeaderTooltip(anomalyHeader);
  }

  function renderImageryToggle() {
    return (
      <div className="satellite-imagery-toggle-switch">
        <Switch
          checked={selectedImageryData.visible}
          onChange={(v) => {
            dispatchMap({
              type: 'setSelectedImageryData',
              payload: {
                visible: v,
                data: selectedImageryData.data,
              },
            });
          }}
        />
        <div className="margin-left-10 text-imagery">
          <Typography.Text>
            {t('Show Imagery')}
          </Typography.Text>
        </div>
      </div>
    );
  }

  function renderRanchBlockHeader(type) {
    if (type === 'ranch') {
      return CONDITION_CHECK_2 && ranch && bottomSheetHeaderTooltip(ranch.name);
    }
    if (type === 'block') {
      return CONDITION_CHECK_2 && block && bottomSheetHeaderTooltip(block.name);
    }
    return null;
  }

  function bottomSheetHeader() {
    const { name, type } = (selectedFeature?.properties) || {};
    const anomalyHeader = selectedFeature?.properties
      ? `${selectedFeature.name} -
      ${t(getHumanReadableAnomalyType(selectedFeature.properties.anomaly_type))}
      [${t(selectedFeature.properties.anomaly_severity)}]`
      : null;

    return (
      <div className={selectedFeature?.type !== 'Feature' || isImageryPanelVisible
        ? 'bottom-sheet-header-container'
        : 'bottom-sheet-header-container bottom-sheet-header-container-sensor-details'}
      >
        <div className="sensor-detail-header">
          <div className="flex-row">
            {isSensor && !!selectedFeature?.properties
              && !isSettings && !isImageryPanelVisible
              && name && type ? (
                <>
                  <div className="sensor-header-icon-block mobile-sensor-icon">
                    <i className={icons[type] ? icons[type] : icons.cavalier} />
                  </div>
                  {bottomSheetHeaderTooltip(name, sensorTitles[type]?.singular)}
                </>
              )
              : (
                <div>
                  {isImageryPanelVisible
                    ? null
                    : (
                      <div className="sensor-header-type">
                        {t(sensorTitles[type]?.singular || '')}
                      </div>
                    )}
                </div>
              )}
            {renderRanchBlockHeader(selectedObjFromState?.type)}

            {CONDITION_CHECK_5 && !isImageryPanelVisible && (
              // show ellipses when character length is above 35
              <MapHeader
                headerText={anomalyHeader?.length > 35
                  ? <Tooltip title={anomalyHeader}>{anomalyHeader}</Tooltip>
                  : anomalyHeader}
                onClick={onClick}
                editable={editable}
              />
            )}

            {CONDITION_CHECK_4 && renderImageryToggle()}
          </div>
        </div>
        <div className="bottom-sheet-header">
          <Button
            shape="circle"
            icon={<FaTimes />}
            onClick={() => {
              if (onClick) onClick({ type: 'close' });
              dispatchMap({
                type: 'setSelectedFeatureWithType',
                payload: {
                  selectedFeature: undefined,
                  bottomSheetType: undefined,
                  showBottomSheet: !!CONDITION_CHECK_3,
                },
              });
              dispatchMap({
                type: 'setIsImageryPanelVisible',
                payload: !!CONDITION_CHECK_3,
              });
            }}
          />
        </div>
      </div>
    );
  }

  function renderSatelliteImagery() {
    return CONDITION_CHECK_4
      && (
        <SatelliteImageryWrapper
          ranchId={ranchId}
          dispatchMap={dispatchMap}
          childComponent={childComponent}
          isLeft={isLeft}
        />
      );
  }

  function onDismissBottomSheet() {
    dispatchMap({
      type: 'setIsBottomSheetVisible',
      payload: false,
    });
    dispatchMap({
      type: 'setSelectedFeatureWithType',
      payload: {
        selectedFeature: undefined,
        bottomSheetType: undefined,
        showBottomSheet: false,
      },
    });
    dispatchMap({
      type: 'setIsImageryPanelVisible',
      payload: false,
    });
  }

  function onClosesDrawer() {
    dispatchMap({
      type: 'setSelectedFeatureWithType',
      payload: {
        selectedFeature: undefined,
        bottomSheetType: undefined,
        showBottomSheet: false,
      },
    });
  }

  function getSnapPoints(maxHeight, height) {
    if (isSettings) {
      return [maxHeight - (headerHeight + safeAreaTop)];
    }

    const satelliteDiv = document.getElementById('satellite-imagery-section');
    const satelliteDivHeight = (satelliteDiv && satelliteDiv.offsetHeight);
    if (CONDITION_CHECK_4) {
      // 35 for the left, right tab height
      if (isApp) return [satelliteDivHeight + safeAreaTop + safeAreaBottom + padding + 35];
      return [(satelliteDivHeight + headerHeight + padding + 35)];
    }

    // 490 min-height for the default view of anomaly details in bottom-sheet drawer
    const anomalyDetail = isAnomaly ? 490 : 310;
    // 310 & 250 min-height for the default view of bottom-sheet drawer
    const previewSize = maxHeight - ((maxHeight
      - ((selectedFeature?.type === 'Feature' ? anomalyDetail : 250))) - safeAreaBottom);
    if (height > previewSize && isExpandBtnVisible) {
      dispatchMap({
        type: 'setIsExpandBtnVisible',
        payload: false,
      });
    } else if (height <= previewSize && !isExpandBtnVisible) {
      dispatchMap({
        type: 'setIsExpandBtnVisible',
        payload: true,
      });
    }
    return [
      maxHeight - (headerHeight + safeAreaTop),
      previewSize,
    ];
  }

  function renderRanchBlockInfo(type) {
    if (type === 'ranch') {
      return CONDITION_CHECK_1 && renderRanchInfo(ranch);
    }
    if (type === 'block') {
      return CONDITION_CHECK_1 && block && renderBlockInfo(block);
    }
    return null;
  }

  const renderDetails = () => (
    <div className={!isMobile ? 'bottomsheet-block desktop-sidebar-details' : ''}>
      {isSensor && (!isImageryPanelVisible || !isMobile)
        && !!selectedFeature?.properties && (
          <SensorDetailBody sensorProperties={selectedFeature.properties} renderDefault={false} />
      )}
      {renderRanchBlockInfo(selectedObjFromState?.type)}

      {CONDITION_CHECK_5 && (
        <div className={!isMobile && 'desktop-imagery-anomaly'}>
          <ImageryAnomalyDetailPage
            propAnomaly={selectedFeature}
            propBlockId={blockId || selectedFeature?.properties?.block}
            showMap={false}
            showActionButton={!isMobile}
          />
        </div>
      )}
    </div>
  );

  const renderDrawerTitle = (subText) => (
    <div className="sensor-detail-header map-desktop-sensor-header-detail">
      <div className="flex-row">
        {isSensor && !!selectedFeature?.properties
          && !isSettings
          && selectedFeature.properties.name && selectedFeature.properties.type ? (
            <>
              <div className="sensor-header-icon-block">
                <i className={icons[selectedFeature.properties.type]
                  ? icons[selectedFeature.properties.type] : icons.cavalier}
                />
              </div>
              <div className="sensor-header-sub-text">
                <div className="sensor-header-type">{t(subText || '')}</div>
              </div>
            </>
          ) : (
            <div>
              <div className="sensor-header-type">{t(subText || '')}</div>
            </div>
          )}
        <div className="map-desktop-sensor-header">
          <Typography.Text>
            <strong>
              {!isAnomaly && selectedFeature && getTitleForDrawer(
                selectedFeature, expandedGeoJSON,
              )}
              <div className="map-desktop-header-title">
                {CONDITION_CHECK_5 && (
                  <MapHeader
                    headerText={getAnomalyHeader(selectedFeature)}
                    onClick={onClick}
                    editable={editable}
                  />
                )}

                {selectedObjFromState.type === 'block' ? isBottomSheetVisible
                  && CONDITION_CHECK_1 && block?.name
                  : selectedObjFromState.type === 'ranch' && isBottomSheetVisible
                  && CONDITION_CHECK_1 && ranch?.name}
              </div>
            </strong>
          </Typography.Text>
        </div>
      </div>
      <div className="map-desktop-bottom-header-button bottom-sheet-header">
        <Button
          shape="circle"
          icon={<FaTimes />}
          onClick={() => {
            if (onClick) onClick({ type: 'close' });
            onClosesDrawer();
          }}
        />
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <BottomSheet
          ref={sheetRef}
          open={isBottomSheetVisible}
          blocking={false}
          onDismiss={() => onDismissBottomSheet()}
          defaultSnap={({ snapPoints }) => snapPoints[-1]}
          snapPoints={({ maxHeight, height }) => getSnapPoints(maxHeight, height)}
          header={bottomSheetHeader()}
          scrollLocking={false}
          onClick={() => {
            if (!isAnomaly) {
              sheetRef.current
                .snapTo(({ maxHeight }) => maxHeight - (headerHeight + safeAreaTop));
            }
          }}
          footer={isAnomaly && <AnomalyAction blockId={blockId} anomaly={selectedFeature} />}
          className={isAnomaly && 'anomaly-bottom-sheet-height'}
        >
          {!isImageryPanelVisible && (
            <div className={isAnomaly && isMobile ? 'anomaly-bottomsheet-block'
              : 'bottomsheet-block'}
            >
              {renderDetails()}
            </div>
          )}
          {renderSatelliteImagery()}
        </BottomSheet>
      ) : null}
      {!isMobile && (
      <Drawer
        className={isAnomaly ? 'desktop-anomaly-drawer drawer-desktop' : 'drawer-desktop'}
        style={{ marginTop: '50px', maxHeight: `${drawerHeight}px`, minWidth }}
        title={renderDrawerTitle(sensorTitles[selectedFeature?.properties?.type]?.singular)}
        placement="right"
        closable={false}
        visible={isBottomSheetVisible}
        key="right"
        mask={false}
        width={400}
      >
        {renderDetails()}
      </Drawer>
      )}
    </>
  );
}

ShowDetails.propTypes = {
  isMobile: PropTypes.bool,
  presentationMode: PropTypes.string,
  isBottomSheetVisible: PropTypes.bool,
  selectedFeature: PropTypes.shape(),
  dispatchMap: PropTypes.func,
  sheetRef: PropTypes.shape(),
  bottomSheetType: PropTypes.string,
  isImageryPanelVisible: PropTypes.bool,
  selectedImageryData: PropTypes.shape(),
  isExpandBtnVisible: PropTypes.bool,
  expandedGeoJSON: PropTypes.shape(),
  ranchId: PropTypes.number.isRequired,
  blockId: PropTypes.number.isRequired,
  selectedObjFromState: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
  editable: PropTypes.bool,
  childComponent: PropTypes.func,
  isLeft: PropTypes.bool,
};

ShowDetails.defaultProps = {
  isMobile: false,
  presentationMode: null,
  isBottomSheetVisible: false,
  selectedFeature: null,
  dispatchMap: null,
  sheetRef: null,
  bottomSheetType: '',
  isImageryPanelVisible: false,
  selectedImageryData: null,
  isExpandBtnVisible: false,
  expandedGeoJSON: null,
  onClick: null,
  editable: false,
  childComponent: null,
  isLeft: true,
};
