import { helpers } from 'farmx-api';

const { fieldToHeader } = helpers;

function sortByName(sensors) {
  return sensors.sort((a, b) => a?.name?.localeCompare(b?.name));
}

/**
 * @param {*} sSensors - array of soil sensors objects
 * @param {*} pSensors - array of pressure sensors objects
 * @param {*} blockIds - array of blockIds
 * @returns - The array of objects with grouped sensors by blockIds
 */
export function groupSensors(sSensors, pSensors, blockIds) {
  const allSensors = [];
  const remainingSensors = [];
  blockIds.forEach((blockId) => {
    const sortedSoilSensors = sSensors[blockId] ? sortByName(sSensors[blockId]) : [];
    const filteredSoilSensors = sortedSoilSensors.filter((d) => d?.name);

    const sortedPressureSensors = pSensors[blockId] ? sortByName(pSensors[blockId]) : [];
    const filteredPressureSensors = sortedPressureSensors.filter((d) => d?.name);

    if (filteredSoilSensors?.length) {
      // Grouping pressure senors and soil sensors by blockId
      filteredSoilSensors.forEach((sensor) => {
        allSensors.push(sensor, ...filteredPressureSensors);
      });
    } else if (filteredPressureSensors?.length) {
      // Collecting pressure senors that are not included with soil sensors
      remainingSensors.push(...filteredPressureSensors);
    }
  });

  return [...allSensors, ...remainingSensors];
}

/**
 * @param {*} graphConfig - config string to set key for config objects array
 * @param {*} sensorsData - sensors data array for the selected ranch/block
 * @param {*} SensorDataChart - component to render the graph
 * @param {*} variable - object that contains graphConfig as key with variables array
 * @returns - The object with graphConfig as key and values as array of
 * graph config objects for the selected ranch/block
 */
export function prepareGraphConfig(graphConfig, sensorsData = [], SensorDataChart, variableObj) {
  const graphsObj = {};
  const configArr = [];
  sensorsData.forEach((sensor, sKey) => {
    let variables = [];
    if (sensor?.type === 'aquacheck_soil'
      || sensor?.type === 'pixl_soil') variables = variableObj.soilVariables;
    if (sensor?.type === 'water_pressure') variables = variableObj.pressureVariables;
    const {
      identifier, id, type, name,
    } = sensor;

    variables.forEach((variable, vKey) => {
      const sObj = {
        component: SensorDataChart,
        key: `${name}_${sKey}_${vKey}`,
        variable,
        name,
        identifier,
        sensor: { identifier, id, type },
      };
      configArr.push(sObj);
    });
  });
  graphsObj[graphConfig] = configArr;
  return graphsObj;
}

const units = {
  soil_moisture_rootzone_vwc: 'percent',
  water_pressure: 'psi',
};

export function getChartHeader(key) {
  const unitsStr = units[key] ? ` (${units[key]})` : '';
  const variableName = fieldToHeader(key);
  const legendTitle = variableName + unitsStr;
  return legendTitle;
}

export function concatSoilSensors(soilSensor, pixlSensor) {
  const soilSensorKeys = Object.keys(soilSensor);
  const concatenateSoilSensors = soilSensorKeys.map((key) => {
    const obj = {};
    obj[key] = [...soilSensor[key], ...pixlSensor[key]];

    return obj;
  }).reduce((a, obj) => ({ ...a, ...obj }), {});

  return concatenateSoilSensors;
}

export function filterSensorByType(sensors, type) {
  let filteredSensors = [];
  if (sensors?.length) {
    filteredSensors = sensors?.filter((d) => d?.type !== type);
  }
  return filteredSensors;
}
