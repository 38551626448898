import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { ValueUnitInfo } from './ValueUnitInfo';
import { IconTitle } from './IconTitle';
import { SoilRefChart } from './SoilRefChart';
import { CardLayout } from '../components/CardLayout';
import { useRootzoneValue } from './ListCards/useRootzoneValue';
import { isNotNull } from '../../../helper/listHelper';

export function SoilSensorListItem(props) {
  const { details, status, selectedMetrics } = props;
  const { t } = useTranslation();
  const {
    vwcRootzone, wiltingPointRootzone, fieldCapacityRootzone, depth1, depth2,
    online,
  } = status;
  const depthSize = useMemo(() => {
    if (isNotNull(depth1) && isNotNull(depth2)) {
      return depth2 - depth1;
    }
    return 1;
  }, [depth1, depth2]);

  const [rootzoneValue, rootzoneValueLabel] = useRootzoneValue({
    depthRootzone: depthSize,
    vwcRootzone,
    wiltingPointRootzone,
    fieldCapacityRootzone,
    selectedMetrics,
  });

  if (!status || status.visible === false) {
    return null;
  }

  return (
    <CardLayout>
      <div className="flex-row space-between">
        <div>
          <IconTitle
            iconName="eyedropper"
            title={details.name || t('No Name')}
          />
          {status?.vwcRootzone ? (
            <div className="margin-top-10">
              <ValueUnitInfo
                type={online ? 'primary' : 'disabled'}
                value={rootzoneValue}
                unit={rootzoneValueLabel}
                info={t('root zone avg')}
              />
            </div>
          ) : (
            <Typography.Text>{t('No Data')}</Typography.Text>
          )}
        </div>
        {!!status?.vwc1 && (
          <SoilRefChart
            id={`soil-ref-chart-${details.id}`}
            status={status}
            selectedMetrics={selectedMetrics}
          />
        )}
      </div>
    </CardLayout>
  );
}

SoilSensorListItem.defaultProps = {
  details: {},
  status: {},
  selectedMetrics: 'vwc',
};

SoilSensorListItem.propTypes = {
  details: PropTypes.shape({
    id: PropTypes.number,
    vwc: PropTypes.number,
    name: PropTypes.string,
  }),
  status: PropTypes.shape({
    id: PropTypes.number,
    vwc1: PropTypes.number,
    vwcRootzone: PropTypes.number,
    visible: PropTypes.bool,
    depth1: PropTypes.string,
    depth2: PropTypes.string,
    wiltingPointRootzone: PropTypes.string,
    fieldCapacityRootzone: PropTypes.string,
    online: PropTypes.bool,
  }),
  selectedMetrics: PropTypes.oneOf(['vwc', 'percent', 'volume']),
};
