import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hooks, selectors } from 'farmx-redux-core';
import { truncate } from 'lodash';
import ConnectivityStateTag from '../../components/ConnectivityStateTag';
import { IconTitle } from '../IconTitle';
import { ValueUnitInfo } from '../ValueUnitInfo';
import { CardLayout } from '../../components/CardLayout';
import { displayInchesInQuotes, isNotNull } from '../../../../helper/listHelper';
import { NoSummaryData } from './NoSummaryData';

const {
  useLoadStatus,
  useUnits,
} = hooks;

const {
  selectWeatherSummary,
  selectSensorLoadingStatus,
} = selectors;

export function WeatherSummary(props) {
  const { list } = props;
  const { t } = useTranslation();
  const getUserUnits = useUnits();
  useLoadStatus(list);
  const summary = useSelector((state) => selectWeatherSummary(state, list)) || {};
  const loading = useSelector((state) => selectSensorLoadingStatus(state, list));
  const {
    airTemperature,
    precipitation,
    windSpeed,
    stationNames,
  } = summary;
  const units = summary.units || {
    airTemperature: 'degrees_fahrenheit',
    precipitation: 'inches',
  };
  const getLabel = (label) => (isNotNull(label) ? label : '');
  const temperatureUnit = getUserUnits(getLabel(airTemperature), units.airTemperature, 'temperature');
  const precipitationUnit = getUserUnits(
    getLabel(precipitation), units.precipitation, 'depth', { decimalPlaces: 2 },
  );
  const showNoDataInfo = (!loading && !list.length) || isNaN(airTemperature);

  if (showNoDataInfo) {
    return (
      <NoSummaryData
        icon="cloud-sun"
        title={t('Weather')}
      />
    );
  }

  return (
    <CardLayout>
      <div className="flex-row space-between">
        <IconTitle
          iconName="cloud-sun"
          title={t('Weather')}
        />
        {!loading && (
          <ConnectivityStateTag
            isOnline
          />
        )}
      </div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <div className="flex-row space-between margin-top-20">
            <ValueUnitInfo
              showDegree
              value={temperatureUnit.value}
              unit={temperatureUnit.label}
              info={t('current temp')}
            />
            <ValueUnitInfo
              value={precipitationUnit.value}
              unit={`${displayInchesInQuotes(precipitationUnit.label)} prec.`}
              info={t('last 24 hours')}
            />
            <ValueUnitInfo
              value={getLabel(windSpeed)}
              unit="mph"
              info={t('wind speed')}
            />
          </div>
          <div className="flex-row flex-end margin-top-10">
            <Typography.Text className="card-footer-txt">{truncate(stationNames?.join(', '))}</Typography.Text>
          </div>
        </>
      )}
    </CardLayout>
  );
}

WeatherSummary.defaultProps = {
  list: [],
};

WeatherSummary.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
};
