import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  notification,
} from 'antd';
import {
  updateScheduledEvent,
  handleSingleRecurrence,
  deleteScheduledEvent,
} from '../../../helper/scheduleHelper';
import './ScheduleModal.less';

export function ScheduleDeleteModal({
  scheduledEvent,
  visible,
  handleOk,
  handleCancel,
}) {
  const { t } = useTranslation();

  function deleteSingleRecurrence() {
    handleSingleRecurrence(scheduledEvent, null, null, null, 'delete', (success) => {
      if (success) {
        notification.success({
          message: t('Deleted scheduled irrigation'),
        });
        handleOk(true);
      } else {
        notification.error({
          message: t('Failed to delete scheduled irrigation'),
        });
        handleOk(false);
      }
    });
  }

  function deleteOnAndAfterRecurrence() {
    // Update recurrence to end on date before current recurrence

    // start with the root event
    const updatedEvent = {
      ...scheduledEvent,
      start_date: scheduledEvent.rootStart,
      stop_date: scheduledEvent.rootStop,
      date_start_local: scheduledEvent.rootStartLocal,
    };

    if (scheduledEvent.repeat_end_type === 'count') {
      updatedEvent.repeat_end_count = scheduledEvent.event_index;
    } else {
      updatedEvent.repeat_end_type = 'date';
      // TODO: check for off by one
      updatedEvent.repeat_end_date = scheduledEvent.start_date;
    }

    updateScheduledEvent(updatedEvent, (success) => {
      if (success) {
        notification.success({
          message: t('Deleted scheduled irrigation'),
        });
        handleOk(true);
      } else {
        notification.error({
          message: t('Failed to delete scheduled irrigation'),
        });
        handleOk(false);
      }
    });
  }

  function deleteSchedule() {
    deleteScheduledEvent(scheduledEvent.id, (success) => {
      if (success) {
        notification.success({
          message: t('Deleted scheduled irrigation'),
        });
        handleOk(true);
      } else {
        notification.error({
          message: t('Failed to delete scheduled irrigation'),
        });
        handleOk(false);
      }
    });
  }

  // if event is a rendered recurring event, not the root event
  function isRecurringEvent() {
    return scheduledEvent.is_recurring && scheduledEvent.event_index !== 0;
  }

  function renderTitle() {
    if (isRecurringEvent()) {
      return t('Delete recurring irrigation');
    }
    return t('Delete scheduled irrigation');
  }

  function renderRecurringEventFooter() {
    return [
      <Button key="single" type="primary" onClick={deleteSingleRecurrence}>
        {t('This event')}
      </Button>,
      <Button key="following" type="primary" onClick={deleteOnAndAfterRecurrence}>
        {t('This and all following events')}
      </Button>,
      <Button key="all" type="primary" onClick={deleteSchedule}>
        {t('All events')}
      </Button>,
      <Button key="back" onClick={handleCancel}>
        {t('Cancel')}
      </Button>,
    ];
  }

  function renderFooter() {
    if (isRecurringEvent()) {
      return renderRecurringEventFooter();
    }
    return [
      <Button key="submit" type="primary" onClick={deleteSchedule} data-testid="delete-button">
        {t('Delete')}
      </Button>,
      <Button key="back" onClick={handleCancel}>
        {t('Cancel')}
      </Button>,
    ];
  }

  return (
    <Modal
      title={renderTitle()}
      className="schedule-options-modal"
      closable={false}
      centered
      visible={visible}
      onOk={deleteSchedule}
      onCancel={handleCancel}
      footer={renderFooter()}
    />
  );
}
