import * as Sentry from '@sentry/react';
import moment from 'moment';
import 'moment/min/locales';

/* eslint-disable no-useless-escape */
export const validateEmail = (email) => !!email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

// Ref link: https://docs.sentry.io/platforms/javascript/guides/react/
export const sentryInit = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 1.0,
    integrations: [new Sentry.BrowserTracing()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

/**
 * @param {*} feedBack - FeedBack object with name, event_id, email, comments and score
 * @param {*} callBack - function to return response
 */
export const submitUserFeedback = (feedBack, callBack) => {
  const organizationSlug = process.env.REACT_APP_SENTRY_ORGANIZATION_SLUG;
  const projectSlug = process.env.REACT_APP_SENTRY_PROJECT_SLUG;
  const DSN = process.env.REACT_APP_SENTRY_DSN;
  const feedBackURL = `https://sentry.io/api/0/projects/${organizationSlug}/${projectSlug}/user-feedback/`;

  const headers = {
    Authorization: `DSN ${DSN}`,
    'Content-Type': 'application/json',
  };

  fetch(feedBackURL, {
    method: 'POST',
    headers,
    body: JSON.stringify(feedBack),
  }).then((response) => callBack(response)).catch(() => callBack(false));
};

export const getPercentForFraction = (value) => Math.round(Number(value) * 1000) / 10;
export const roundToNearestPercent = (value) => Math.round(Number(value));

export function setMomentLocale(lang) {
  const browserLang = navigator.language;
  return moment.locale(lang === 'system' ? browserLang : lang);
}

function getTitleCaseString(inputString) {
  const anomalyTypeWords = inputString.replace('_', ' ').split(' ');

  for (let i = 0; i < anomalyTypeWords.length; i += 1) {
    anomalyTypeWords[i] = anomalyTypeWords[i].charAt(0).toUpperCase()
     + anomalyTypeWords[i].slice(1);
  }
  return anomalyTypeWords.join(' ');
}

export function getHumanReadableAnomalyType(anomalyType) {
  if (anomalyType) {
    return getTitleCaseString(anomalyType);
  }
  return null;
}

export function recommendationAnomalyFilter(data) {
  return data?.filter((d) => d?.type !== 'imagery_anomaly')
    .reduce((a, obj) => (obj || a), undefined);
}

export function getRecommendationIdentifier(recommendation) {
  return `${recommendation.block}-${recommendation.type}${recommendation.type === 'imagery_anomaly'
    ? `-${recommendation.anomalyType}` : ''}`;
}

export const compareByName = (a, b) => (a?.name?.toLowerCase()
  ?.localeCompare(b?.name?.toLowerCase()));
