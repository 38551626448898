import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors, hooks } from 'farmx-redux-core';
import IrrigationStatus from '../../components/IrrigationStatus';
import { ControlPumpDetails } from '../ControlPump/ControlPumpDetails';
import { ControlValveDetail } from '../ControlValves/ControlValveDetail';
import { PageHeader } from '../../components/PageHeader';
import { useTracking } from '../../../../helper/mixpanel';
import RanchBlockTitle from '../../components/RanchBlockTitle';
import PressureSummary from './PressureSummary';
import WaterInformation from './WaterInformation';

const {
  selectBlockControlStatus, selectPressureDetails,
  selectCurrentBlockScheduledEvents,
  selectScheduledEventById,
} = selectors;

const { useBlockNames, useRanchNamesForBlocks } = hooks;

/**
 * TODO: Unreachable code: if (!blockDetails) {...}
 */
export function BlockDetails(props) {
  const { match } = props;
  let blockDetails = null;
  const { t } = useTranslation();
  const tracking = useTracking();

  const blockId = Number(match.params.id);
  useEffect(() => {
    if (tracking) tracking.track('Loaded Control Summary Page', { blockId });
  }, [blockId, tracking]);

  const blockName = useBlockNames([blockId]) || {};
  const ranchName = useRanchNamesForBlocks([blockId]) || {};

  const controlStatus = useSelector((state) => selectBlockControlStatus(state, [blockId]));
  const pressureSensors = useSelector((state) => selectPressureDetails(state, blockId)) || {};

  const vStatus = (controlStatus && controlStatus[blockId]?.valveStatus) || {};
  const pStatus = (controlStatus && controlStatus[blockId]?.vfdStatus) || {};
  const scheduledEventIds = useSelector(
    (state) => selectCurrentBlockScheduledEvents(state, blockId),
  )?.map((d) => d?.id);
    // The one month date range is needed to select the scheduled events
  const dates = [moment(), moment().add(1, 'month')];
  const eventId = scheduledEventIds?.[0];
  const scheduledEvent = useSelector((state) => selectScheduledEventById(state, {
    id: eventId,
    blockId,
  }));

  blockDetails = {
    blockId,
    valveCurrentState: (vStatus.controlStatus && vStatus.controlStatus.state) || '',
    blockName: blockName[blockId],
    pumpCurrentState: (pStatus.controlStatus && pStatus.controlStatus.status) || '',
    irrigationStartDate: (vStatus.scheduleStatus && vStatus.scheduleStatus.dateScheduledOpen)
    || (scheduledEvent && scheduledEvent.start_date),
    irrigationEndDate: (vStatus.scheduleStatus && vStatus.scheduleStatus.dateScheduledClose)
    || (scheduledEvent && scheduledEvent.stop_date),
    units: vStatus.units || {
      pressureCutoff: 'psi',
      actualVolume: 'gallons',
    },
  };

  if (!blockDetails) {
    return (
      <div className="div-default block-details-container">
        <div className="block-details-additional-info mobile-list-item">
          {t('Block details not available')}
        </div>
      </div>
    );
  }

  const ranchBlockName = `${t('Control')} - ${ranchName[blockId]} > ${blockDetails.blockName}`;

  return (
    <div className="div-default block-details-container">
      <PageHeader
        showBack
        title={<RanchBlockTitle title={ranchBlockName} />}
        toPath="/control"
      />
      <div className="block-details-additional-info mobile-list-item">
        <IrrigationStatus
          block={blockDetails}
          type="calendar"
        />
        <WaterInformation
          blockId={blockId}
          units={blockDetails.units}
          dateRange={dates}
          eventId={eventId}
        />
      </div>
      <div className="block-details-additional-info mobile-list-item">
        <Link
          className="link-to-control-sensor"
          to={{
            pathname: `/control/sensors/${blockId}`,
            state: { prevPath: window.location.pathname },
          }}
        >
          <PressureSummary
            id={`pressure-${blockName[blockId]}`}
            pressure={pressureSensors.pressure}
            valveList={pressureSensors.sensorList}
            loading={pressureSensors.loading}
            pressureCutoff={pressureSensors.pressureCutoff}
            units={blockDetails.units}
          />
        </Link>
      </div>
      <ControlPumpDetails pumpId={blockDetails.blockId} />
      <ControlValveDetail valveId={blockDetails.blockId} showPressureSummary={false} />
    </div>
  );
}

BlockDetails.defaultProps = {
  history: {},
  match: { params: {} },
};

BlockDetails.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
};
