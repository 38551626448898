import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import { Link } from 'react-router-dom';
import { PageHeader } from '../components/PageHeader';
import { ValveSummary } from './ListCards/ValveSummary';
import { RanchBlockSelect } from './RanchBlockSelect';
import { installState } from '../../../constants';
import { getLoadingStatusForListOfSensors } from '../../../helper/listHelper';
import ControlValveListItem from '../control/ControlValves/ControlValveListItem';
import { isMobile } from '../../../utils/detectDevice';

const {
  selectBlocksForRanchBlockSelection,
  selectAllSensorsForBlockIdsByType,
} = selectors;

export function ValveSensorList(props) {
  const { history } = props;
  const { t } = useTranslation();
  const [selectedObj, setSelectedObj] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const state = useSelector((store) => store);
  const sensorStatus = state.sensorsData.status.entities;

  useEffect(() => {
    if (history && history.location && history.location.selectedObj) {
      setSelectedObj(history.location.selectedObj);
    }
  }, [history]);

  useEffect(() => {
    const blocks = selectBlocksForRanchBlockSelection(state, selectedObj);
    const blockIds = blocks.map((item) => item.id);

    const sensors = selectAllSensorsForBlockIdsByType(state, {
      blockIds,
      installState: installState.INSTALLED,
    });

    setList(sensors.valve || []);
  }, [selectedObj, state]);

  useEffect(() => {
    setLoading(getLoadingStatusForListOfSensors(list, sensorStatus));
  }, [list, sensorStatus]);

  const showList = Boolean(!loading && list.length);

  return (
    <div className="div-default padding-10 page-content device-summary-list">
      <div className="desktop-container">
        <PageHeader
          showBack
          title={t('Valve Sensors')}
          toPath="/list"
        />
        {isMobile && (
        <RanchBlockSelect
          selectedObj={selectedObj}
          setSelectedObj={setSelectedObj}
        />
        )}
        <div className="list-container">
          <ValveSummary
            list={list}
          />

          {showList && list.map((v) => {
            const status = sensorStatus[`${v.type}/${v.identifier}`];
            if (status && status.visible !== false) {
              const valve = { ...v, ...status };
              return (
                <Link key={valve.identifier} to={`/list/valve-sensor/${valve.type}/${valve.identifier}`}>
                  <ControlValveListItem
                    valve={{
                      blockId: valve.id,
                      valveCurrentState: (valve.controlStatus && valve.controlStatus.state) || '',
                      blockName: valve.name,
                      valveName: valve.name,
                      alarmCount: valve.alarmCount,
                      lastUpdated: valve.lastUpdated,
                      valveOnline: valve.online,
                      pressure: '',
                      sensorList: [],
                      valveLoading: valve.loading,
                    }}
                    isLink={false}
                  />
                </Link>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
}

ValveSensorList.defaultProps = {
  history: {},
};

ValveSensorList.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    location: PropTypes.shape({
      selectedObj: PropTypes.object,
    }),
  }),
};
