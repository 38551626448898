import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { IconTitle } from '../IconTitle';
import { CardLayout } from '../../components/CardLayout';

export function NoSummaryData(props) {
  const { icon, title, message } = props;
  const { t } = useTranslation();

  return (
    <CardLayout>
      <div className="flex-row space-between">
        <IconTitle
          iconName={icon}
          title={title}
        />
      </div>
      <div className="margin-top-20">
        <Typography.Text>{t(message)}</Typography.Text>
      </div>
    </CardLayout>
  );
}

NoSummaryData.defaultProps = {
  message: 'No Sensors Installed',
};

NoSummaryData.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
};
