import React, {
  useState, useEffect, useCallback, Suspense, lazy,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import { RanchBlockSelectMobile } from 'farmx-web-ui';
import { useDispatch, useSelector } from 'react-redux';
import { actions, hooks, selectors } from 'farmx-redux-core';
import InfiniteScroll from 'react-infinite-scroller';
import ControlPumpList from './ControlPump/ControlPumpList';
import ControlValveList from './ControlValves/ControlValveList';
import ControlSummary from './ControlSummary/ControlSummary';
import { PageHeader } from '../components/PageHeader';
import './control.css';
import './CommonStyle.css';
import { useTracking } from '../../../helper/mixpanel';
import ReactContentLoader from '../components/ContentLoader';

// This will be removed after testing
// const ControlSummary = lazy(() => import('./ControlSummary/ControlSummary'));
// const ControlValveList = lazy(() => import('./ControlValves/ControlValveList'));
// const ControlPumpList = lazy(() => import('./ControlPump/ControlPumpList'));

const {
  selectBlocksLoadingState,
  selectBlocksLoaded,
} = selectors;

const {
  loadAllSensors,
  setUrlLoad,
  setRanchBlockSelection,
} = actions;

const { selectLoginUserInfo } = selectors;

const { useRanchBlockSelection } = hooks;

const tabsContent = (blocks) => ({
  summary: <ControlSummary blocks={blocks} />,
  pumps: <ControlPumpList blocks={blocks} />,
  valves: <ControlValveList blocks={blocks} />,
});

export function ControlPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const tracking = useTracking();

  const userInfo = useSelector(selectLoginUserInfo).payload;

  useEffect(() => {
    if (tracking) tracking.track('Loaded Control Page');
  }, [tracking]);

  const controlOptions = [
    { label: t('Summary'), value: 'summary' },
    { label: t('Pumps'), value: 'pumps' },
    { label: t('Valves'), value: 'valves' },
  ];

  const param = new URLSearchParams(search);
  const paramTab = param.get('tab');

  const [selectedTab, setSelectedTab] = useState(paramTab || 'summary');
  const dispatch = useDispatch();

  const {
    block, ranch, selectedObjFromState, blockArray,
  } = useRanchBlockSelection();

  const loadingBlocks = useSelector((state) => selectBlocksLoadingState(state));
  const blocksLoaded = useSelector(selectBlocksLoaded);

  const [blockObj, setBlockObj] = useState({ 0: [] });
  const [count, setCount] = useState(0);
  const [items, setItems] = useState(blockObj[count]);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const chunkSize = 10;

  useEffect(() => {
    if (blockArray && blockArray.length && !blockObj[0].length && selectedObjFromState.type) {
      let i;
      const chunk = chunkSize;
      const obj = {};
      for (i = 0; i < blockArray.length; i += 1) {
        const chunkIndex = Math.floor(i / chunk);
        if (!obj[chunkIndex]) {
          obj[chunkIndex] = []; // start a new chunk
        }
        obj[chunkIndex].push(blockArray[i]);
      }
      setBlockObj(obj);
      setItems(obj[0]);
      if (blockArray.length > chunkSize) {
        setHasMoreItems(true);
      }
    }
  }, [blockArray, blockObj, selectedObjFromState.type]);

  useEffect(() => {
    dispatch(loadAllSensors());
  }, [dispatch]);

  if (!location.search.length) {
    if (ranch && ranch[0]) history.push(`?ranchId=${ranch[0]}`);
    if (block && block[0]) history.push(`?blockId=${block[0]}`);
  }

  function handleSelect(d) {
    if (d && d.value !== undefined) {
      dispatch(setRanchBlockSelection(d, tracking));
      setItems([]);
      setBlockObj({ 0: [] });
      setCount(0);
      setHasMoreItems(false);
    }
  }

  function handleURLParam(e) {
    const params = {};
    if (location.search.length) {
      if (ranch && ranch[0]) {
        const ranchId = ranch[0];
        params.ranchId = ranchId;
      } if (block && block[0]) {
        const blockId = block[0];
        params.blockId = blockId;
      }
    }
    params.tab = e.target.value;
    let paramStr = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
    if (paramStr.length) paramStr = `?${paramStr}`;

    return paramStr;
  }

  function handleChange(e) {
    setSelectedTab(e.target.value);
    history.push(handleURLParam(e));
    dispatch(setUrlLoad(false));
    setHasMoreItems(false);
    if (tracking) tracking.track(`Selected Tab=${e.target.value}`);
  }

  const fetchMoreData = useCallback(() => {
    function handleItems(blkArr) {
      const prevItems = items;
      const newItems = prevItems.concat(blkArr);
      return newItems;
    }

    if (blockObj[count + 1]) {
      setHasMoreItems(true);
      setItems(handleItems(blockObj[count + 1]));
      setCount(count + 1);
    } else {
      setHasMoreItems(false);
    }
  }, [count, blockObj, items]);

  /**
   * Why is this useEffect needed?
   *
   * Data passed to <InfiniteScroll /> is filtered in child component.
   * In case filtered data length is zero, InfininteScroll doesn't fire fetchMoreData() to
   * get next batch of data.
   * It causes list showing no data, and the below code fixes by calling fetchMoreData()
   * if the items are changed and filtered data has length less than 5
   */
  useEffect(() => {
    if (items) {
      const vfds = items.length ? items.filter((d) => d && d.vfd) : [];
      const filteredVfd = vfds.filter((value, index) => (
        index === vfds.findIndex((obj) => obj.vfd.identifier === value.vfd.identifier)
      ));
      if (filteredVfd.length < 5) {
        fetchMoreData();
      }
    }
  }, [fetchMoreData, items]);

  function renderChildren() {
    if (!blocksLoaded && loadingBlocks.loading) return <ReactContentLoader />;

    return selectedObjFromState.type ? tabsContent(items)[selectedTab] : null;
  }

  return (
    <div className="div-default control-container page-content">
      <div className="inline-page-loader-div">
        <PageHeader
          data-testid="control-title"
          title={t('Control')}
        />
        {blocksLoaded && loadingBlocks.loading ? (
          <div className="loader-common">
            <LoadingOutlined />
          </div>
        ) : null}
      </div>
      <div className="div-select-container ranch-block-select-container">
        <RanchBlockSelectMobile
          onSelect={handleSelect}
          selected={selectedObjFromState}
          admin={userInfo && userInfo.admin}
        />
      </div>
      {selectedObjFromState.type ? (
        <div className="control-tab-container">
          <Radio.Group
            buttonStyle="solid"
            onChange={handleChange}
            options={controlOptions}
            optionType="button"
            size="large"
            value={selectedTab}
          />
        </div>
      ) : null}
      <InfiniteScroll
        initialLoad={false}
        loadMore={fetchMoreData}
        hasMore={hasMoreItems}
        loader={<div className="loader" key="0"> Loading... </div>}
        useWindow={false}
      >
        {renderChildren()}
      </InfiniteScroll>
    </div>
  );
}
